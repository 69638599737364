import View from './view.js';
import React from 'react';
import useSetFlowToBasedOnData from 'Data/useSetFlowToBasedOnData.js';

export default function Logic() {
  useSetFlowToBasedOnData({
    context: 'member',
    path: 'member.main_member',
    story: '/App/MyAccount/Content/Verify/Result/Match/MainMember',
  });

  return <View />;
}
