// import { useSetFlowToBasedOnDataShow } from 'Data/useSetFlowToBasedOnData.js';
import { useData } from 'Data/ViewsData.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  let dataMember = useData({
    context: 'related_member',
    path: 'related_member.provider_location.id',
  });

  return (
    <View
      flowNo={dataMember.value === null}
      flowContent={dataMember.value !== null}
    />
  );

  // TODO revert once we support stories with lists
  // useSetFlowToBasedOnDataShow({
  //   path: 'provider_location_id',
  //   story: `/App/MyAccount/Content/Profile/Content/Individuals/Item/Content/Show/Dependents/Content/ProviderLocation`,
  // });

  // return <View />;
}
