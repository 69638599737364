import React, { useContext, useReducer } from 'react';

let Context = React.createContext([false, () => {}]);

export function ActAsSupport(props) {
  let value = useReducer(s => !s, false);
  return <Context.Provider value={value}>{props.children}</Context.Provider>;
}

export function useActAsSupport() {
  return useContext(Context);
}
