import { DataProvider } from 'Data/ViewsData.js';
import { useSelected } from 'Data/Selected.js';
import { useFlow } from 'Logic/ViewsFlow.js';
import { useQuery } from 'Data/Api.js';
import gql from 'graphql-tag';
import React from 'react';
import View from './view.js';

let query = gql`
  query get_notification($id: uuid!) {
    notification: notifications_by_pk(id: $id) {
      id
      notification
      status
      created_at
      updated_at
    }
  }
`;

export default function Logic() {
  // TODO it would be handy if we would export the story from the view and
  // reference it here in setFlowTo as Content.storyPath, it would remove the need
  // to keep references as strings
  let story = '/App/MyAccount/Content/Comms/Item/Content';
  let flow = useFlow();
  let [selected] = useSelected();
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: { id: selected.notificationId },
  });

  if (fetching) return null;
  if (error) return `😱 ${error.message}`;

  return (
    <DataProvider context="notification" value={data}>
      <View flowShow={flow.has(`${story}/Show`)} />
    </DataProvider>
  );
}
