import { useActAsSupport } from 'Logic/ActAsSupport.js';
import { useAuth } from 'Data/Auth.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  let auth = useAuth();
  let [isActingAsSupport] = useActAsSupport();

  return (
    <View
      {...auth.permissions}
      isActingAsSupport={isActingAsSupport}
      isNotNewUserAndIsProviderAdminOrIsSupport={
        !auth.permissions.isNewUser &&
        (auth.permissions.isProviderAdmin || isActingAsSupport)
      }
      isNotNewUserAndIsMemberGroupAdmin={
        !auth.permissions.isNewUser && auth.permissions.isMemberGroupAdmin
      }
      
    />
  );
}
