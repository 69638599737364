import View from './view.js';
import React from 'react';
import useSetFlowToBasedOnData from 'Data/useSetFlowToBasedOnData.js';

export default function Logic() {
  useSetFlowToBasedOnData({
    context: 'member',
    path: 'member.provider_location.id',
    story: `/App/MyAccount/Content/Profile/Content/Employees/Item/Content/Show/ProviderLocation`,
  });

  return <View />;
}
