// This file is automatically generated by Views and will be overwritten
// when the morpher runs. If you want to contribute to how it's generated, eg,
// improving the algorithms inside, etc, see this:
// https://github.com/viewstools/morph/blob/master/ensure-is-media.js

import { useMedia } from 'use-media';

let useIsMedia = () => ({
  "mobile": useMedia({ minWidth: 0, maxWidth: 414}),"tablet": useMedia({ minWidth: 415, maxWidth: 1024}),"laptop": useMedia({ minWidth: 1025, maxWidth: 1280}),"desktop": useMedia({ minWidth: 1281})
})
export default useIsMedia