import { useData } from 'Data/ViewsData.js';
import { useExistingUser } from 'Data/useExistingUser.js';
import { useQuery } from 'Data/Api.js';
import View from './view.js';
import gql from 'graphql-tag';
import React from 'react';

let query = gql`
  query users($companies_ids: [uuid!]!) {
    members: users(
      where: {
        member: { company_id: { _in: $companies_ids } }
        _not: { professional: {} }
      }
      order_by: { profile: { last_name: asc, first_name: asc } }
    ) {
      id
      email
      profile {
        id
        first_name
        last_name
      }
    }
    company_users: users(
      where: {
        company_users: { company_id: { _in: $companies_ids } }
        _not: { professional: {} }
      }
      order_by: { profile: { last_name: asc, first_name: asc } }
    ) {
      id
      email
      profile {
        id
        first_name
        last_name
      }
    }
  }
`;

export default function Logic() {
  let dataProfessional = useData({
    context: 'professional',
    path: 'professional.companies_ids',
  });
  let [{ data, fetching, error }] = useQuery({
    query,
    variables: { companies_ids: dataProfessional.value },
  });
  let existingUser = useExistingUser({
    context: 'professional',
    path: 'professional',
    usersData: data,
  });

  if (fetching) return null;
  if (error) return `😱 ${error.message}`;

  return <View {...existingUser} />;
}
