import { useActAsSupport } from 'Logic/ActAsSupport.js'
import { useData } from 'Data/ViewsData.js'
import View from './view.js'
import getValidEffectiveDates from 'Logic/getValidEffectiveDates.js'
import React from 'react'

export default function Logic() {
  let [isActingAsSupport] = useActAsSupport()
  let dataEmployeesImport = useData({
    context: 'employees_import',
    path: 'employees_import.company',
  })

  return (
    <View
      from={getValidEffectiveDates(
        dataEmployeesImport.value?.effective_date,
        dataEmployeesImport.value?.expiry_date,
        isActingAsSupport
      )}
    />
  )
}
