import { DataProvider, useData } from 'Data/ViewsData.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  let item = useData({
    context: 'employees_import',
    path: 'employees_import.employees',
  });

  return item.value.map((eitem) => (
    <DataProvider
      context="employee_import"
      key={eitem.email}
      value={{ employee_import: eitem }}
    >
      <View isValid={eitem.is_valid} />
    </DataProvider>
  ));
}
