import {
  COMPANY_TYPES,
  JOINING_AS,
  GENDERS,
  PHONE_TYPES,
  TERMINATION_REASONS,
  RELATIONSHIPS,
  ROLES,
} from './constants.js'
import { validate as isValidEmail } from 'email-validator'
// import getValidEffectiveDates from 'Logic/getValidEffectiveDates.js';
// import getValidTerminationDates from 'Logic/getValidTerminationDates.js';
import isPastDate from 'date-fns/isPast'
import isValidDate from 'date-fns/isValid'
import parseISODate from 'date-fns/parseISO'
import phoneRegex from 'phone-regex'
import sicCodes from 'Data/sicCodes.json'

export function email(value) {
  return isValidEmail(value)
}
// https://stackoverflow.com/a/2385967/1562732
export function name(value) {
  return (
    value &&
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð][a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u.test(
      value.trim()
    )
  )
}
export function trueFalse(value) {
  return [true, false].includes(value)
}

export function birthday(value) {
  return date(value) && isPastDate(parseISODate(value))
}

// TODO
export function maritalStatus(value) {
  return textInput(value)
}

// TODO
export function status(value) {
  return textInput(value)
}

export function date(value) {
  return isValidDate(parseISODate(value))
}

export function phoneNumber(value) {
  return phoneRegex({ exact: true }).test(value)
}

export function textInput(value) {
  return value && value.length >= 2
}

export function street(value) {
  return textInput(value)
}

export function city(value) {
  return textInput(value)
}

export function state(value) {
  return value && value.length === 2
}

export function zip(value) {
  return /^\d{5}$/.test(value)
}

export function address({
  addressStreet,
  addressCity,
  addressState,
  addressZip,
}) {
  return (
    street(addressStreet) &&
    city(addressCity) &&
    state(addressState) &&
    zip(addressZip)
  )
}

export function image(value) {
  return value ? value.includes('data:image/png;base64') : false
}

export function imageUpload(value) {
  return value ? value.includes('data:image/') : false
}

export function signature(value) {
  let { data, type } = value
  switch (type) {
    case 'sign':
    case 'write':
      return image(data)
    case 'upload':
      return imageUpload(data)
    default:
      throw new Error(`wrong signature type provided ${type}`)
  }
}

export function fein(value) {
  return /^\d{2}-?\d{7}$/.test(value)
}

export function sic(value) {
  return sicCodes.some(item => item.id === value)
}

export function doingBusinessAs(dba) {
  return textInput(dba)
}

export function yesNo(value) {
  return ['yes', 'no'].includes(value)
}

export function joiningAs(value) {
  return value in JOINING_AS
}

export function isHomeOffice(value) {
  return trueFalse(value)
}

export function phoneType(value) {
  return PHONE_TYPES.includes(value)
}

let companyValidators = {
  name,
  fein,
  email,
  phoneNumber,
  doingBusinessAs,
  address,
  isHomeOffice,
}

export function companyDetails(value) {
  return Object.keys(value).every(key => companyValidators[key](value[key]))
}

export function company(value) {
  return value.trim().length > 0
}
export function companies(value) {
  return value.length > 0
}
export function locations(value) {
  return value.length > 0
}
export function professionals(value) {
  return value.length > 0
}

export function npi(value) {
  return /^\d{10}$/.test(value)
}

export function signupCode(value) {
  return /^\d{6}$/.test(value)
}

export function website(value) {
  if (!value) return true
  return value.includes('.')
}

export function role(value) {
  return ROLES.some(item => item.id === value)
}

export function companyType(value) {
  return COMPANY_TYPES.some(item => item.id === value)
}

export function gender(value) {
  return GENDERS.some(item => item.id === value)
}

export function relationship(value) {
  return RELATIONSHIPS.some(item => item.id === value)
}

let LOWERCASE_LETTERS = /[a-z]/
let UPPERCASE_LETTERS = /[A-Z]/
let NUMBERS = /[0-9]/
let SPECIAL_CHARACTERS = /[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`]/
export function password(value) {
  return (
    value.length >= 8 &&
    LOWERCASE_LETTERS.test(value) &&
    UPPERCASE_LETTERS.test(value) &&
    NUMBERS.test(value) &&
    SPECIAL_CHARACTERS.test(value)
  )
}

export function user(values) {
  return values.useExistingUser
    ? values.user.id
    : isValidEmail(values.user.email) &&
        phoneNumber(values.user.phone_number) &&
        values.user.profile.first_name &&
        values.user.profile.last_name
}

export function licenseNumber(value) {
  return value && /^\d{4}$/.test(value)
}

// TODO in this case, the effective date validation depends on the context at
// point of use since the list changes based off the company effective and
// expiry dates. We need to find a way to allow for that context to be passed
// down here and expressed within Views somehow (it's in a logic file now)
export function effectiveDate(value) {
  return date(value)
  // return getValidEffectiveDates().some(item => item.id === value);
}
export function terminationDate(value) {
  return date(value)
  // return getValidTerminationDates().some((item) => item.id === value);
}

let UUID = /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
export function uuid(value) {
  return value && UUID.test(value)
}

export function contribution(rawValue) {
  let value = parseFloat(rawValue, 10)
  if (isNaN(value)) return false

  return value >= 0
}

export function contributionBasicOne(rawValue) {
  return validatePlanContrib(rawValue, MAX_PLAN_CONTRIB.BASIC.ONE)
}
export function contributionBasicTwo(rawValue) {
  return validatePlanContrib(rawValue, MAX_PLAN_CONTRIB.BASIC.TWO)
}
export function contributionBasicThree(rawValue) {
  return validatePlanContrib(rawValue, MAX_PLAN_CONTRIB.BASIC.THREE)
}

export function contributionBasicFour(rawValue) {
  return validatePlanContrib(rawValue, MAX_PLAN_CONTRIB.BASIC.FOUR)
}

export function contributionBasicFive(rawValue) {
  return validatePlanContrib(rawValue, MAX_PLAN_CONTRIB.BASIC.FIVE)
}

export function contributionEnhancedOne(rawValue) {
  return validatePlanContrib(rawValue, MAX_PLAN_CONTRIB.ENHANCED.ONE)
}
export function contributionEnhancedTwo(rawValue) {
  return validatePlanContrib(rawValue, MAX_PLAN_CONTRIB.ENHANCED.TWO)
}
export function contributionEnhancedThree(rawValue) {
  return validatePlanContrib(rawValue, MAX_PLAN_CONTRIB.ENHANCED.THREE)
}

export function contributionEnhancedFour(rawValue) {
  return validatePlanContrib(rawValue, MAX_PLAN_CONTRIB.ENHANCED.FOUR)
}

export function contributionEnhancedFive(rawValue) {
  return validatePlanContrib(rawValue, MAX_PLAN_CONTRIB.ENHANCED.FIVE)
}

function validatePlanContrib(rawValue, max) {
  let value = parseFloat(rawValue, 10)
  if (isNaN(value)) return false

  return value >= 0 && value <= max
}

export let MAX_PLAN_CONTRIB = {
  BASIC: {
    ONE: 18,
    TWO: 28,
    THREE: 35,
    FOUR: 42,
    FIVE: 52,
  },
  ENHANCED: {
    ONE: 25,
    TWO: 40,
    THREE: 50,
    FOUR: 60,
    FIVE: 75,
  },
}

export function plan(value) {
  return value === 'Basic' || value === 'Enhanced'
}

export function memberId(value) {
  return value && /^[0-9]{8}$/.test(value)
}

let SOCIAL_SECURITY_NUMBER = /^\d{3}-\d{2}-\d{4}$/
export function social_security_number(value) {
  return value && SOCIAL_SECURITY_NUMBER.test(value)
}

export function numberZeroOrPositive(value) {
  return Number.isFinite(value) && value >= 0
}

export function terminationReason(value) {
  return TERMINATION_REASONS.some(item => item.id === value)
}
