import Papa from 'papaparse';

export function parseCsv({ file, ...config }) {
  return new Promise((complete, error) =>
    Papa.parse(file, { ...config, complete, error })
  );
}

export function toCsv({ data, ...config }) {
  return Papa.unparse(data, config);
}

let isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
export function toCsvDownloadLink({ data, fields, uFEFF = true }) {
  let csv = toCsv({ data: { data, fields } });
  let type = isSafari() ? 'application/csv' : 'text/csv';
  let blob = new Blob([uFEFF ? '\uFEFF' : '', csv], { type });
  let dataURI = `data:${type};charset=utf-8,${uFEFF ? '\uFEFF' : ''}${csv}`;

  let URL = window.URL || window.webkitURL;

  return typeof URL.createObjectURL === 'undefined'
    ? dataURI
    : URL.createObjectURL(blob);
}

export function downloadCsv({ data, fields, name }) {
  let uri = toCsvDownloadLink({ data, fields });

  let downloadLink = document.createElement('a');
  downloadLink.href = uri;
  downloadLink.download = name;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}
