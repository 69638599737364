import { _in } from 'Data/hasura.js'
import { dateISOIn } from 'utils/dateHelpers.js'
import { downloadCsv } from 'utils/csv.js'
import { phoneNumberUS as formatPhoneNumber } from 'Data/format.js'
import { useQuery } from 'Data/Api.js'
import { useSetFlowTo } from 'Logic/ViewsFlow.js'
import format from 'number-format.js'
import gql from 'graphql-tag'
import { useData } from 'Data/ViewsData.js'
import React, { useEffect } from 'react'
import View from './view.js'

function where({ member_ids }) {
  return _in('id', member_ids)
}

let query = gql`
  query list_individuals($where: members_bool_exp) {
    members(
      where: $where
      order_by: { profile: { last_name: asc, first_name: asc } }
    ) {
      id
      effective_date
      expiry_date
      policy_id
      status
      plan_cost_total
      plan_members_count
      plan {
        name
      }
      professional {
        user {
          profile {
            first_name
            last_name
          }
        }
      }
      provider_location {
        company_doing_business_as {
          name
          company {
            legal_name
          }
        }
      }
      user {
        email
        phone_number
      }
      profile {
        last_name
        first_name
        date_of_birth
        gender
        marital_status
        social_security_number
      }
      related_members_aggregate {
        aggregate {
          count
        }
      }
      created_at
    }
  }
`

let fields = [
  'Id',
  'Effective Date',
  'Expiry Date',
  'Member Id',
  'Type',
  'Plan Name',
  'Professional',
  'Location',
  'Location Company Name',
  'Status',
  'Email',
  'Phone Number',
  'First Name',
  'Last Name',
  'Date of Birth',
  'Gender',
  'Marital Status',
  'Social Security Number',
  'Dependents',
  'Members Covered',
  'Total Cost',
  'Created',
]

function processData({ members }) {
  let data = members.map(member => {
    return [
      member.id,
      dateISOIn(member.effective_date),
      dateISOIn(member.expiry_date),
      member.policy_id,
      'Individual',
      member.plan && member.plan.name,
      member.professional &&
        `${member.professional.user.profile.first_name} ${member.professional.user.profile.last_name}`,
      member.provider_location &&
        member.provider_location.company_doing_business_as.name,
      member.provider_location &&
        member.provider_location.company_doing_business_as &&
        member.provider_location.company_doing_business_as.company &&
        member.provider_location.company_doing_business_as.company.legal_name,
      member.status,
      member.user?.email || '',
      (member.user?.phone_number &&
        formatPhoneNumber(member.user.phone_number)) ||
        '',
      member.profile.first_name,
      member.profile.last_name,
      dateISOIn(member.profile.date_of_birth),
      member.profile.gender,
      member.profile.marital_status,
      member.profile.social_security_number,
      member.related_members_aggregate.aggregate.count,
      member.plan_members_count,
      format('$#,##0.00', member.plan_cost_total),
      member.created_at,
    ]
  })
  downloadCsv({
    name: 'greyfinch-individuals-report.csv',
    fields,
    data,
  })
}

export default function Logic(props) {
  let individualData = useData({
    viewPath: props.viewPath,
    path: 'filtered_individuals',
    context: 'filtered_individuals',
  })
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: {
      where: where({
        member_ids: individualData.value.filtered_individuals,
      }),
    },
  })
  let setFlowTo = useSetFlowTo()

  let story =
    '/App/MyAccount/Content/Profile/Content/Individuals/MoreActions/Content/DownloadCSV'
  useEffect(() => {
    if (error) {
      setFlowTo(`${story}/Failure`)
    } else {
      if (!fetching && data) {
        // Process the data
        try {
          processData(data)
          setFlowTo(`${story}/Done`)
        } catch (e) {
          console.error(e)
          setFlowTo(`${story}/Failure`)
        }
      }
    }
  }, [story, setFlowTo, data, fetching, error])
  return <View />
}
