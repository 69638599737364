import { useData } from 'Data/ViewsData.js';
import { useExistingUser } from 'Data/useExistingUser.js';
import { useQuery } from 'Data/Api.js';
import View from './view.js';
import gql from 'graphql-tag';
import React from 'react';

let query = gql`
  query users($company_id: uuid) {
    company_users: users(
      where: {
        company_users: { company_id: { _eq: $company_id } }
        _not: { member: {} }
      }
      order_by: { profile: { last_name: asc, first_name: asc } }
    ) {
      id
      email
      profile {
        id
        first_name
        last_name
        address_id
      }
    }
    professionals: users(
      where: {
        professional: {
          professional_locations: {
            provider_location: { company_id: { _eq: $company_id } }
          }
        }
        _not: { member: {} }
      }
      order_by: { profile: { last_name: asc, first_name: asc } }
    ) {
      id
      email
      profile {
        id
        first_name
        last_name
        address_id
      }
    }
  }
`;

export default function Logic() {
  let dataMember = useData({ context: 'member', path: 'member.company_id' });
  let [{ data }] = useQuery({
    query,
    variables: { company_id: dataMember.value },
  });
  let existingUser = useExistingUser({
    context: 'member',
    path: 'member',
    usersData: data,
  });
  return <View {...existingUser} />;
}
