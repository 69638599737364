import useSetFlowToBasedOnData from 'Data/useSetFlowToBasedOnData.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  useSetFlowToBasedOnData({
    path: 'user.is_deleting_professional',
    context: 'user',
    story:
      '/App/MyAccount/Content/Profile/Content/MyProfessionals/Item/Content/Delete/Professional/Content/Delete',
  });

  return <View />;
}
