import { useActAsSupport } from 'Logic/ActAsSupport.js'
import { useQuery } from 'Data/Api.js'
import { useSelected } from 'Data/Selected.js'
import { DataProvider } from 'Data/ViewsData.js'
import gql from 'graphql-tag'
import React from 'react'
import View from './view.js'
import useFilter from 'useFilter.js'
import useHasSignedAgreementForSelectedCompany from 'Data/hasSignedAgreementForSelectedCompany.js'
import useIsAdmin from 'Data/isAdmin.js'

let value = {
  filtered_professionals: [],
}

let query = gql`
  query provider_locations_aggregate($companies_ids: [uuid!]!) {
    provider_locations_aggregate(
      where: { company_id: { _in: $companies_ids } }
    ) {
      aggregate {
        count
      }
    }
  }
`

export default function Logic() {
  let [selected] = useSelected()
  let [isActingAsSupport] = useActAsSupport()
  let hasSignedAgreementForSelectedCompany =
    useHasSignedAgreementForSelectedCompany('Provider')
  let filter = useFilter()
  let isAdmin = useIsAdmin()
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: {
      companies_ids: selected.companies_ids,
    },
  })

  if (fetching && !data) return null
  if (error) return `😱 ${error.message}`

  let hasLocations = data.provider_locations_aggregate.aggregate.count > 0
  let hasSelectedOne = selected.companies_ids.length === 1

  let hasToAcceptAndSignAgreement =
    !isActingAsSupport &&
    hasSelectedOne &&
    !hasSignedAgreementForSelectedCompany
  let hasToAddLocations =
    !isActingAsSupport &&
    hasSelectedOne &&
    !hasLocations &&
    !hasToAcceptAndSignAgreement

  return (
    <DataProvider context="filtered_professionals" value={value}>
      <View
        {...filter}
        isAdmin={isAdmin || isActingAsSupport}
        hasToAcceptAndSignAgreement={hasToAcceptAndSignAgreement}
        hasToAddLocations={hasToAddLocations}
        canInteract={!hasToAcceptAndSignAgreement && !hasToAddLocations}
      />
    </DataProvider>
  )
}
