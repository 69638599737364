// import { cacheExchange } from '@urql/exchange-graphcache';
import { captureError } from 'utils/ErrorBoundary.js';
import { devtoolsExchange } from '@urql/devtools';
import {
  createClient,
  dedupExchange,
  fetchExchange,
  cacheExchange,
  Provider,
  useQuery as _useQuery,
  useMutation,
} from 'urql';
import { pipe, tap } from 'wonka';
import { useActAsSupport } from 'Logic/ActAsSupport.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import Auth from '@aws-amplify/auth';
// import makeMutationInsertSoftDeleteHandler from './makeMutationInsertSoftDeleteHandler.js';
import React, { useLayoutEffect, useMemo } from 'react';

export { useMutation };

export function useQuery(args) {
  let res = _useQuery(args);
  let [{ error }] = res;
  let setFlowTo = useSetFlowTo();

  useLayoutEffect(() => {
    if (!error) return;
    if (process.env.NODE_ENV === 'development') return;

    setFlowTo('/App/RuntimeError');
  }, [error]); // eslint-disable-line
  // ignore setFlowTo

  return res;
}

function isErrorThatForcesSignIn(error) {
  switch (error.extensions && error.extensions.code) {
    case 'access-denied':
    case 'jwt-invalid-claims':
      return true;
    default:
      return false;
  }
}

function makeClient({ isActingAsSupport, setFlowTo }) {
  function forceSignIn() {
    Auth.signOut();
    setFlowTo('/App/Auth/SignIn');
  }

  let errorExchange = ({ forward }) => ops$ => {
    return pipe(
      forward(ops$),
      tap(({ error }) => {
        // if the OperationResult has an error send a request to sentry
        if (error) {
          // the error is a CombinedError with networkError and graphqlErrors properties
          captureError(error, error.graphQLErrors);
          if (error.graphQLErrors.some(isErrorThatForcesSignIn)) {
            forceSignIn();
          }
        }
      })
    );
  };

  return createClient({
    url: process.env.REACT_APP_API,
    // TODO there's a problem with @url/exchange-graphcache and this setting
    // so I've disabled the cache for now and resorted to this instead
    // which gives us semi-updated queries
    requestPolicy: 'cache-and-network', // if we see weird stuff, use 'network-only'
    fetch: async (url, options) => {
      let headers = { ...options.headers };
      try {
        let user = await Auth.currentAuthenticatedUser();
        headers.Authorization = `Bearer ${user.signInUserSession.accessToken.jwtToken}`;
        if (isActingAsSupport) {
          headers['x-hasura-role'] = 'support';
        }
      } catch (error) {
        captureError(error);
        forceSignIn();
        headers['x-hasura-role'] = 'public';
      }
      return fetch(url, { ...options, headers });
    },
    exchanges: [
      // replacing devtools with a passthrough exchange for production environments
      process.env.NODE_ENV !== 'production'
        ? devtoolsExchange
        : ({ forward }) => forward,
      dedupExchange,
      cacheExchange,
      errorExchange,
      fetchExchange,
    ],
    // exchanges: [
    //   dedupExchange,
    //   // devtoolsExchange,
    //   // Replace the default cacheExchange with the new one
    //   cacheExchange({
    //     keys: {
    //       company_users: data =>
    //         `${data.company_id}:${data.user_id}:${data.role}`,
    //       member_group_contributions: data =>
    //         `${data.company_id}:${data.plan_id}`,
    //       members_aggregate: () => null,
    //       members_aggregate_fields: () => null,
    //       related_members_aggregate: () => null,
    //       related_members_aggregate_fields: () => null,
    //       professional_locations_aggregate: () => null,
    //       professional_locations_aggregate_fields: () => null,
    //       provider_locations_aggregate: () => null,
    //       provider_locations_aggregate_fields: () => null,
    //       professional_locations: () => null,
    //       // data => `${data.professional_id}:${data.provider_location_id}`
    //       tags: data => data.name,
    //       VerifyResponse: () => null,
    //       procedure_tags: () => null,
    //     },
    //     updates: {
    //       Mutation: {
    //         ...makeMutationInsertSoftDeleteHandler('companies'),
    //         ...makeMutationInsertSoftDeleteHandler('company_users'),
    //         ...makeMutationInsertSoftDeleteHandler('professionals'),
    //         ...makeMutationInsertSoftDeleteHandler('provider_locations'),
    //         ...makeMutationInsertSoftDeleteHandler('members'),
    //       },
    //     },
    //   }),
    //   fetchExchange,
    // ].filter(Boolean),
  });
}

export function Api(props) {
  let setFlowTo = useSetFlowTo();
  let [isActingAsSupport] = useActAsSupport();
  // eslint-disable-next-line
  let client = useMemo(() => makeClient({ setFlowTo, isActingAsSupport }), [
    isActingAsSupport,
  ]);
  // ignore setFlowTo

  return <Provider value={client}>{props.children}</Provider>;
}
