import { useData } from 'Data/ViewsData.js';
import { useQuery } from 'Data/Api.js';
import gql from 'graphql-tag';
import View from './view.js';
import React from 'react';

let query = gql`
  query getLicenses {
    licenses {
      id
      name
      default_age_max
      default_age_min
      can_age_be_set_by_professional
      is_primary_care
    }
  }
`;

export default function Logic() {
  let dataProfessional = useData({
    context: 'professional',
    path: 'professional.license.id',
  });
  let [{ fetching, error, data }] = useQuery({ query });

  if (fetching) return null;
  if (error) return `😱 ${error.message}`;

  return data.licenses.map((item) => {
    let isSelected = dataProfessional.value === item.id;

    return (
      <View
        id={item.id}
        key={item.id}
        isPrimaryCare={item.is_primary_care}
        canAgeBeSetByProfessional={item.can_age_be_set_by_professional}
        defaultAgeMax={item.default_age_max}
        defaultAgeMin={item.default_age_min}
        isSelected={isSelected}
        onClick={() => dataProfessional.onChange(item, 'professional.license')}
        value={item.name}
      />
    );
  });
}
