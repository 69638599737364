import { useData } from 'Data/ViewsData.js';
import View from './view.js';
import Info from 'DesignSystem/Info/view.js';
import React from 'react';

export default function Logic() {
  let dataProfessional = useData({
    context: 'professional',
    path: 'professional.professional_locations',
  });

  // TODO move as stories
  // ListItems/Empty
  // ListItems/Item
  if (dataProfessional.value.length === 0) {
    return <Info text="None" />;
  }

  return dataProfessional.value.map((item) => (
    <View
      key={item.provider_location.id}
      value={`${item.provider_location.company_doing_business_as.name} - ${item.provider_location.address.street}, ${item.provider_location.address.city}, ${item.provider_location.address.state}, ${item.provider_location.address.zip}`}
    />
  ));
}
