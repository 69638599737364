import { DataProvider } from 'Data/ViewsData.js'
import { useSelected } from 'Data/Selected.js'
import { useFlow } from 'Logic/ViewsFlow.js'
import { useQuery } from 'Data/Api.js'
import gql from 'graphql-tag'
import React from 'react'
import View from './view.js'

let query = gql`
  query getMember($id: uuid!) {
    member: members_by_pk(id: $id) {
      id
      effective_date
      expiry_date
      policy_id
      status
      termination_date
      created_at
      updated_at
      stripe_subscription_status
      stripe_payment_metadata
      stripe_latest_invoice_url
      is_individual
      plan {
        name
      }
      phone_number
      email
      profile {
        first_name
        last_name
        date_of_birth
        marital_status
        social_security_number
      }
      addresses {
        id
        street
        city
        state
        zip
      }
      relationship_to_main_member
      main_member {
        stripe_subscription_status
        phone_number
        is_individual
        profile {
          first_name
          last_name
        }
      }
      provider_location {
        id
        address {
          id
          street
          city
          state
          zip
        }
        company_doing_business_as {
          id
          name
        }
      }
      professional {
        id
        full_name
      }
      company {
        legal_name
      }
    }
  }
`

let context = {
  fetchOptions: {
    headers: {
      'x-hasura-role': 'provider',
    },
  },
}

export default function Logic() {
  // TODO it would be handy if we would export the story from the view and
  // reference it here in setFlowTo as Content.storyPath, it would remove the need
  // to keep references as strings
  let story = '/App/MyAccount/Content/Members/Item/Content'
  let flow = useFlow()
  let [selected] = useSelected()
  let [{ fetching, error, data }] = useQuery({
    query,
    context,
    variables: { id: selected.memberId },
  })

  if (fetching) return null
  if (error) return `😱 ${error.message}`

  return (
    <DataProvider context="member" value={data}>
      <View flowShow={flow.has(`${story}/Show`)} />
    </DataProvider>
  )
}
