import React, { useMemo } from 'react';
import { DataProvider } from 'Data/ViewsData.js';
import { isInvalid, parseGraphqlError } from 'utils/graphQlHelpers.js';
import { INVITE } from 'Data/constants.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import { NEW_USER } from 'Data/useExistingUser.js';
import { useMutation, useQuery } from 'urql';
import {
  useNotifications,
  notifyError,
  notifySuccess,
} from 'utils/useNotifications.js';
import { useSelected } from 'Data/Selected.js';
import gql from 'graphql-tag';
import View from './view.js';

let story = '/App/MyAccount/Content/Profile/Content/Employees/AddItem';

let query = gql`
  query {
    plans {
      id
      name
      pricing
    }
  }
`;
let MUTATION_INSERT_MEMBER = gql`
  mutation insert_member($member: members_insert_input!) {
    insert_members(objects: [$member]) {
      returning {
        id
        status
      }
    }
  }
`;
let MUTATION_INSERT_USER = gql`
  mutation insert_user($user: users_insert_input!) {
    insert_users(
      objects: [$user]
      on_conflict: {
        constraint: users_email_key
        update_columns: [phone_number]
      }
    ) {
      returning {
        id
        profile_id
      }
    }
  }
`;

export default function Logic() {
  let setFlowTo = useSetFlowTo();
  let [selected, select] = useSelected();
  let [, notify] = useNotifications();
  let [, executeMutationInsertMember] = useMutation(MUTATION_INSERT_MEMBER);
  let [, executeMutationInsertUser] = useMutation(MUTATION_INSERT_USER);
  let [{ fetching, error, data }] = useQuery({ query });
  let memberValue = useMemo(
    () => ({
      member: {
        company_id: selected.companies_ids[0] || null,
        useExistingUser: false,
        user: NEW_USER,
        effective_date: '',
      },
    }),
    [] // eslint-disable-line
  );

  if (fetching && !data) return null;
  if (error) return `😱 ${error.message}`;

  return (
    <DataProvider context="plans" value={data}>
      <DataProvider context="member" onSubmit={onSubmit} value={memberValue}>
        <View />
      </DataProvider>
    </DataProvider>
  );

  async function onSubmit(next) {
    if (isInvalid(INVITE.MEMBER, next.member, notify, data.company))
      return true;

    let member = {
      company_id: next.member.company_id,
      is_configuration_up_to_member: true,
      effective_date: next.member.effective_date,
    };

    let user = {
      user: {
        email: next.member.useExistingUser
          ? next.member.existingUser.email
          : next.member.user.email,
        phone_number: next.member.user.phone_number,
        profile: {
          data: {
            first_name: next.member.user.profile.first_name,
            last_name: next.member.user.profile.last_name,
          },
        },
      },
    };
    let mutationResponseInsertUser = await executeMutationInsertUser(user);
    if (mutationResponseInsertUser.error) {
      notify(
        notifyError(parseGraphqlError(mutationResponseInsertUser.error).message)
      );
      return;
    }

    member.user_id =
      mutationResponseInsertUser.data.insert_users.returning[0].id;
    member.profile_id =
      mutationResponseInsertUser.data.insert_users.returning[0].profile_id;

    let mutationResponseInsertMember = await executeMutationInsertMember({
      member,
    });
    if (mutationResponseInsertMember.error) {
      notify(
        notifyError(
          parseGraphqlError(mutationResponseInsertMember.error).message
        )
      );
    } else {
      select({ nothing: Date.now() });
      let user = next.member.existingUser || next.member.user;
      notify(
        notifySuccess(
          `${user.profile.first_name} ${user.profile.last_name} was successfully added!`
        )
      );
      setFlowTo(`${story}/No`);
    }
  }
}
