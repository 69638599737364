import { useData } from 'Data/ViewsData.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  let dataCompany = useData({
    context: 'company',
    path: 'company',
  });

  return (
    <View
      isProvider={dataCompany.value.isProvider}
      isMemberGroup={dataCompany.value.isMemberGroup}
      onClick={onClick}
    />
  );

  function onClick(isMemberGroup) {
    dataCompany.onChange((next) => {
      next.company.isMemberGroup = isMemberGroup;

      if (!isMemberGroup) {
        next.company.effective_date = '';
        next.company.member_group_contributions = [
          {
            contribution: {
              one_person: 0,
              two_people: 0,
              three_people: 0,
              four_people: 0,
              five_people_or_more: 0,
            },
          },
          {
            contribution: {
              one_person: 0,
              two_people: 0,
              three_people: 0,
              four_people: 0,
              five_people_or_more: 0,
            },
          },
        ];
      }
    });
  }
}
