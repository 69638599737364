import { VERIFY_MEMBERSHIP } from 'Data/constants.js'
import { DataProvider } from 'Data/ViewsData.js'
import { isInvalid } from 'utils/graphQlHelpers.js'
import { useNotifications } from 'utils/useNotifications.js'
import { useSetFlowTo } from 'Logic/ViewsFlow.js'
import React, { useEffect, useMemo } from 'react'
import gql from 'graphql-tag'
import View from './view.js'
import useLazyQuery from 'Data/useLazyQuery.js'

// TODO Work out a SQL function for it
// Could look a bit like:
//
// CREATE FUNCTION verify_membership(phone_number text, date_of_birth date, last_name text)
// RETURNS SETOF members AS $$
//     SELECT *
//     FROM members m
//     LEFT JOIN profiles p ON m.profile_id = p.id
//     LEFT JOIN users u ON m.user_id = u.id
//     WHERE
//         p.last_name ILIKE phone_number
//         AND
//         p.date_of_birth = date_of_birth
//         AND (
//             u.phone_number = phone_number
//             -- :D
//             -- OR
//             -- u.phone_number = (
//             --     SELECT phone_number FROM users
//             --     WHERE id = (
//             --         SELECT user_id FROM members WHERE id = member.main_member_id
//             --     )
//             -- )
//             -- main_member->related_members, etc
//         );
// $$ LANGUAGE sql STABLE;
let query = gql`
  query verify_membership(
    $phone_number: String!
    $date_of_birth: date!
    $last_name: String!
  ) {
    members(
      where: {
        profile: {
          last_name: { _ilike: $last_name }
          date_of_birth: { _eq: $date_of_birth }
        }
        _or: [
          { user: { phone_number: { _eq: $phone_number } } }
          { main_member: { user: { phone_number: { _eq: $phone_number } } } }
          {
            main_member: {
              related_members: {
                user: { phone_number: { _eq: $phone_number } }
              }
            }
          }
        ]
      }
    ) {
      effective_date
      policy_id
      status
      stripe_subscription_status
      stripe_payment_metadata
      stripe_latest_invoice_url
      profile {
        first_name
        last_name
      }
      expiry_date
      employer_name
      relationship_to_main_member
      plan {
        name
      }
      main_member {
        phone_number
        profile {
          first_name
          last_name
        }
      }
    }
  }
`

let VALUE_VERIFY = {
  verify: {
    date_of_birth: '',
    phone_number: '',
    last_name: '',
  },
}

let context = {
  fetchOptions: {
    headers: {
      'x-hasura-role': 'verifier',
    },
  },
}

export default function Logic() {
  let setFlowTo = useSetFlowTo()
  let [, notify] = useNotifications()
  let [{ data, fetching }, executeQuery] = useLazyQuery({ query, context })

  useEffect(() => {
    let story = 'No'

    if (data) {
      if (data.members.length === 1) {
        story = 'Match'
      } else {
        story = 'NoMatch'
      }
    }

    if (fetching) {
      story = 'Loading'
    }

    setFlowTo(`/App/MyAccount/Content/Verify/Result/${story}`)
  }, [fetching, data]) // eslint-disable-line
  // ignore setFlowTo and selectedDispatch

  let valueMember = useMemo(
    () => ({
      member: data && data.members[0],
    }),
    [data]
  )

  return (
    <DataProvider context="member" value={valueMember}>
      <DataProvider context="verify" onSubmit={onSubmit} value={VALUE_VERIFY}>
        <View />
      </DataProvider>
    </DataProvider>
  )

  function onSubmit(next) {
    if (isInvalid(VERIFY_MEMBERSHIP, next.verify, notify)) {
      return true
    }

    executeQuery({ variables: next.verify })
  }
}
