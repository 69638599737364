export default function ensureValuesAreCurrency(object) {
  let transformed = {};
  Object.entries(object).forEach(([key, value]) => {
    transformed[key] = parseFloat(
      Math.abs(
        typeof value === 'string' ? parseFloat(value, 10) : value
      ).toFixed(2),
      2
    );
  });
  return transformed;
}
