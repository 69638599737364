import View from './view.js';
import React from 'react';
import useSetFlowToBasedOnData from 'Data/useSetFlowToBasedOnData.js';

export default function Logic() {
  useSetFlowToBasedOnData({
    context: 'member',
    path: 'member.provider_location_id',
    story:
      '/App/MyAccount/Content/Profile/Content/Employees/AddItem/Content/Add/Professionals',
  });

  return <View />;
}
