import { _and, _in, _ilike } from 'Data/hasura.js'
import { FixedSizeList as List } from 'react-window'
import { DataProvider } from 'Data/ViewsData.js'
import { useSelected } from 'Data/Selected.js'
import { useSetFlowTo } from 'Logic/ViewsFlow.js'
import { useQuery } from 'Data/Api.js'
import AutoSizer from 'react-virtualized-auto-sizer'
import gql from 'graphql-tag'
import Info from 'DesignSystem/Info/view.js'
import View from './view.js'
import LoadingBar from 'DesignSystem/LoadingBar/logic.js'
import React, { useEffect } from 'react'

let query = gql`
  query list_utilization_reports(
    $limit: Int
    $where: utilization_reports_bool_exp
  ) {
    utilization_reports(
      limit: $limit
      where: $where
      order_by: { uploaded_at: desc_nulls_first, reported_month: desc }
    ) {
      id
      reported_month
      has_nothing_to_upload
      company {
        id
        legal_name
      }
      uploaded_at
      uploaded_by_user {
        id
        profile {
          id
          full_name
        }
      }
    }
  }
`

function where({ filter: rfilter, companies_ids, nothing }) {
  let filter = rfilter.trim()

  return _and(
    _in('company_id', companies_ids),
    filter && _ilike('company.legal_name', filter)
  )
}

// this number is a combination of the 70px of the height plus the 8px
// of the marginTop from the Card in ListItems.view
// until we find a better way to
let ROW_HEIGHT = 78

export default function Logic(props) {
  let [selected, select] = useSelected()
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: {
      limit: 10000,
      where: where({
        filter: props.filter,
        companies_ids: selected.companies_ids,
        nothing: selected.nothing,
      }),
    },
  })
  let setFlowTo = useSetFlowTo()

  useEffect(() => {
    if (!data) return

    let view = data.utilization_reports.some(
      (item) => item.uploaded_at === null
    )
      ? 'Content'
      : 'No'

    setFlowTo(
      `/App/MyAccount/Content/Profile/Content/Utilization/DeadlineInfo/${view}`
    )
  }, [data]) // eslint-disable-line
  // ignore setFlowTo

  if (fetching)
    return (
      <LoadingBar
        alignSelf="flexStart"
        borderBottomLeftRadius={4}
        borderBottomRightRadius={4}
        borderTopLeftRadius={4}
        borderTopRightRadius={4}
        height={8}
        marginTop={20}
        width={200}
      />
    )

  if (error) return `😱 ${error.message}`

  // TODO move as stories
  // ListItems/Loading
  // ListItems/Empty
  // ListItems/Item
  if (data.utilization_reports.length === 0) {
    return <Info marginLeft={10} text="No reports" />
  }

  function Row({ index, style }) {
    let item = data.utilization_reports[index]
    let isUploaded = item.uploaded_at !== null

    return (
      <DataProvider
        context="utilization_report"
        key={item.id}
        value={{ utilization_report: item }}
      >
        <View
          {...style}
          isUploaded={isUploaded}
          onClick={() => {
            if (isUploaded) return

            select({ utilizationReportId: item.id })
            setFlowTo(
              '/App/MyAccount/Content/Profile/Content/Utilization/Item/Content'
            )
          }}
        />
      </DataProvider>
    )
  }

  // don't use react-window and autosizer in short lists
  // a simple Array.map works fine there
  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          height={height}
          itemCount={data.utilization_reports.length}
          itemSize={ROW_HEIGHT}
          width={width}
        >
          {Row}
        </List>
      )}
    </AutoSizer>
  )
}
Logic.defaultProps = {
  filter: '',
}
