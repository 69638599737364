import { AUTH } from 'Data/constants.js';
import { DataProvider, useData } from 'Data/ViewsData.js';
import { isInvalid } from 'utils/graphQlHelpers.js';
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'utils/useNotifications.js';
import { useAuth } from 'Data/Auth.js';
import View from './view.js';
import React from 'react';

let ITEM = {
  signin_new_password_required: {
    password: '',
  },
};

export default function Logic() {
  let [, notify] = useNotifications();
  let auth = useAuth();
  let dataAuthUser = useData({ context: 'auth', path: 'auth.user' });

  return (
    <DataProvider
      context="signin_new_password_required"
      value={ITEM}
      onSubmit={onSubmit}
    >
      <View />
    </DataProvider>
  );

  async function onSubmit(next) {
    if (
      isInvalid(
        AUTH.SIGNIN_NEW_PASSWORD_REQUIRED,
        next.signin_new_password_required,
        notify
      )
    )
      return true;

    try {
      auth.getUserContext(
        await auth.signInNewPasswordRequired({
          user: dataAuthUser.value,
          password: next.signin_new_password_required.password,
        })
      );
      notify(notifySuccess('Your new password is ready'));
    } catch (error) {
      console.error(error);
      notify(notifyError('Something went wrong. Please, try again.'));
    }
  }
}
