import { useFlow, useSetFlowTo } from 'Logic/ViewsFlow.js';
import View from './view.js';
import React, { useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';

export default function Logic() {
  let dropdownRef = useRef();
  let flow = useFlow();
  let setFlowTo = useSetFlowTo();
  let story =
    '/App/MyAccount/Content/Plans/Content/Procedures/Content/MoreActions';
  let isShowing = flow.has(`${story}/Content`);

  useOnClickOutside(dropdownRef, () => {
    if (isShowing) {
      setFlowTo(`${story}/No`);
    }
  });

  return (
    <View
      dropdownRef={dropdownRef}
      isShowing={isShowing}
      onClick={() => {
        setFlowTo(`${story}/${isShowing ? 'No' : 'Content'}`);
      }}
    />
  );
}
