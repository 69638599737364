import View from './view.js';
import React from 'react';
import ReactToPrint from 'react-to-print';

export default function Logic(props) {
  return (
    <ReactToPrint
      trigger={() => <View />}
      content={() => props.contentRef.current}
    />
  );
}
