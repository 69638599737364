import { useCompaniesSelected } from 'Logic/useFilterByCompanies.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  let filter = useCompaniesSelected({
    type: 'Provider',
    has_accepted_agreement: true,
  });
  return <View {...filter} />;
}
