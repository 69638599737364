import { downloadCsv } from 'utils/csv.js';
import { useSelected } from 'Data/Selected.js';
import { useQuery } from 'Data/Api.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import format from 'number-format.js';
import gql from 'graphql-tag';
import View from './view.js';
import React, { useEffect } from 'react';

let query = gql`
  query get_procedures($plan_id: uuid!) {
    plan: plans_by_pk(id: $plan_id) {
      name
    }
    procedures(
      where: { plan_id: { _eq: $plan_id } }
      order_by: { prepaid: desc, code: asc, name: desc }
    ) {
      id
      name
      patient_fee_value
      is_patient_fee_percentage
      code
      prepaid
    }
  }
`;

let fields = ['Code', 'Name', 'Discount', 'Included Per Year', 'Plan'];

function processData({ procedures, plan }) {
  let data = procedures.map((item) => [
    item.code,
    item.name,
    format(
      item.is_patient_fee_percentage ? '###.##%' : '$#,##0.00',
      item.patient_fee_value
    ),
    item.prepaid,
    plan.name,
  ]);

  downloadCsv({
    name: `greyfinch-plan-${plan.name.toLowerCase()}-procedures-report.csv`,
    fields,
    data,
  });
}

export default function Logic(props) {
  let [selected] = useSelected();
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: {
      plan_id: selected.myPlanId,
    },
  });
  let setFlowTo = useSetFlowTo();

  let story =
    '/App/MyAccount/Content/Plans/Content/Procedures/Content/MoreActions/Content/DownloadCSV';
  useEffect(() => {
    if (error) {
      setFlowTo(`${story}/Failure`);
    } else {
      if (!fetching && data) {
        // Process the data
        try {
          processData(data);
          setFlowTo(`${story}/Done`);
        } catch (e) {
          console.error(e);
          setFlowTo(`${story}/Failure`);
        }
      }
    }
  }, [story, setFlowTo, data, fetching, error]);
  return <View />;
}
