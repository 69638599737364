import useSetFlowToBasedOnData from 'Data/useSetFlowToBasedOnData.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  useSetFlowToBasedOnData({
    path: 'user.is_deleting_member',
    context: 'user',
    story:
      '/App/MyAccount/Content/Profile/Content/Admins/Item/Content/Delete/OtherRoles/Content/Employee/Content/Delete',
  });

  return <View />;
}
