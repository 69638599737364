import { captureBreadcrumb } from 'utils/ErrorBoundary.js';
import { useActAsSupport } from 'Logic/ActAsSupport.js';
import { useFlow, useSetFlowTo } from '../Logic/ViewsFlow.js';
import AmplifyAuth from '@aws-amplify/auth';
import flat from 'lodash/flatten';
import isBefore from 'date-fns/isBefore';
import get from 'lodash/get';
import gql from 'graphql-tag';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import qs from 'querystringify';
import useLazyQuery from 'Data/useLazyQuery.js';

let DEFAULT_STATE = {
  data: {
    userId: null,
    companies: [],
    adminCompanies: [],
    userCompanies: [],
  },
  permissions: {
    canBeSupport: false,
    isNewUser: false,
    isProvider: false,
    isProviderAdmin: false,
    isProviderUser: false,
    isMemberGroup: false,
    isMemberGroupAdmin: false,
    isMemberGroupUser: false,
    isBroker: false,
    isBrokerAdmin: false,
    isBrokerUser: false,
    hasToAcceptAndSignAgreement: false,
  },
};

// FIXME see useEffect with comment FIXME invite see above hook
// moving invite temporarily above so it picks it up before it gets a
// change to be removed
let { invite } = qs.parse(window.location.search);

export function Auth(props) {
  let flow = useFlow();
  let setFlowTo = useSetFlowTo();
  let [isActingAsSupport, toggleActAsSupport] = useActAsSupport();
  // TODO rework the relation between the graphql client and the auth module
  // I put a client above the Auth and one right next to the MyAccount so we can
  // remove the cache when the user logs out because of how the graphql clients
  // get stacked through context but even though it's achieving the goal of
  // removing the cached data after sign in, this isn't ideal long term
  // because the user query from here stays there until the page is closed
  // not much can be done with it but ideally we'd make it less obvious
  // one way would be to use a new client here https://formidable.com/open-source/urql/docs/urql-outside-react/
  // or to use fetch directly, let's explore options
  let [userContext, executeQueryGetUserContext] = useLazyQuery({
    query,
    requestPolicy: 'network-only',
  });

  let [state, setState] = useState(DEFAULT_STATE);
  let isRefreshToken = useRef(false);
  let syncedIsActingAsSupport = useRef(false);

  let authContext = useMemo(
    () => {
      async function getUserContext(user) {
        let shouldSetFlow = true;
        // prevent auth from hijacking Tools state
        if (process.env.NODE_ENV === 'development') {
          if (
            process.env.REACT_APP_VIEWS_TOOLS &&
            !flow.has(props.getSignInStory(state)) &&
            !flow.has(props.getResetPasswordConfirmStory(state)) &&
            !flow.has(props.getSignupConfirmStory(state))
          ) {
            shouldSetFlow = false;
          }
        }
        if (shouldSetFlow) {
          setFlowTo(props.getLoadingStory(state));
        }

        if (process.env.NODE_ENV === 'development') {
          if (!user) {
            try {
              user = await AmplifyAuth.currentAuthenticatedUser();
            } catch (error) {
              console.error(error);
            }
          }
        }

        if (!user) {
          if (process.env.NODE_ENV === 'development') {
            console.warn(
              'You need to login/signup with a valid user. See cognitoUsers in sample-data/make.js or make your own and attach your cognito_id and details to that list.'
            );
          }
          await authContext.signOut();
          return;
        }

        let payload = JSON.parse(
          user.signInUserSession.idToken.payload['https://hasura.io/jwt/claims']
        );

        captureBreadcrumb({
          category: 'auth',
          data: payload,
        });

        executeQueryGetUserContext({
          variables: { id: payload['x-hasura-user-id'] },
        });
      }

      let signIn = ({ email, password }) =>
        AmplifyAuth.signIn(email && email.toLowerCase(), password);
      let signInNewPasswordRequired = ({ user, password }) =>
        AmplifyAuth.completeNewPassword(user, password);

      let signUp = ({ email, password, first_name, last_name }) =>
        AmplifyAuth.signUp({
          username: email && email.toLowerCase(),
          password,
          attributes: {
            email: email && email.toLowerCase(),
            family_name: last_name,
            name: first_name,
          },
        });
      let signUpConfirm = ({ email, code }) =>
        AmplifyAuth.confirmSignUp(email && email.toLowerCase(), code);
      let signUpConfirmResendCode = ({ email }) =>
        AmplifyAuth.resendSignUp(email && email.toLowerCase());

      // TODO FIXME clean up on local!
      async function userExists(email) {
        try {
          await AmplifyAuth.confirmSignUp(
            email && email.toLowerCase(),
            '000000',
            {
              // If set to False, the API will throw an AliasExistsException error if the phone number/email used already exists as an alias with a different user
              forceAliasCreation: false,
            }
          );
          return true;
        } catch (error) {
          switch (error.code) {
            case 'UserNotFoundException':
            case 'ResourceNotFoundException':
              return false;
            case 'NotAuthorizedException':
            case 'AliasExistsException': // Email alias already exists
            case 'CodeMismatchException':
            case 'ExpiredCodeException':
            default:
              return true;
          }
        }
      }

      async function signOut() {
        await AmplifyAuth.signOut();
        syncedIsActingAsSupport.current = false;
        if (isActingAsSupport) {
          toggleActAsSupport();
        }
        setFlowTo(props.getSignInStory(state));
        setState(DEFAULT_STATE);
        if (process.env.NODE_ENV === 'development') {
          if (process.env.REACT_APP_VIEWS_TOOLS) {
            sessionStorage.clear();
          }
        }
      }

      let resetPassword = ({ email }) =>
        AmplifyAuth.forgotPassword(email && email.toLowerCase());
      let resetPasswordConfirm = ({ email, code, password }) =>
        AmplifyAuth.forgotPasswordSubmit(
          email && email.toLowerCase(),
          code,
          password
        );

      async function changePassword({ old_password, new_password }) {
        let user = await AmplifyAuth.currentAuthenticatedUser();
        return await AmplifyAuth.changePassword(
          user,
          old_password,
          new_password
        );
      }

      async function refreshToken() {
        let res = await new Promise(async (resolve, reject) => {
          try {
            let cognitoUser = await AmplifyAuth.currentAuthenticatedUser();
            let currentSession = await AmplifyAuth.currentSession();
            cognitoUser.refreshSession(
              currentSession.refreshToken,
              (err, session) => (err ? reject(err) : resolve(session))
            );
          } catch (error) {
            reject(error);
          }
        });

        isRefreshToken.current = true;
        executeQueryGetUserContext({ variables: { id: state.data.userId } });
        return res;
      }

      return {
        ...state,
        changePassword,
        getUserContext,
        signIn,
        signInNewPasswordRequired,
        signUp,
        signUpConfirm,
        signUpConfirmResendCode,
        signOut,
        resetPassword,
        resetPasswordConfirm,
        refreshToken,
        userExists,
      };
    },
    [state, userContext, flow] // eslint-disable-line
  ); // ignore getUserContext and setFlowTo

  // FIXME invite see above hook
  // check for login on start
  useEffect(() => {
    (async () => {
      try {
        let user = await AmplifyAuth.currentAuthenticatedUser();
        if (
          isBefore(
            user.signInUserSession.idToken.payload.exp * 1000,
            Date.now()
          )
        ) {
          throw new Error('Session expired');
        }

        authContext.getUserContext(user);
        setFlowTo(props.getReadyStory(state));
      } catch (error) {
        // TODO FIXME cleaner way to prevent redirect
        if (!invite) {
          //simple workaround to prevent setting signin on invite
          setFlowTo(props.getSignInStory(state));
        }
      }
    })();
  }, [isActingAsSupport, toggleActAsSupport]); // eslint-disable-line
  // only run once

  // parse the user context when it changes
  useEffect(() => {
    if (userContext.fetching || !userContext.data) return;

    if (userContext.error || !userContext.data.user) {
      authContext.signOut();
      return;
    }

    try {
      setState(parseUserContext(userContext, isActingAsSupport));
    } catch (error) {
      authContext.signOut();
    }
  }, [userContext, isActingAsSupport]); // eslint-disable-line
  // ignore authContext

  // react to the state changing
  useEffect(() => {
    if (!state.data.userId) return;

    // leaving this commented out for now because it was creating a weird
    // inconsistency with production when the flow changing was causing the
    // story to be reset
    //
    //     // prevent auth from hijacking Tools state
    //     if (process.env.NODE_ENV === 'development') {
    //       if (
    //         process.env.REACT_APP_VIEWS_TOOLS &&
    //         !flow.has(props.getLoadingStory(state))
    //       ) {
    //         isRefreshToken.current = false;
    //         return;
    //       }
    //     }

    if (!isRefreshToken.current) {
      setFlowTo(props.getReadyStory(state));
    }

    if (!syncedIsActingAsSupport.current) {
      syncedIsActingAsSupport.current = true;

      if (state.permissions.canBeSupport) {
        toggleActAsSupport();
      }
    }
    isRefreshToken.current = false;
  }, [state.data.userId /* , flow*/]); // eslint-disable-line
  // props.getReadyStory, setFlowTo

  return (
    <AuthContext.Provider value={authContext}>
      {props.children}
    </AuthContext.Provider>
  );
}
Auth.defaultProps = {
  getLoadingStory: () => '/App/Loading',
  getSignInStory: () => {
    if (/signup/.test(window.location.search)) {
      return '/App/Auth/SignUp';
    }
    return '/App/Auth/SignIn';
  },
  getResetPasswordConfirmStory: () => '/App/Auth/ResetPasswordConfirm',
  getSignupConfirmStory: () => '/App/Auth/SignUpConfirm',
  getReadyStory: () => '/App/MyAccount',
};

let AuthContext = React.createContext([{}, () => {}]);
export let useAuth = () => useContext(AuthContext);
export let useUserId = () => useAuth().data.userId;

let query = gql`
  query get_user_context($id: uuid!) {
    user: users_by_pk(id: $id) {
      id
      can_be_support
      company_users {
        role
        company {
          id
          brokered_companies {
            id
            company_agreements {
              id
              type
              has_accepted_agreement
            }
          }
          company_agreements {
            id
            type
            has_accepted_agreement
          }
        }
      }
    }
  }
`;

export let isBroker = item =>
  item.brokered_companies &&
  item.company_agreements.some(aitem => aitem.type === 'Broker');
export let isProvider = item =>
  item.company_agreements.some(aitem => aitem.type === 'Provider');
export let isMemberGroup = item =>
  item.company_agreements.some(aitem => aitem.type === 'MemberGroup');

let getCompaniesWithRole = (company_users, role) =>
  flat(
    company_users
      .filter(item => item.role === role)
      .map(item => [item.company, ...item.company.brokered_companies])
  );

function parseUserContext(userContext, isActingAsSupport) {
  let userId = get(userContext.data, 'user.id');
  let canBeSupport = get(userContext.data, 'user.can_be_support');
  let companies = get(userContext.data, 'user.company_users', []);
  let adminCompanies = getCompaniesWithRole(companies, 'company-admin');
  let userCompanies = getCompaniesWithRole(companies, 'company-user');
  companies = flat(
    companies.map(item => [item.company, ...item.company.brokered_companies])
  );

  let isProviderAdmin = adminCompanies.some(isProvider);
  let isProviderUser = userCompanies.some(isProvider);

  let isMemberGroupAdmin = adminCompanies.some(isMemberGroup);
  let isMemberGroupUser = userCompanies.some(isMemberGroup);

  let isBrokerAdmin = adminCompanies.some(isBroker);
  let isBrokerUser = userCompanies.some(isBroker);

  // TODO replace this for a better check
  // it's hacked in to allow for broker users not to see the members section
  // when they're not acting as support
  if ((isBrokerAdmin || isBrokerUser) && !isActingAsSupport) {
    adminCompanies = adminCompanies.filter(item => !isProvider(item));
    isProviderAdmin = false;

    userCompanies = userCompanies.filter(item => !isProvider(item));
    isProviderUser = false;
  }

  let isAdmin = isBrokerAdmin || isProviderAdmin || isMemberGroupAdmin;
  let isUser = isBrokerUser || isProviderUser || isMemberGroupUser;

  let isNewUser = !(
    isProviderAdmin ||
    isProviderUser ||
    isMemberGroupAdmin ||
    isMemberGroupUser ||
    isBrokerAdmin ||
    isBrokerUser
  );

  let hasToAcceptAndSignAgreement = false;
  if (isBrokerAdmin) {
    hasToAcceptAndSignAgreement = companies
      .filter(isBroker)
      .some(item =>
        item.company_agreements.some(aitem => !aitem.has_accepted_agreement)
      );
  } else if (isAdmin) {
    hasToAcceptAndSignAgreement = companies.some(item =>
      item.company_agreements.some(aitem => !aitem.has_accepted_agreement)
    );
  }

  return {
    data: {
      userId,
      companies,
      adminCompanies,
      userCompanies,
    },
    permissions: {
      canBeSupport,
      isNewUser,
      isProvider: isProviderAdmin || isProviderUser,
      isProviderAdmin,
      isProviderUser,
      isMemberGroup: isMemberGroupAdmin || isMemberGroupUser,
      isMemberGroupAdmin,
      isMemberGroupUser,
      isBroker: isBrokerAdmin || isBrokerUser,
      isBrokerAdmin,
      isBrokerUser,
      hasToAcceptAndSignAgreement,
      isAdmin,
      isUser,
    },
  };
}
