import * as isValid from 'Data/validate.js';
import { socialSecurityNumber } from 'Data/format.js';
import { DataProvider } from 'Data/ViewsData.js';
import { ADD } from 'Data/constants.js';
import { isInvalid, parseGraphqlError } from 'utils/graphQlHelpers.js';
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'utils/useNotifications.js';
import { parseCsv } from 'utils/csv.js';
import { useMutation, useQuery } from 'Data/Api.js';
import { useSelected } from 'Data/Selected.js';
import { useFlow, useSetFlowTo } from 'Logic/ViewsFlow.js';
import { dateShortOut } from 'utils/dateHelpers.js';
import View from './view.js';
import gql from 'graphql-tag';
import React, { useCallback, useMemo } from 'react';
import toPascalCase from 'to-pascal-case';
import toSnakeCase from 'to-snake-case';

let query = gql`
  query {
    plans {
      id
      name
    }
    users {
      email
    }
  }
`;

let MUTATION = gql`
  mutation insertMember($objects: [members_insert_input!]!) {
    insert_members(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

function hasEmployeeDependentData(item) {
  return (i) =>
    item[`dep_${i}_relationship`] ||
    item[`dep_${i}_first_name`] ||
    item[`dep_${i}_last_name`] ||
    item[`dep_${i}_ssn`] ||
    item[`dep_${i}_dob`] ||
    item[`dep_${i}_gender`];
}

function getEmployeeDependentErrors(item) {
  return MAX_DEPENDENTS.filter(hasEmployeeDependentData(item)).map((i) => [
    !isValid.textInput(item[`dep_${i}_first_name`]) && `dep ${i} first name`,
    !isValid.textInput(item[`dep_${i}_last_name`]) && `dep ${i} last name`,
    !isValid.relationship(item[`dep_${i}_relationship`]) &&
      `dep ${i} relationship`,
    !isValid.social_security_number(item[`dep_${i}_ssn`]) && `dep ${i} ssn`,
    !isValid.birthday(item[`dep_${i}_dob`]) && `dep ${i} dob`,
    !isValid.gender(item[`dep_${i}_gender`]) && `dep ${i} gender`,
  ]);
}

function getEmployeeErrors(item, company) {
  return [
    !isValid.plan(item.plan_name) && 'plan name',
    !isValid.textInput(item.employee_first_name) && 'employee first name',
    !isValid.textInput(item.employee_last_name) && 'employee last name',
    !isValid.email(item.employee_email) && 'employee email',
    company.emails.has(item.employee_email) && 'employee email exists',
    !isValid.phoneNumber(item.employee_phone_number) && 'employee phone number',
    company.phone_numbers.has(item.employee_phone_number) &&
      'employee phone number exists',
    !isValid.social_security_number(item.employee_ssn) && 'employee ssn',
    !isValid.gender(item.employee_gender) && 'employee gender',
    !isValid.birthday(item.employee_dob) && 'employee dob',
    !isValid.maritalStatus(item.employee_marital_status) &&
      'employee marital status',
    !isValid.textInput(item.address) && 'address',
    !isValid.textInput(item.city) && 'city',
    !isValid.state(item.state) && 'state',
    !isValid.zip(item.zip) && 'zip',
    ...getEmployeeDependentErrors(item),
  ]
    .flat(Infinity)
    .filter(Boolean);
}

async function parseEmployeesCsv(file, company) {
  let results = await parseCsv({
    file,
    header: true,
    skipEmptyLines: true,
    transformHeader: toSnakeCase,
    transform: (rvalue, key) => {
      if (!rvalue) return;

      let value = rvalue.trim();

      if (/(plan_name|gender|marital_status|relationship)/.test(key)) {
        return toPascalCase(value);
      } else if (/(dob)/.test(key)) {
        return dateShortOut(value);
      } else if (/email/.test(key)) {
        return value.toLowerCase();
      } else if (/phone_number/.test(key)) {
        value = value.replace(/[\s-()]/g, '');
        return value.startsWith('+') ? value : `+1${value}`;
      } else if (/ssn/.test(key)) {
        return socialSecurityNumber(value);
      } else {
        return value;
      }
    },
  });

  if (results.error) {
    throw new Error(results.error);
  }

  return (
    results.data
      // Discard empty lines
      .filter((item) => Object.values(item).some(Boolean))
      .map((item) => {
        let errors = getEmployeeErrors(item, company);

        return {
          ...item,
          errors: errors.join(', '),
          is_valid: errors.length === 0,
        };
      })
      .sort((a, b) => (!a.is_valid && !b.is_valid ? 0 : !a.is_valid ? -1 : 1))
  );
}

// we have 8 dependents max in the csv and they start with index 1, so we take 9
// and get the 8 elements after the first
let MAX_DEPENDENTS = [...Array(9).keys()].slice(1);

function getMemberDependents(item) {
  return MAX_DEPENDENTS.filter(hasEmployeeDependentData(item)).map((i) => ({
    relationship_to_main_member: item[`dep_${i}_relationship`],
    profile: {
      data: {
        first_name: item[`dep_${i}_first_name`],
        last_name: item[`dep_${i}_last_name`],
        social_security_number: item[`dep_${i}_ssn`],
        date_of_birth: item[`dep_${i}_dob`],
        gender: item[`dep_${i}_gender`],
      },
    },
  }));
}

export default function Logic() {
  let story = '/App/MyAccount/Content/Profile/Content/Employees/AddManyItems';
  let setFlowTo = useSetFlowTo();
  let flow = useFlow();
  let [, notify] = useNotifications();
  let [selected, select] = useSelected();
  let [, executeMutation] = useMutation(MUTATION);
  // TODO use fn to check if they exist first or use lazy query at least
  let [{ fetching, error, data }] = useQuery({ query });
  let employeesImportValue = useMemo(
    () => ({
      employees_import: {
        company_id: selected.companies_ids[0] || null,
        effective_date: null,
        employees: [],
        file: null,
      },
    }),
    [] // eslint-disable-line
  );

  let onChange = useCallback(
    async (next, change) => {
      if (
        flow.has(`${story}/Content/Data/Upload`) &&
        next.employees_import.file
      ) {
        try {
          if (
            next.employees_import.file.name.split('.').pop().toLowerCase() !==
            'csv'
          ) {
            throw new Error('Invalid extension');
          }

          setFlowTo(`${story}/Content/Data/Loading`);

          let nextEmployees = await parseEmployeesCsv(
            next.employees_import.file,
            {
              emails: new Set(data.users.map((item) => item.email)),
              phone_numbers: new Set(
                data.users.map((item) => item.phone_number)
              ),
            }
          );

          change((next) => {
            next.employees_import.employees = nextEmployees;
          });
        } catch (error) {
          notify(
            notifyError(
              `The uploaded file isn't a valid CSV file. Review it and try again.`
            )
          );
          change((next) => {
            next.employees_import.file = null;
          });
          console.error(error);
        }
      } else if (flow.has(`${story}/Content/Data/Loading`)) {
        if (next.employees_import.employees.length === 0) {
          notify(notifyError('The uploaded CSV file is empty'));
          setFlowTo(`${story}/Content/Data/Upload`);
        } else {
          setFlowTo(`${story}/Content/Data/Verify`);
        }
      }
    },
    [flow, data] // eslint-disable-line
  ); // ignore notify, setFlowTo, story

  if (fetching) return null;
  if (error) return `😱 ${error.message}`;

  return (
    <DataProvider
      context="employees_import"
      onChange={onChange}
      onSubmit={onSubmit}
      value={employeesImportValue}
    >
      <View />
    </DataProvider>
  );

  async function onSubmit(next) {
    if (isInvalid(ADD.MANY_MEMBERS, next.employees_import, notify)) return true;

    let objects = next.employees_import.employees.map((item) => {
      let oitem = {
        company_id: next.employees_import.company_id,
        is_configuration_up_to_member: false,
        effective_date: next.employees_import.effective_date,
        plan_id: data.plans.find((pitem) => pitem.name === item.plan_name).id,
        user: {
          data: {
            email: item.employee_email,
            phone_number: item.employee_phone_number,
          },
        },
        profile: {
          data: {
            first_name: item.employee_first_name,
            last_name: item.employee_last_name,
            date_of_birth: item.employee_dob,
            gender: item.employee_gender,
            marital_status: item.employee_marital_status,
            social_security_number: item.employee_ssn,
            address: {
              data: {
                street: item.address,
                city: item.city,
                state: item.state,
                zip: item.zip,
              },
            },
          },
        },
      };

      let related_members = getMemberDependents(item);
      if (related_members.length > 0) {
        oitem.related_members = {
          data: related_members.map((item) => ({
            ...item,
            is_configuration_up_to_member: oitem.is_configuration_up_to_member,
            effective_date: oitem.effective_date,
            plan_id: oitem.plan_id,
          })),
        };
      }

      return oitem;
    });

    let mutationResponse = await executeMutation({ objects });
    if (mutationResponse.error) {
      notify(notifyError(parseGraphqlError(mutationResponse.error).message));
    } else {
      select({ nothing: Date.now() });
      notify(notifySuccess(`Successfully added ${objects.length} employees!`));
      setFlowTo(`${story}/No`);
    }
  }
}
