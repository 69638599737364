import _formatDate from 'date-fns/format';
import isValidDate from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import parseDate from 'date-fns/parse';

export let yearMonthDay = 'yyyy-MM-dd';
export let yearMonthDayTimestamp = `yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX`;

export let monthDayYear = 'MM/dd/yyyy';
export let monthYear = 'MM/yy';

export function formatDate(rvalue, formatIn, formatOut) {
  let value = rvalue;
  if (typeof rvalue === 'string') {
    value =
      formatIn === 'iso'
        ? parseISO(rvalue)
        : parseDate(rvalue, formatIn, new Date());
  }
  return isValidDate(value) ? _formatDate(value, formatOut) : rvalue;
}

export function dateShortIn(value) {
  return formatDate(value, 'yyyy-MM-dd', 'MM/dd/yyyy');
}
export function dateShortOut(value) {
  return formatDate(value, 'MM/dd/yyyy', 'yyyy-MM-dd');
}

export function dateISOIn(value) {
  return formatDate(value, 'iso', 'MM/dd/yyyy');
}
export function dateISOOut(value) {
  return formatDate(value, 'MM/dd/yyyy', 'iso');
}

// let tryToParse = (value, parsers = []) => {
//   if (typeof value !== 'string') return value;
//   let parsedValue;
//   for (let parser of parsers) {
//     parsedValue = parser(value);
//     if (isValid(parsedValue)) return parsedValue;
//   }
// };

// export let formatDate = (rvalue, format) => {
//   let value = tryToParse(rvalue, [
//     parseISO,
//     value => parse(value, 'MM/dd/yyyy', new Date()),
//   ]);
//   return isValid(value) ? doFormat(value, format) : '';
// };
