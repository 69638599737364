import { injectGlobal } from 'emotion'
import eot from './WonderUnitSans-500.eot'
import truetype from './WonderUnitSans-500.ttf'
import woff from './WonderUnitSans-500.woff'
import woff2 from './WonderUnitSans-500.woff2'

injectGlobal(`@font-face {
  font-family: 'WonderUnitSans';
  font-style: normal;
  font-weight: 500;
  src: url(${eot}) format('eot'), url(${truetype}) format('truetype'), url(${woff}) format('woff'), url(${woff2}) format('woff2');
}`)