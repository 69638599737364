import { useData } from 'Data/ViewsData.js';
import { useQuery } from 'Data/Api.js';
import gql from 'graphql-tag';
import View from './view.js';
import React, { useMemo } from 'react';

let query = gql`
  query get_provider_locations {
    provider_locations {
      id
      doing_business_as_and_address
      is_accepting_new_patients
    }
  }
`;

let context = {
  fetchOptions: {
    headers: {
      'x-hasura-role': 'public',
    },
  },
};

export default function Logic() {
  let itemProviderLocationId = useData({
    context: 'member',
    path: 'member.provider_location_id',
  });
  let itemProfessionalId = useData({
    context: 'member',
    path: 'member.professional_id',
  });

  let [{ fetching, error, data }] = useQuery({ query, context });

  let list = useMemo(() => {
    if (!data) return [];

    return data.provider_locations.map((item) => ({
      ...item,
      banner:
        !item.is_accepting_new_patients &&
        'Currently not accepting new patients',
    }));
  }, [data]);

  let isShowingWarning = useMemo(() => {
    if (!data) return false;

    let selected = data.provider_locations.find(
      (item) => item.id === itemProviderLocationId.value
    );
    return selected && !selected.is_accepting_new_patients;
  }, [data, itemProviderLocationId]);

  if (fetching && !data) return null;
  if (error) return `😱 ${error.message}`;

  return (
    <View
      from={list}
      onChangeManual={(id) => {
        itemProviderLocationId.onChange(id);
        itemProfessionalId.onChange(null);
      }}
      isShowingWarning={isShowingWarning}
    />
  );
}
