import { useData } from 'Data/ViewsData.js';
import { useQuery } from 'Data/Api.js';
import View from './view.js';
import gql from 'graphql-tag';
import React from 'react';

let query = gql`
  query get_company_doing_business_as($company_id: uuid!) {
    company_doing_business_as(where: { company_id: { _eq: $company_id } }) {
      id
      name
    }
  }
`;

export default function Logic() {
  let dataProviderLocation = useData({
    context: 'provider_location',
    path: 'provider_location.company_id',
  });
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: { company_id: dataProviderLocation.value },
  });

  if (fetching || !data) return null;
  if (error) return `😱 ${error.message}`;

  return <View from={data.company_doing_business_as} />;
}
