import { useEffect, useState } from 'react';
import useGoogleMapsApi from './useGoogleMapsApi.js';

function geocodeByPlaceId(maps, placeId) {
  return new Promise((resolve, reject) => {
    let geocoder = new maps.api.Geocoder();
    geocoder.geocode({ placeId }, (results, status) => {
      if (status === maps.api.GeocoderStatus.OK) {
        resolve(results[0]);
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.error(
            'useGoogleMapsPlacesAutocomplete#geocodeByPlaceId',
            status
          );
        }

        reject(status);
      }
    });
  });
}

// these calls are expensive, let's keep an internal cache
let addresses = new Map();
let places = new Map();

// https://gist.github.com/jakebathman/719e8416191ba14bb6e700fc2d5fccc5
// https://anthonylouisdagostino.com/bounding-boxes-for-all-us-states/
let STATES = {
  'state AR': {
    southWest: {
      lat: 33.0075,
      lng: -94.6198,
    },
    northEast: {
      lat: 36.4997,
      lng: -89.6594,
    },
  },
};

export default function useGoogleMapsPlacesAutocomplete({
  address,
  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
  restrict,
  autocompleteOptions = {},
  key,
}) {
  let maps = useGoogleMapsApi({ key, libraries: 'places' });
  let [state, setState] = useState({
    status: 'loading',
    results: [],
  });

  useEffect(() => {
    if (!maps.isReady || !address) return;

    let addressKey = `${address}${restrict || ''}`;

    if (addresses.has(addressKey)) {
      setState({ status: 'ready', results: addresses.get(addressKey) });
      return;
    }

    setState({ status: 'loading', results: [] });

    let options = {
      input: address,
      ...autocompleteOptions,
    };

    if (restrict) {
      if (restrict in STATES) {
        options.bounds = new maps.api.LatLngBounds(
          STATES[restrict].southWest,
          STATES[restrict].northEast
        );
        options.strictBounds = true;
      } else {
        throw new Error(
          `restrict "${restrict}" isn't a valid option. Valid values: "${Object.keys(
            STATES
          )}"`
        );
      }
    }

    let service = new maps.api.places.AutocompleteService();
    service.getPlacePredictions(options, (rawResults, status) => {
      // TODO Do we need to handle other statuses?
      // https://developers.google.com/maps/documentation/javascript/reference/places-service#PlacesServiceStatus
      if (status === maps.api.places.PlacesServiceStatus.OK) {
        let results = maybeRestrictResults(rawResults, restrict);
        addresses.set(addressKey, results);

        setState({
          status: 'ready',
          results,
        });
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.debug('useGoogleMapsPlacesAutocomplete', status);
        }

        setState({ status: 'error', results: [] });
      }
    });
  }, [address, maps.isReady]); // eslint-disable-line
  // we are assigning the value of service as a side effect, so we don't want to insert it as a dependency

  async function getAddressForPlaceId(placeId) {
    if (!maps.isReady) return null;

    if (!places.has(placeId)) {
      try {
        places.set(placeId, await geocodeByPlaceId(maps, placeId));
      } catch (error) {
        places.set(placeId, null);
      }
    }

    return places.get(placeId);
  }

  return {
    isError: state.status === 'error',
    isLoading: state.status === 'loading',
    isReady: state.status === 'ready',
    results: state.results,
    getAddressForPlaceId,
  };
}

function maybeRestrictResults(results, restrict) {
  if (!restrict || !(restrict in STATES)) return results;

  let [, state] = restrict.split(' ');
  return results.filter(item =>
    item.terms.some(titem => titem.value === state)
  );
}
