import { isLastPrimaryCareProfessionalInALocation } from 'Data/format.js';
import useSetFlowToBasedOnData from 'Data/useSetFlowToBasedOnData.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  useSetFlowToBasedOnData({
    path: 'user',
    context: 'user',
    story:
      '/App/MyAccount/Content/Profile/Content/MyProfessionals/Item/Content/Delete/OtherRoles',
    isContent: (value) =>
      value.is_deleting_professional &&
      value.professional &&
      !isLastPrimaryCareProfessionalInALocation(value.professional),
  });

  return <View />;
}
