import View from './view.js';
import React, { useRef, useReducer } from 'react';

export default function Logic(props) {
  let [isShowing, onClickIsShowing] = useReducer((v) => !v, false);
  let input = useRef(null);

  return (
    <View
      {...props}
      innerRef={input}
      isShowing={isShowing}
      onClickIsShowing={() => {
        onClickIsShowing();
        input.current.focus();
      }}
    />
  );
}
