import { DataProvider } from 'Data/ViewsData.js';
import { useSelected } from 'Data/Selected.js';
import { useQuery } from 'Data/Api.js';
import gql from 'graphql-tag';
import React from 'react';
import View from './view.js';

let query = gql`
  query get_professional($id: uuid!) {
    professional: professionals_by_pk(id: $id) {
      id
      user {
        id
        email
        phone_number
        profile {
          id
          first_name
          last_name
        }
      }
      is_accepting_new_patients
      license_number
      license {
        id
        name
      }
      professional_locations {
        provider_location {
          id
          address {
            id
            street
            city
            state
            zip
          }
          company_doing_business_as {
            id
            name
          }
        }
      }
    }
  }
`;

export default function Logic() {
  let [selected] = useSelected();
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: { id: selected.professionalId },
  });

  if (fetching) return null;
  if (error) return `😱 ${error.message}`;

  return (
    <DataProvider context="professional" value={data}>
      <View />
    </DataProvider>
  );
}
