import { useData } from 'Data/ViewsData.js';
import { useQuery } from 'urql';
import gql from 'graphql-tag';
import View from './view.js';
import React, { useMemo } from 'react';

let query = gql`
  query get_professionals($provider_location_id: uuid) {
    professionals(
      where: {
        professional_locations: {
          provider_location_id: { _eq: $provider_location_id }
        }
        license: { is_primary_care: { _eq: true } }
      }
      order_by: { user: { profile: { first_name: asc, last_name: asc } } }
    ) {
      id
      full_name_and_license
      is_accepting_new_patients
    }
  }
`;

let context = {
  fetchOptions: {
    headers: {
      'x-hasura-role': 'public',
    },
  },
};

export default function Logic() {
  let item = useData({
    context: 'member',
    path: 'member.provider_location_id',
  });
  let itemProfessionalId = useData({
    context: 'member',
    path: 'member.professional_id',
  });

  let [{ fetching, error, data }] = useQuery({
    query,
    context,
    variables: {
      provider_location_id: item.value,
    },
  });

  let list = useMemo(() => {
    if (!data) return [];

    return [
      { id: null, full_name_and_license: "I don't have a preference" },
      ...data.professionals.map((item) => ({
        ...item,
        banner:
          !item.is_accepting_new_patients &&
          'Currently not accepting new patients',
      })),
    ];
  }, [data]);

  let isShowingWarning = useMemo(() => {
    if (!data) return false;

    let selected = data.professionals.find(
      (professional) => professional.id === itemProfessionalId.value
    );
    return selected && !selected.is_accepting_new_patients;
  }, [data, itemProfessionalId]);

  if (fetching && !data) return null;
  if (error) return `😱 ${error.message}`;

  return <View from={list} isShowingWarning={isShowingWarning} />;
}
