import { DataProvider } from 'Data/ViewsData.js';
import { useSelected } from 'Data/Selected.js';
import { useQuery } from 'Data/Api.js';
import gql from 'graphql-tag';
import React from 'react';
import View from './view.js';

let query = gql`
  query get_provider_location($id: uuid!) {
    provider_location: provider_locations_by_pk(id: $id) {
      id
      phone_number
      website
      company_doing_business_as {
        id
        name
      }
      address {
        id
        street
        city
        state
        zip
      }
    }
  }
`;

export default function Logic() {
  let [selected] = useSelected();
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: { id: selected.locationId },
  });

  if (fetching) return null;
  if (error) return `😱 ${error.message}`;

  return (
    <DataProvider context="provider_location" value={data}>
      <View />
    </DataProvider>
  );
}
