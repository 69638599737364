import { _and, _eq, _neq, _wilike } from 'Data/hasura.js'
import { FixedSizeList as List } from 'react-window'
import { DataProvider, useData } from 'Data/ViewsData.js'
import { useCompanyUsersFilter } from 'Logic/useFilterByCompanies.js'
import { useSelected } from 'Data/Selected.js'
import { useSetFlowTo } from 'Logic/ViewsFlow.js'
import { useQuery } from 'Data/Api.js'
import AutoSizer from 'react-virtualized-auto-sizer'
import gql from 'graphql-tag'
import View from './view.js'
import LoadingBar from 'DesignSystem/LoadingBar/logic.js'
import React, { useEffect } from 'react'

let query = gql`
  query list_companies($limit: Int, $where: companies_bool_exp) {
    companies(limit: $limit, where: $where) {
      id
      active
      legal_name
      company_agreements {
        id
        type
      }
      broker {
        id
        legal_name
      }
    }
  }
`

function where({
  filter: rfilter,
  company_users,
  showInactiveCompanies,
  nothing,
}) {
  let filter = rfilter.trim()
  let filter_condition = _and(
    filter ? _wilike('legal_name', filter) : _neq('legal_name', `${nothing}`),
    !showInactiveCompanies && _eq('active', true)
  )

  if (company_users) {
    return _and({ company_users }, filter_condition)
  } else {
    return filter_condition
  }
}

// this number is a combination of the 70px of the height plus the 8px
// of the marginTop from the Card in ListItems.view
// until we find a better way to
let ROW_HEIGHT = 78

export default function Logic(props) {
  let filteredCompanies = useData({
    viewPath: props.viewPath,
    path: 'filtered_companies',
    context: 'filtered_companies',
  })
  let company_users = useCompanyUsersFilter()
  let [selected, select] = useSelected()
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: {
      limit: 10000,
      where: where({
        filter: props.filter,
        company_users,
        showInactiveCompanies: selected.showInactiveCompanies,
        nothing: selected.nothing,
      }),
    },
  })
  let setFlowTo = useSetFlowTo()

  useEffect(() => {
    if (!data) return

    filteredCompanies.onChange({
      filtered_companies: data.companies.map(company => company.id),
    })
  }, [data]) // eslint-disable-line

  if (fetching)
    return (
      <LoadingBar
        alignSelf="flexStart"
        borderBottomLeftRadius={4}
        borderBottomRightRadius={4}
        borderTopLeftRadius={4}
        borderTopRightRadius={4}
        height={8}
        marginTop={20}
        width={200}
      />
    )
  if (error) return `😱 ${error.message}`

  function Row({ index, style }) {
    let item = data.companies[index]
    return (
      <DataProvider context="company" key={item.id} value={{ company: item }}>
        <View
          {...style}
          isInactive={!item.active}
          onClick={() => {
            select({ companyId: item.id })
            setFlowTo(
              '/App/MyAccount/Content/Profile/Content/MyCompanies/Item/Content'
            )
          }}
        />
      </DataProvider>
    )
  }

  // don't use react-window and autosizer in short lists
  // a simple Array.map works fine there
  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          height={height}
          itemCount={data.companies.length}
          itemSize={ROW_HEIGHT}
          width={width}
        >
          {Row}
        </List>
      )}
    </AutoSizer>
  )
}
Logic.defaultProps = {
  filter: '',
}
