import React, { useEffect } from 'react'
import { useData } from 'Data/ViewsData.js'
import { useQuery } from 'Data/Api.js'
import { useSetFlowTo } from 'Logic/ViewsFlow.js'
import gql from 'graphql-tag'
import { _in } from 'Data/hasura.js'
import { downloadCsv } from 'utils/csv.js'
import { phoneNumberUS as formatPhoneNumber } from 'Data/format.js'
import View from './view.js'

function where({ location_ids }) {
  return _in('id', location_ids)
}

let query = gql`
  query list_locations($where: provider_locations_bool_exp) {
    provider_locations(where: $where) {
      id
      company {
        legal_name
      }
      company_doing_business_as {
        name
      }
      address {
        street
        city
        zip
        state
      }
      phone_number
      website
      professional_locations {
        professional_id
      }
    }
  }
`

let fields = [
  'Id',
  'Company Legal Name',
  'Company Name',
  'Street',
  'City',
  'Zip',
  'State',
  'Phone Number',
  'Website',
]

function processData({ provider_locations }) {
  let data = provider_locations.map(location => [
    location.id,
    location.company.legal_name,
    location.company_doing_business_as.name,
    location.address.street,
    location.address.city,
    location.address.zip,
    location.address.state,
    formatPhoneNumber(location.phone_number),
    location.website,
  ])
  downloadCsv({
    name: 'greyfinch-locations-report.csv',
    fields,
    data,
  })
}

export default function Logic(props) {
  let locationData = useData({
    viewPath: props.viewPath,
    path: 'filtered_locations',
    context: 'filtered_locations',
  })

  let [{ fetching, error, data }] = useQuery({
    query,
    variables: {
      where: where({
        location_ids: locationData.value.filtered_locations,
      }),
    },
  })
  let setFlowTo = useSetFlowTo()

  let story =
    '/App/MyAccount/Content/Profile/Content/MyLocations/MoreActions/Content/DownloadCSV'
  useEffect(() => {
    if (error) {
      setFlowTo(`${story}/Failure`)
    } else {
      if (!fetching && data) {
        // Process the data
        try {
          processData(data)
          setFlowTo(`${story}/Done`)
        } catch (e) {
          console.error(e)
          setFlowTo(`${story}/Failure`)
        }
      }
    }
  }, [story, setFlowTo, data, fetching, error])
  return <View />
}
