import { useAuth } from './Auth.js';
import { useSelected } from './Selected.js';
import { useMemo } from 'react';

export default function useHasSignedAgreementForSelectedCompany(type = null) {
  let auth = useAuth();
  let [selected] = useSelected();

  return useMemo(() => {
    let company = auth.data.companies.find(item =>
      selected.companies_ids.includes(item.id)
    );
    if (!company) return false;

    if (type) {
      return company.company_agreements.some(
        item => item.type === type && item.has_accepted_agreement
      );
    } else {
      return company.company_agreements.some(
        item => item.has_accepted_agreement
      );
    }
  }, [auth.data.companies, type, selected.companies_ids]);
}
