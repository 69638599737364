import {
  isLastPrimaryCareProfessionalInALocation,
  isLastProfessionalInALocation,
} from 'Data/format.js';
import { useData } from 'Data/ViewsData.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import View from './view.js';
import React, { useEffect } from 'react';

export default function Logic() {
  let setFlowTo = useSetFlowTo();
  let data = useData({
    path: 'user',
    context: 'user',
  });

  useEffect(() => {
    if (
      data.value.professional &&
      (isLastProfessionalInALocation(data.value.professional) ||
        isLastPrimaryCareProfessionalInALocation(data.value.professional))
    ) {
      setFlowTo(
        `/App/MyAccount/Content/Profile/Content/Employees/Item/Content/Delete/Professional/Content/${
          isLastProfessionalInALocation(data.value.professional)
            ? 'LastProfessionalInLocation'
            : 'LastPrimaryCareProfessionalInLocation'
        }`
      );

      if (data.value.is_deleting_professional) {
        data.onChange((next) => {
          next.user.is_deleting_professional = false;
        });
      }
    }
  }, [data]); // eslint-disable-line
  // ignore setFlowTo

  return <View />;
}
