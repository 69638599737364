import { isBroker, useAuth } from './Auth.js'
import {
  MEMBER_STATUSES,
  MEMBER_STATUSES_MEMBERS_LIST_FILTER,
  INDIVIDUAL_STATUSES_FILTER,
} from 'Data/constants.js'
import React, { useContext, useMemo, useReducer } from 'react'
import qs from 'querystringify'

let hasntAcceptedAgreement = item =>
  item.company_agreements.some(aitem => !aitem.has_accepted_agreement)

export function getNextSelectedCompany(auth) {
  let company = null

  // brokers can only sign agreements of their own companies
  if (auth.permissions.isBrokerAdmin) {
    company = auth.data.companies.filter(isBroker).find(hasntAcceptedAgreement)
  } else if (auth.permissions.isAdmin) {
    company = auth.data.companies.find(hasntAcceptedAgreement)
  }

  return company || auth.data.companies[0]
}

export function Selected(props) {
  let auth = useAuth()

  let initialState = useMemo(() => {
    let company = getNextSelectedCompany(auth)
    let { 'upload-utilization-report': utilizationReportId = null } = qs.parse(
      window.location.search
    )
    setTimeout(() => {
      window.history.pushState(null, null, '/')
    }, 1000)

    return {
      employeeId: null,
      verifyId: null,
      memberId: null,
      companyId: company ? company.id : null,
      companies_ids: company ? [company.id] : [],
      locationId: null,
      professionalId: null,
      utilizationReportId,
      employeesStatuses: MEMBER_STATUSES.map(item => item.id),
      membersStatuses: MEMBER_STATUSES_MEMBERS_LIST_FILTER.map(item => item.id),
      individualsStatuses: INDIVIDUAL_STATUSES_FILTER.map(item => item.id),
      showInactiveCompanies: false,
    }
  }, []) // eslint-disable-line
  // run once

  let value = useReducer(reducer, initialState)

  return (
    <SelectedContext.Provider value={value}>
      {props.children}
    </SelectedContext.Provider>
  )
}
Selected.defaultProps = {}

let SelectedContext = React.createContext([{}, () => {}])
export let useSelected = () => useContext(SelectedContext)

let reducer = (state, selection) => ({ ...state, ...selection })
