import { useData } from 'Data/ViewsData.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import View from './view.js';
import React, { useLayoutEffect } from 'react';

export default function Logic() {
  let item = useData({
    context: 'company',
    path: 'company.company_agreements',
  });
  let setFlowTo = useSetFlowTo();

  useLayoutEffect(() => {
    let isProvider = item.value.some((item) => item.type === 'Provider');
    setFlowTo(
      `/App/MyAccount/Content/Profile/Content/MyCompanies/Item/Content/Show/Provider/${
        isProvider ? 'Content' : 'No'
      }`
    );
  }, [item.value]); // eslint-disable-line
  // ignore setFlowTo

  return <View />;
}
