import { useData } from 'Data/ViewsData.js';
import View from './view.js';
import React from 'react';
import uuid from 'uuid/v4';

export default function Logic() {
  let item = useData({
    context: 'company',
    path: 'company.company_doing_business_as',
  });

  return (
    <View
      onClick={() => {
        let emptyValues = item.value.find(({ name }) => name === '');
        // If there is already an empty value in the list, then do not add more inputs
        if (!emptyValues) {
          item.onChange([...item.value, { id: uuid(), name: '' }]);
        }
      }}
    />
  );
}
