import { address as formatAddress } from 'Data/format.js';
import { DataProvider } from 'Data/ViewsData.js';
import {
  useNotifications,
  notifyError,
  notifySuccess,
} from 'utils/useNotifications.js';
import { isInvalid, parseGraphqlError } from 'utils/graphQlHelpers.js';
import { EDIT } from 'Data/constants.js';
import { useSelected } from 'Data/Selected.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import { useQuery, useMutation } from 'Data/Api.js';
import gql from 'graphql-tag';
import React from 'react';
import View from './view.js';

let query = gql`
  query get_provider_location($id: uuid!) {
    provider_location: provider_locations_by_pk(id: $id) {
      id
      phone_number
      website
      company_doing_business_as_id
      company_doing_business_as {
        id
        name
      }
      address {
        id
        street
        city
        state
        zip
      }
    }
  }
`;

let MUTATION = gql`
  mutation update_provider_location(
    $address_id: uuid!
    $address: addresses_set_input
    $provider_location_id: uuid!
    $provider_location: provider_locations_set_input
  ) {
    update_addresses(where: { id: { _eq: $address_id } }, _set: $address) {
      returning {
        id
        street
        city
        state
        zip
      }
    }
    update_provider_locations(
      where: { id: { _eq: $provider_location_id } }
      _set: $provider_location
    ) {
      returning {
        id
        company_doing_business_as_id
        company_doing_business_as {
          id
          name
        }
      }
    }
  }
`;

export default function Logic() {
  let story = '/App/MyAccount/Content/Profile/Content/MyLocations/Item';
  let setFlowTo = useSetFlowTo();
  let [, notify] = useNotifications();
  let [selected] = useSelected();
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: { id: selected.locationId },
  });
  let [, executeMutation] = useMutation(MUTATION);

  if (fetching) return null;
  if (error) return `😱 ${error.message}`;

  return (
    <DataProvider context="provider_location" onSubmit={onSubmit} value={data}>
      <View />
    </DataProvider>
  );

  async function onSubmit(next) {
    if (isInvalid(EDIT.LOCATION, next, notify)) return true;

    let mutationResponse = await executeMutation({
      address_id: data.provider_location.address.id,
      address: {
        street: next.provider_location.address.street,
        city: next.provider_location.address.city,
        state: next.provider_location.address.state,
        zip: next.provider_location.address.zip,
      },
      provider_location_id: selected.locationId,
      provider_location: {
        company_doing_business_as_id:
          next.provider_location.company_doing_business_as_id,
        phone_number: next.provider_location.phone_number,
        website: next.provider_location.website,
      },
    });

    if (mutationResponse.error) {
      notify(notifyError(parseGraphqlError(mutationResponse.error).message));
    } else {
      notify(
        notifySuccess(
          `${formatAddress(
            next.provider_location.address
          )} successfully updated.`
        )
      );
      setFlowTo(`${story}/Content/Show`);
    }
  }
}
