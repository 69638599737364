import View from './view.js'
import React from 'react'

let AutocompleteAddressChoiceLogic = (props) => {
  let id = props.item ? props.item.place_id : props.id
  let text = props.item ? props.item.description : props.text

  return (
    <View
      {...props}
      id={id}
      text={text}
      isSelected={props.selected === props.id}
      onClick={() => props.onClick(id)}
    />
  )
}
export default AutocompleteAddressChoiceLogic
