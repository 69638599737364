import { MEMBER_STATUSES } from 'Data/constants.js'
import { useSelected } from 'Data/Selected.js'
import View from './view.js'
import React from 'react'

export default function Logic() {
  let [selected, setSelected] = useSelected()

  return (
    <View
      from={MEMBER_STATUSES}
      selected={selected.employeesStatuses}
      onClick={onClick}
    />
  )

  function onClick(employeesStatuses) {
    setSelected({ ...selected, employeesStatuses })
  }
}
