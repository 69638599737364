import { _and, _eq, _or, _wilike } from 'Data/hasura.js'
import { useSelected } from 'Data/Selected.js'
import { useQuery } from 'Data/Api.js'
import { DataProvider } from 'Data/ViewsData.js'
import { FixedSizeList as List } from 'react-window'
import gql from 'graphql-tag'
import React, { useEffect, useMemo } from 'react'
import View from './view.js'
import AutoSizer from 'react-virtualized-auto-sizer'

let query = gql`
  query getProcedures($id: uuid, $limit: Int, $where: procedures_bool_exp) {
    procedures(
      limit: $limit
      where: $where
      order_by: { prepaid: desc, code: asc, name: desc }
    ) {
      id
      name
      patient_fee_value
      is_patient_fee_percentage
      code
      prepaid
    }
  }
`

// TODO ask the Hasura crowd if it's better to have it on the query as more
// specific arguments on the query?
// TODO we'll want to check the performance on this query :D
function where({ filter: rfilter = '', planId }) {
  let filter = rfilter.trim()

  return _and(
    _eq('plan_id', planId),
    filter && _or(_wilike('code', filter), _wilike('name', filter))
  )
}

// this number is a combination of the 70px of the height plus the 8px
// of the marginTop from the Card in ListItems.view
// until we find a better way to
let ROW_HEIGHT = 78

export default function Logic(props) {
  let [selected, select] = useSelected()

  useEffect(() => {
    select({ procedures: [] })
  }, [selected.myPlanId]) // eslint-disable-line

  let [{ fetching, error, data }] = useQuery({
    query,
    variables: {
      id: selected.myPlanId,
      where: where({
        filter: props.filter,
        planId: selected.myPlanId,
      }),
      limit: 10000,
    },
  })

  let procedures = useMemo(() => {
    if (!data || !data.procedures) return []
    return data.procedures.map(p => ({
      ...p,
      isSelected: selected.procedures.some(sp => sp.id === p.id),
    }))
  }, [data, selected])

  if (fetching) return null
  if (error) return `😱 ${error.message}`

  function Row({ index, style }) {
    let procedure = {
      ...procedures[index],
    }
    return (
      <DataProvider
        key={procedure.id}
        context="procedure"
        value={{ procedure }}
      >
        <View
          {...style}
          onClick={() => onClick(procedure)}
          isSelected={procedure.isSelected}
        />
      </DataProvider>
    )
  }

  function onClick(procedure) {
    if (
      !procedure.code ||
      procedure.code.length < 4 ||
      !procedure.patient_fee_value ||
      procedure.is_patient_fee_percentage
    ) {
      return
    }

    let selectedProcedures = selected.procedures ? [...selected.procedures] : []
    if (procedure.isSelected) {
      selectedProcedures = selectedProcedures.filter(p => p.id !== procedure.id)
    } else {
      selectedProcedures.push({ id: procedure.id, code: procedure.code })
    }
    select({ procedures: selectedProcedures })
  }

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          height={height}
          itemCount={procedures.length}
          itemSize={ROW_HEIGHT}
          width={width}
        >
          {Row}
        </List>
      )}
    </AutoSizer>
  )
}
