import { DataProvider } from 'Data/ViewsData.js';
import { useQuery } from 'Data/Api.js';
import { useSelected } from 'Data/Selected.js';
import gql from 'graphql-tag';
import React from 'react';
import View from './view.js';

let query = gql`
  query get_company($id: uuid!) {
    company: companies_by_pk(id: $id) {
      id
      active
      financial_email
      hr_email
      legal_name
      federal_tax_id_number
      phone_number
      primary_industry_sic_code
      national_provider_id
      business_type
      effective_date
      expiry_date
      company_agreements {
        id
        type
        has_accepted_agreement
      }
      physical_address {
        id
        street
        city
        state
        zip
      }
      broker {
        id
        legal_name
      }
      utilization_report_user_id
      utilization_report_user {
        id
        profile {
          id
          full_name
        }
      }
      company_doing_business_as {
        id
        name
        provider_locations_aggregate {
          aggregate {
            count
          }
        }
      }
      member_group_contributions(order_by: { plan: { name: asc } }) {
        contribution
        company_id
        plan_id
        plan {
          id
          name
        }
      }
    }
  }
`;

export default function Logic() {
  let [selected] = useSelected();
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: { id: selected.companyId },
  });

  if (fetching) return null;
  if (error) return `😱 ${error.message}`;

  return (
    <DataProvider context="company" value={data}>
      <View />
    </DataProvider>
  );
}
