import { useData } from 'Data/ViewsData.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  let dataProfessional = useData({
    context: 'professional',
    path: 'professional.useExistingUser',
  });
  return <View isExistingUser={dataProfessional.value} />;
}
