import { VariableSizeList as List } from 'react-window'
import View from './view.js'
import React from 'react'
import useIsMedia from 'Logic/useIsMedia.js'

let ROW_HEIGHT = 44
let LINE_HEIGHT = 16
let LINE_HEIGHT_MOBILE = 22
let CHARACTERS_PER_LINE = 80
let CHARACTERS_PER_LINE_MOBILE = 32

export default function Logic(props) {
  let isMedia = useIsMedia()

  function Row({ index, style }) {
    let item = props.from[index]
    let isSelected = props.isSelected(item)

    return (
      <View
        {...props}
        {...style}
        key={item.id}
        isSelected={isSelected}
        text={item[props.field]}
        banner={item.banner}
        onClick={() => props.onClick(item.id)}
      />
    )
  }

  let lineHeight = isMedia.mobile ? LINE_HEIGHT_MOBILE : LINE_HEIGHT
  let charactersPerLine = isMedia.mobile
    ? CHARACTERS_PER_LINE_MOBILE
    : CHARACTERS_PER_LINE

  function getItemSize(index) {
    let value = `${props.from[index][props.field]}`
    let rows = Math.ceil(value.length / charactersPerLine) - 1
    return ROW_HEIGHT + rows * lineHeight
  }

  if (props.isMultiChoice) {
    return props.from.map((item, index) => {
      return Row({
        index,
        style: {
          position: 'relative',
          height: getItemSize(index),
        },
      })
    })
  } else {
    return (
      <List
        key={isMedia.mobile}
        height={props.height}
        itemCount={props.from.length}
        itemSize={getItemSize}
        width={props.width}
      >
        {Row}
      </List>
    )
  }
}
