import { useQuery } from 'Data/Api.js'
import { useData } from 'Data/ViewsData.js'
import gql from 'graphql-tag'
import React from 'react'
import View from './view.js'

let query = gql`
  query admin_get_stripe_portal($return_url: String!, $user_id: uuid!) {
    admin_get_stripe_portal(return_url: $return_url, user_id: $user_id) {
      url
    }
  }
`

export default function Logic() {
  let dataUser = useData({ context: 'member', path: 'member.user' })

  let [{ data, fetching, error }] = useQuery({
    query,
    variables: {
      return_url: document.location.href,
      user_id: dataUser.value.id,
    },
  })

  return (
    <View
      goTo={data?.admin_get_stripe_portal.url}
      isDisabledManual={fetching || error}
    />
  )
}
