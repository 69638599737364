import { useActAsSupport } from 'Logic/ActAsSupport.js';
import { useSelected } from 'Data/Selected.js';
import View from './view.js';
import React from 'react';
import useFilter from 'useFilter.js';
import useHasSignedAgreementForSelectedCompany from 'Data/hasSignedAgreementForSelectedCompany.js';
import useIsAdmin from 'Data/isAdmin.js';

export default function Logic() {
  let [selected] = useSelected();
  let [isActingAsSupport] = useActAsSupport();
  let isAdmin = useIsAdmin();
  let hasSignedAgreementForSelectedCompany = useHasSignedAgreementForSelectedCompany();
  let filter = useFilter();
  let hasSelectedOne = selected.companies_ids.length === 1;
  let hasToAcceptAndSignAgreement =
    !isActingAsSupport &&
    hasSelectedOne &&
    !hasSignedAgreementForSelectedCompany;

  return (
    <View
      {...filter}
      isAdmin={isAdmin || isActingAsSupport}
      hasToAcceptAndSignAgreement={hasToAcceptAndSignAgreement}
      canInteract={!hasToAcceptAndSignAgreement}
    />
  );
}
