import { useAuth } from 'Data/Auth.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import View from './view.js';
import React, { useLayoutEffect } from 'react';

export default function Logic() {
  let auth = useAuth();
  let setFlowTo = useSetFlowTo();

  useLayoutEffect(() => {
    setFlowTo(
      `/App/MyAccount/Content/Profile/Content/Admins/Item/Content/Show/EditAction/${
        auth.permissions.isAdmin ? 'Content' : 'No'
      }`
    );
  }, [auth.permissions.isAdmin]); // eslint-disable-line
  // ignore setFlowTo

  return <View />;
}
