import { useNotifications } from 'utils/useNotifications.js';
import { OK, ERROR } from 'Data/constants.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  let [notifications] = useNotifications();
  return notifications.map((n) => (
    <View
      key={n.id}
      isError={n.status === ERROR}
      isOk={n.status === OK}
      message={n.message}
    />
  ));
}
