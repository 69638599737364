import { DataProvider, useData } from 'Data/ViewsData.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  let item = useData({
    context: 'utilization_report_import',
    path: 'utilization_report_import.items',
  });

  return item.value.map((eitem, index) => (
    <DataProvider
      context="utilization_report_import_item"
      key={index}
      value={{
        utilization_report_import_item: eitem,
      }}
    >
      <View isValid={eitem.is_valid} />
    </DataProvider>
  ));
}
