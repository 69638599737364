import { useData } from 'Data/ViewsData.js';
import { useQuery } from 'Data/Api.js';
import View from './view.js';
import React from 'react';
import gql from 'graphql-tag';

let query = gql`
  query get_company_doing_business_as($id: uuid!) {
    company_doing_business_as(
      where: { company: { provider_locations: { id: { _eq: $id } } } }
    ) {
      id
      name
    }
  }
`;

export default function Logic() {
  let dataProviderLocation = useData({
    context: 'provider_location',
    path: 'provider_location.id',
  });
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: {
      id: dataProviderLocation.value,
    },
  });

  if (fetching) return null;
  if (error) return `😱 ${error.message}`;

  return <View from={data.company_doing_business_as} />;
}
