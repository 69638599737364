import { DataProvider } from 'Data/ViewsData.js';
import { decode } from 'Logic/base64.js';
import { notifyError, useNotifications } from 'utils/useNotifications.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import View from './view.js';
import React, { useLayoutEffect } from 'react';
import qs from 'querystringify';

let ITEM = {
  auth: {
    email: '',
    password: '',
    user: null,
  },
};

export default function Logic() {
  let [, notify] = useNotifications();
  let setFlowTo = useSetFlowTo();

  useLayoutEffect(() => {
    let { invite } = qs.parse(window.location.search);
    if (invite) {
      try {
        JSON.parse(decode(invite));
        setFlowTo('/App/Auth/SignUp');
      } catch (error) {
        notify(notifyError(`Please, check your invite code and try again.`));
      }
    }
  }, []); // eslint-disable-line
  // ignore notify and setFlowTo

  return (
    <DataProvider context="auth" value={ITEM}>
      <View />
    </DataProvider>
  );
}
