import { DataProvider } from 'Data/ViewsData.js';
import { useSelected } from 'Data/Selected.js';
import { useQuery } from 'Data/Api.js';
import gql from 'graphql-tag';
import Info from 'DesignSystem/Info/view.js';
import React from 'react';
import View from './view.js';

let query = gql`
  query getProfessionalsAtLocation($id: uuid!) {
    professionals(
      where: { professional_locations: { provider_location_id: { _eq: $id } } }
    ) {
      id
      user {
        id
        email
        profile {
          id
          first_name
          last_name
        }
      }
      license {
        id
        name
      }
    }
  }
`;

export default function Logic() {
  let [selected] = useSelected();
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: { id: selected.locationId },
  });

  if (fetching) return null;
  if (error) return `😱 ${error.message}`;

  // TODO move as stories
  // ListItems/Empty
  // ListItems/Item
  if (data.professionals.length === 0) {
    return <Info text="None" />;
  }

  return data.professionals.map((item) => (
    <DataProvider
      context="professional"
      key={item.id}
      value={{ professional: item }}
    >
      <View />
    </DataProvider>
  ));
}
