import { ADD } from 'Data/constants.js';
import { DataProvider } from 'Data/ViewsData.js';
import { isInvalid, parseGraphqlError } from 'utils/graphQlHelpers.js';
import { useAuth } from 'Data/Auth.js';
import { useMutation, useQuery } from 'Data/Api.js';
import { useSelected } from 'Data/Selected.js';
import {
  useNotifications,
  notifyError,
  notifySuccess,
} from 'utils/useNotifications.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import gql from 'graphql-tag';
import React, { useMemo } from 'react';
import View from './view.js';
import Storage from '@aws-amplify/storage';

let query = gql`
  query getUserAndCompanyData($user_id: uuid!, $company_id: uuid!) {
    user: users_by_pk(id: $user_id) {
      id
      profile {
        id
        first_name
        last_name
        title
        signature
      }
    }
    company: companies_by_pk(id: $company_id) {
      id
      business_type
      effective_date
      legal_name
      company_agreements {
        id
        type
        has_accepted_agreement
      }
      physical_address {
        id
        street
        city
        state
        zip
      }
    }
  }
`;

let MUTATION_UPDATE_PROFILE = gql`
  mutation update_profiles(
    $profile_id: uuid
    $signature: String
    $title: String
  ) {
    update_profiles(
      where: { id: { _eq: $profile_id } }
      _set: { signature: $signature, title: $title }
    ) {
      returning {
        id
        signature
        title
      }
    }
  }
`;

let MUTATION_UPDATE_COMPANY = gql`
  mutation update_company_agreement(
    $company_agreement_id: uuid
    $user_id: uuid
  ) {
    update_company_agreements(
      where: { id: { _eq: $company_agreement_id } }
      _set: {
        has_accepted_agreement: true
        accepted_agreement_by_user_id: $user_id
        accepted_agreement_on: "now()"
      }
    ) {
      returning {
        id
        has_accepted_agreement
        accepted_agreement_by_user_id
        accepted_agreement_on
        # not ideal but it helps the cache
        company {
          id
          company_agreements {
            id
            type
            has_accepted_agreement
          }
        }
      }
    }
  }
`;

export default function Logic(props) {
  let [, notify] = useNotifications();
  let [selected] = useSelected();
  let auth = useAuth();
  let setFlowTo = useSetFlowTo();
  let company_id = useMemo(
    () => props.companyId || selected.companyId || null,
    [] // eslint-disable-line
  );
  // run once

  let [{ fetching, error, data }] = useQuery({
    query,
    variables: { user_id: auth.data.userId, company_id },
  });
  let [, executeMutationUpdateProfile] = useMutation(MUTATION_UPDATE_PROFILE);
  let [, executeMutationUpdateCompany] = useMutation(MUTATION_UPDATE_COMPANY);

  let signatureValue = useMemo(() => ({ signature: data }), [data]);

  if (fetching) return null;
  if (error) return `😱 ${error.message}`;

  return (
    <DataProvider
      context="signature"
      onSubmit={onSubmit}
      value={signatureValue}
    >
      <View />
    </DataProvider>
  );

  async function onSubmit(next) {
    if (isInvalid(ADD.ACCEPT_AGREEMENT, next.signature, notify)) return true;

    try {
      // upload file
      let res = await Storage.put(
        `signature.${next.signature.user.profile.signature.ext}`,
        next.signature.user.profile.signature.blob,
        {
          level: 'private',
          contentType: next.signature.user.profile.signature.type,
        }
      );
      // get full S3 URL
      let rawUrl = await Storage.get(res.key, { level: 'private' });
      let { origin, pathname } = new URL(rawUrl);

      // first update the user so the signature is stored
      let mutationResponseUpdateProfile = await executeMutationUpdateProfile({
        profile_id: next.signature.user.profile.id,
        signature: `${origin}${pathname}`,
        title: next.signature.user.profile.title,
      });
      if (mutationResponseUpdateProfile.error) {
        return notify(
          notifyError(parseGraphqlError(mutationResponseUpdateProfile.error))
        );
      }

      let agreementsLeftToAccept = data.company.company_agreements.filter(
        (item) => !item.has_accepted_agreement
      );

      // then update the company so the trigger runs afterwards
      let mutationResponseUpdateCompany = await Promise.all(
        agreementsLeftToAccept.map((item) =>
          executeMutationUpdateCompany({
            company_agreement_id: item.id,
            user_id: auth.data.userId,
          })
        )
      );

      if (mutationResponseUpdateCompany.some((item) => item.error)) {
        mutationResponseUpdateCompany
          .filter((item) => item.error)
          .forEach((item) =>
            notify(notifyError(parseGraphqlError(item.error).message))
          );
      } else {
        await auth.refreshToken();

        notify(
          notifySuccess(
            `You accepted the Terms and Conditions for ${next.signature.company.legal_name}`
          )
        );
        setFlowTo(
          '/App/MyAccount/Content/Profile/Content/MyCompanies/AddItem/Content/Confirmation/Content'
        );
      }
    } catch (error) {
      notify(notifyError(error.message));
    }
  }
}
