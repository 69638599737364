import addMonths from 'date-fns/addMonths'
import subMonths from 'date-fns/subMonths'
import getDate from 'date-fns/getDate'
import formatDate from 'date-fns/format'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import isValid from 'date-fns/isValid'
import parseISO from 'date-fns/parseISO'
import startOfMonth from 'date-fns/startOfMonth'
import startOfToday from 'date-fns/startOfToday'
import range from 'lodash/range'

let DAY_OF_MONTH_THRESHOLD_TO_TAKE_CURRENT_MONTH_AS_VALID_EFFECTIVE_DATE = 20
let NUMBER_OF_OPTIONS = 6
let NUMBER_OF_OPTIONS_FOR_SUPPORT_GOING_BACK = 12

export default function getValidEffectiveDates(
  start = null,
  end = null,
  isActingAsSupport = false
) {
  let today = startOfToday()
  let dayOfMonth = getDate(today)
  let firstEffectiveDate = startOfMonth(today)
  if (
    dayOfMonth >
    DAY_OF_MONTH_THRESHOLD_TO_TAKE_CURRENT_MONTH_AS_VALID_EFFECTIVE_DATE
  ) {
    firstEffectiveDate = addMonths(firstEffectiveDate, 1)
  }

  let startDate = parseISO(start)
  if (isValid(startDate) && isAfter(startDate, firstEffectiveDate)) {
    firstEffectiveDate = startDate
  }

  let endDate = parseISO(end)
  let isBeforeEndDate = (date) =>
    isValid(endDate) ? isBefore(date, endDate) : true

  let dates = range(NUMBER_OF_OPTIONS)
    .map((index) => addMonths(firstEffectiveDate, index))
    .filter(isBeforeEndDate)
    .map(mapDate)

  if (isActingAsSupport) {
    dates = [
      ...range(NUMBER_OF_OPTIONS_FOR_SUPPORT_GOING_BACK)
        .map((index) =>
          subMonths(
            firstEffectiveDate,
            NUMBER_OF_OPTIONS_FOR_SUPPORT_GOING_BACK - index
          )
        )
        .map(mapDate),
      ...dates,
    ]
  }

  // Josh wanted it this way
  dates.reverse()

  return dates
}

function mapDate(date) {
  return {
    id: formatDate(date, 'yyyy-MM-dd'),
    text: formatDate(date, 'MM/dd/yyyy'),
  }
}
