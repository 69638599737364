import { useActAsSupport } from 'Logic/ActAsSupport.js'
import { useSelected } from 'Data/Selected.js'
import View from './view.js'
import React from 'react'
import useFilter from 'useFilter.js'
import useHasSignedAgreementForSelectedCompany from 'Data/hasSignedAgreementForSelectedCompany.js'
import useIsAdmin from 'Data/isAdmin.js'
import { DataProvider } from 'Data/ViewsData.js'

let value = {
  filtered_employees: [],
}

export default function Logic() {
  let [selected] = useSelected()
  let [isActingAsSupport] = useActAsSupport()
  let hasSignedAgreementForSelectedCompany =
    useHasSignedAgreementForSelectedCompany()
  let filter = useFilter()
  let isAdmin = useIsAdmin()
  let hasSelectedOne = selected.companies_ids.length === 1
  let hasToAcceptAndSignAgreement =
    !isActingAsSupport &&
    hasSelectedOne &&
    !hasSignedAgreementForSelectedCompany

  return (
    <DataProvider context="filtered_employees" value={value}>
      <View
        {...filter}
        isAdmin={isAdmin || isActingAsSupport}
        hasToAcceptAndSignAgreement={hasToAcceptAndSignAgreement}
        canInteract={!hasToAcceptAndSignAgreement}
      />
    </DataProvider>
  )
}
