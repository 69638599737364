import { useData } from 'Data/ViewsData.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  let data = useData({
    path: 'user',
    context: 'user',
  });

  return (
    <View
      isDisabled={
        !data.value.is_deleting_admin &&
        !data.value.is_deleting_member &&
        !data.value.is_deleting_professional
      }
    />
  );
}
