import { address as formatAddress } from 'Data/format.js';
import { ADD } from 'Data/constants.js';
import { DataProvider } from 'Data/ViewsData.js';
import { isInvalid, parseGraphqlError } from 'utils/graphQlHelpers.js';
import { useSelected } from 'Data/Selected.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import {
  useNotifications,
  notifyError,
  notifySuccess,
} from 'utils/useNotifications.js';
import { useMutation } from 'Data/Api.js';
import View from './view.js';
import gql from 'graphql-tag';
import React, { useMemo } from 'react';

let MUTATION = gql`
  mutation insert_provider_location(
    $provider_location: provider_locations_insert_input!
  ) {
    insert_provider_locations(objects: [$provider_location]) {
      returning {
        id
      }
    }
  }
`;

export default function Logic() {
  let story = '/App/MyAccount/Content/Profile/Content/MyLocations/Item';
  let setFlowTo = useSetFlowTo();
  let [selected, select] = useSelected();
  let [, notify] = useNotifications();
  let [, executeMutation] = useMutation(MUTATION);

  let providerLocationValue = useMemo(
    () => ({
      provider_location: {
        company_id: selected.companies_ids[0] || null,
        company_doing_business_as_id: null,
        phone_number: null,
        website: '',
        address: {
          city: '',
          state: '',
          zip: '',
          street: '',
        },
      },
    }),
    [] // eslint-disable-line
  );

  return (
    <DataProvider
      context="provider_location"
      onSubmit={onSubmit}
      value={providerLocationValue}
    >
      <View />
    </DataProvider>
  );

  async function onSubmit(next) {
    if (isInvalid(ADD.LOCATION, next.provider_location, notify)) return true;

    let mutationResponse = await executeMutation({
      provider_location: {
        company_doing_business_as_id:
          next.provider_location.company_doing_business_as_id,
        company_id: next.provider_location.company_id,
        phone_number: next.provider_location.phone_number,
        website: next.provider_location.website,
        address: {
          data: {
            city: next.provider_location.address.city,
            state: next.provider_location.address.state,
            zip: next.provider_location.address.zip,
            street: next.provider_location.address.street,
          },
        },
      },
    });

    if (mutationResponse.error) {
      notify(notifyError(parseGraphqlError(mutationResponse.error).message));
    } else {
      select({ nothing: Date.now() });
      notify(
        notifySuccess(
          `${formatAddress(
            next.provider_location.address
          )} was added successfully!`
        )
      );
      setFlowTo(`${story}/No`);
    }
  }
}
