import { _and, _eq, _or, _ilike, _in, _wilike, _is_null } from 'Data/hasura.js'
import { FixedSizeList as List } from 'react-window'
import { DataProvider, useData } from 'Data/ViewsData.js'
import { useSelected } from 'Data/Selected.js'
import { useSetFlowTo } from 'Logic/ViewsFlow.js'
import { useQuery } from 'Data/Api.js'
import isValidDate from 'date-fns/isValid'
import parseDate from 'date-fns/parse'
import AutoSizer from 'react-virtualized-auto-sizer'
import gql from 'graphql-tag'
import Info from 'DesignSystem/Info/view.js'
import View from './view.js'
import LoadingBar from 'DesignSystem/LoadingBar/logic.js'
import React, { useEffect } from 'react'

let query = gql`
  query list_members($limit: Int, $where: members_bool_exp) {
    members(
      limit: $limit
      where: $where
      order_by: [
        {
          created_at: desc
          status: asc
          profile: { last_name: asc, first_name: asc }
        }
      ]
    ) {
      id
      status
      effective_date
      created_at
      termination_date
      stripe_subscription_status
      stripe_payment_metadata
      stripe_latest_invoice_url
      profile {
        id
        first_name
        last_name
      }
    }
  }
`

function where({ filter: rfilter, individualsStatuses, nothing }) {
  let filter = rfilter.trim()
  let datefilter = null
  if (isValidDate(parseDate(filter, 'MM/dd/yyyy', new Date()))) {
    datefilter = _eq('effective_date', filter)
  }

  return _and(
    _eq('is_individual', true),
    _is_null('main_member_id', true),
    _in('status', individualsStatuses),
    filter &&
      _or(
        _ilike('user.email', filter),
        _eq('policy_id', filter),
        datefilter,
        _eq('profile.social_security_number', filter),
        _wilike('profile.first_name', filter),
        _wilike('profile.last_name', filter)
      )
  )
}

// this number is a combination of the 70px of the height plus the 8px
// of the marginTop from the Card in ListItems.view
// until we find a better way to
let ROW_HEIGHT = 78

export default function Logic(props) {
  let filteredIndividuals = useData({
    viewPath: props.viewPath,
    path: 'filtered_individuals',
    context: 'filtered_individuals',
  })
  let [selected, select] = useSelected()
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: {
      limit: 10000,
      where: where({
        filter: props.filter,
        individualsStatuses: selected.individualsStatuses,
        nothing: selected.nothing,
      }),
    },
    pollInterval: 5000,
  })
  let setFlowTo = useSetFlowTo()

  useEffect(() => {
    if (!data) return

    filteredIndividuals.onChange({
      filtered_individuals: data.members.map(member => member.id),
    })
  }, [data]) // eslint-disable-line

  if (fetching)
    return (
      <LoadingBar
        alignSelf="flexStart"
        borderBottomLeftRadius={4}
        borderBottomRightRadius={4}
        borderTopLeftRadius={4}
        borderTopRightRadius={4}
        height={8}
        marginTop={20}
        width={200}
      />
    )

  if (error) return `😱 ${error.message}`

  // TODO move as stories
  // ListItems/Loading
  // ListItems/Empty
  // ListItems/Item
  if (data.members.length === 0) {
    return <Info marginLeft={10} text="No individual members" />
  }

  let sortedMembers = [
    ...data.members.filter(member => member.status === 'Created'),
    ...data.members.filter(member => member.status !== 'Created'),
  ]

  function Row({ index, style }) {
    let item = sortedMembers[index]
    return (
      <DataProvider context="member" key={item.id} value={{ member: item }}>
        <View
          {...style}
          onClick={() => {
            select({ memberId: item.id })
            setFlowTo(
              '/App/MyAccount/Content/Profile/Content/Individuals/Item/Content'
            )
          }}
        />
      </DataProvider>
    )
  }

  // don't use react-window and autosizer in short lists
  // a simple Array.map works fine there
  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          height={height}
          itemCount={sortedMembers.length}
          itemSize={ROW_HEIGHT}
          width={width}
        >
          {Row}
        </List>
      )}
    </AutoSizer>
  )
}
Logic.defaultProps = {
  filter: '',
}
