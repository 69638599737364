import View from './view.js';
import React from 'react';
import useSetFlowToBasedOnData from 'Data/useSetFlowToBasedOnData.js';

export default function Logic() {
  useSetFlowToBasedOnData({
    context: 'member',
    path: 'member.status',
    isContent: (value) => value === 'Active' || value === 'Inactive',
    story:
      '/App/MyAccount/Content/Profile/Content/Employees/Item/Content/Change/Status',
  });

  return <View />;
}
