export let INITIAL_DATA = 'INITIAL_DATA'
export let SET = 'SET'
export let REMOVE = 'REMOVE'
export let OK = 'OK'
export let ERROR = 'ERROR'
export let INVALID_FIELD_MESSAGE = 'Please answer this question'
export let DISABLED_FIELD_MESSAGE = '___Question_Disabled___'
export let QUESTION_SKIPPED = '___Question_Skipped___'
export let PHONE_TYPES = ['cell', 'landline']

export let JOINING_AS = {
  PROVIDER: 'PROVIDER',
  MEMBER_GROUP: 'MEMBER_GROUP',
  BROKER: 'BROKER',
}

export let VERIFY_MEMBERSHIP = 'VERIFY_MEMBERSHIP'

export let JOINING_AS_REPEATER = {
  PROVIDER: 'a dental provider',
  MEMBER_GROUP: 'an employer',
  BROKER: 'an agent',
}

export let ADD = {
  COMPANY: 'add/COMPANY',
  LOCATION: 'add/LOCATION',
  PROFESSIONAL: 'add/PROFESSIONAL',
  MEMBER: 'add/MEMBER',
  MANY_MEMBERS: 'add/MANY_MEMBERS',
  ADMIN: 'add/ADMIN',
  ACCEPT_AGREEMENT: 'add/ACCEPT_AGREEMENT',
}

export let INVITE = {
  ADMIN_TO_SIGN: 'invite/ADMIN_TO_SIGN',
  MEMBER: 'invite/MEMBER',
}

export let AUTH = {
  CHANGE_PASSWORD: 'auth/CHANGE_PASSWORD',
  SIGNIN: 'auth/SIGNIN',
  SIGNIN_NEW_PASSWORD_REQUIRED: 'auth/SIGNIN_NEW_PASSWORD_REQUIRED',
  SIGNUP: 'auth/SIGNUP',
  SIGNUP_CONFIRM: 'auth/SIGNUP_CONFIRM',
  RESET_PASSWORD: 'auth/RESET_PASSWORD',
  RESET_PASSWORD_CONFIRM: 'auth/RESET_PASSWORD_CONFIRM',
  SET_NEW_PASSWORD: 'auth/SET_NEW_PASSWORD',
}

export let EDIT = {
  COMPANY: 'edit/COMPANY',
  LOCATION: 'edit/LOCATION',
  PROFESSIONAL: 'edit/PROFESSIONAL',
  MEMBER: 'edit/MEMBER',
  MEMBER_INDIVIDUAL: 'edit/MEMBER_INDIVIDUAL',
  ADMIN: 'edit/ADMIN',
  USER_PROFILE: 'edit/USER_PROFILE',
  UTILIZATION_REPORT: 'edit/UTILIZATION_REPORT',
}

export let ACTIVATE = {
  MEMBER: 'activate/MEMBER',
}

export let DELETE = {
  USER: 'delete/USER',
}

export let DEFAULT_ADDRESS = {
  street: '',
  city: '',
  state: '',
  zip: '',
}
export let COMPANY_TYPES = [
  { text: 'LLC - C Corp', id: 'LLC - C Corp' },
  { text: 'LLC - S Corp', id: 'LLC - S Corp' },
  { text: 'LLC - Partnership', id: 'LLC - Partnership' },
  {
    text: 'Individual/Sole proprietor/Single-member LLC',
    id: 'individual, sole proprietor, or single-member LLC',
  },
  { text: 'C Corporation', id: 'C Corporation' },
  { text: 'S Corporation', id: 'S Corporation' },
  { text: 'Partnership', id: 'Partnership' },
  { text: 'Trust/Estate', id: 'Trust/Estate' },
]

export let ROLES = [
  { text: 'Super-Admin', id: 'company-admin' },
  { text: 'Admin', id: 'company-user' },
]

export let RELATIONSHIPS = [
  { text: 'Spouse', id: 'Spouse' },
  { text: 'Child', id: 'Child' },
  { text: 'Other', id: 'Other' },
]

export let GENDERS = [
  { text: 'Male', id: 'Male' },
  { text: 'Female', id: 'Female' },
  { text: 'Prefer Not To Define', id: 'PreferNotToDefine' },
]

export let MARITAL_STATUSES = [
  {
    id: 'Single',
    text: 'Single',
  },
  {
    id: 'Married',
    text: 'Married',
  },
  {
    id: 'Separated',
    text: 'Separated',
  },
  {
    id: 'Divorced',
    text: 'Divorced',
  },
  {
    id: 'Widowed',
    text: 'Widowed',
  },
]

export let STATES_ONLY_AR = [
  { name: 'Arkansas', id: 'AR', text: 'AR • Arkansas' },
]

export let MEMBER_STATUSES = [
  {
    id: 'Created',
    text: 'Created',
  },
  {
    id: 'Invited',
    text: 'Invited',
  },
  {
    id: 'Enrolled',
    text: 'Enrolled',
  },
  {
    id: 'Declined',
    text: 'Declined',
  },
  {
    id: 'Active',
    text: 'Active',
  },
  {
    id: 'Inactive',
    text: 'Inactive',
  },
]

export let EDIT_MEMBER_STATUSES = [
  {
    id: 'Active',
    text: 'Active',
  },
  {
    id: 'Inactive',
    text: 'Inactive',
  },
]

export let MEMBER_STATUSES_MEMBERS_LIST_FILTER = [
  {
    id: 'Enrolled',
    text: 'Enrolled',
  },
  {
    id: 'Active',
    text: 'Active',
  },
  {
    id: 'Inactive',
    text: 'Inactive',
  },
  // {
  //   id: 'PastDue',
  //   text: 'Past Due',
  // },
  // {
  //   id: 'Unpaid',
  //   text: 'Unpaid',
  // },
]

export let INDIVIDUAL_STATUSES_FILTER = [
  {
    id: 'Created',
    text: 'Created',
  },
  {
    id: 'Active',
    text: 'Active',
  },
  {
    id: 'Inactive',
    text: 'Inactive',
  },
  {
    id: 'Enrolled',
    text: 'Enrolled',
  },
  // {
  //   id: 'PastDue',
  //   text: 'Past Due',
  // },
  // {
  //   id: 'Unpaid',
  //   text: 'Unpaid',
  // },
]

export let TERMINATION_REASONS = [
  {
    id: 'Employment Terminated',
    text: 'Employment Terminated',
  },
  {
    id: 'Non-Payment',
    text: 'Non-Payment',
  },
  {
    id: '30-Day Opt Out',
    text: '30-Day Opt Out',
  },
  {
    id: 'Non-Renewal',
    text: 'Non-Renewal',
  },
  {
    id: 'Eligible for New Dental Insurance',
    text: 'Eligible for New Dental Insurance',
  },
  {
    id: 'Other',
    text: 'Other',
  },
]

export let TERMINATION_REASONS_INDIVIDUAL = TERMINATION_REASONS.filter(
  item => item.id !== 'Employment Terminated'
)
