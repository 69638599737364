import { useAuth } from './Auth.js';
import { useSelected } from './Selected.js';

export default function useAdminCheck() {
  let auth = useAuth();
  let [selected] = useSelected();
  return auth.data.adminCompanies.some(company =>
    selected.companies_ids.includes(company.id)
  );
}
