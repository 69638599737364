import useFocusedManual from './useFocusedManual.js';

export default function useCapture(props) {
  let focusedManual = useFocusedManual();

  function onKeyUp(event) {
    if (props.onKeyUp) {
      props.onKeyUp(event);
    } else if (event.key === 'Enter' && typeof props.onSubmit === 'function') {
      props.onSubmit();
    }
  }

  function onChange(event) {
    props.onChange(
      props.type === 'number'
        ? props.isInteger
          ? parseInt(event.target.value, 10)
          : parseFloat(event.target.value, 10)
        : event.target.value
    );
  }

  return { ...focusedManual, onKeyUp, onChange };
}
