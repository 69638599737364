import { useActAsSupport } from 'Logic/ActAsSupport.js'
import { useData } from 'Data/ViewsData.js'
import View from './view.js'
import getValidEffectiveDates from 'Logic/getValidEffectiveDates.js'
import React from 'react'

export default function Logic() {
  let [isActingAsSupport] = useActAsSupport()
  let dataMember = useData({
    context: 'member',
    path: 'member.company',
  })

  return (
    <View
      from={getValidEffectiveDates(
        dataMember.value.effective_date,
        dataMember.value.expiry_date,
        isActingAsSupport
      )}
    />
  )
}
