import View from './view.js';
import React from 'react';
import { useData } from 'Data/ViewsData.js';
import format from 'number-format.js';
export default function Logic() {
  let item = useData({
    context: 'company',
    path: 'company.member_group_contributions[1].contribution.four_people',
  });
  let contribution = item.value;
  return (
    <View
      onFocus={(e) => e.target.select()}
      onBlur={(e) => {
        item.onChange(format('#,##0.00', contribution));
      }}
    />
  );
}
