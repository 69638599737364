import { DataProvider } from 'Data/ViewsData.js';
import { useSelected } from 'Data/Selected.js';
import { useQuery } from 'Data/Api.js';
import View from './view.js';
import gql from 'graphql-tag';
import React from 'react';

let query = gql`
  query get_companies($user_id: uuid!) {
    companies(where: { company_users: { user_id: { _eq: $user_id } } }) {
      id
      legal_name
    }
  }
`;

// TODO review this and the parent query
export default function Logic() {
  let [selected] = useSelected();
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: {
      user_id: selected.adminId,
    },
  });

  if (fetching) return null;
  if (error) return `😱 ${error.message}`;

  return data.companies.map((item) => (
    <DataProvider context="company" key={item.id} value={{ company: item }}>
      <View />
    </DataProvider>
  ));
}
