import { ADD } from 'Data/constants.js';
import { DataProvider } from 'Data/ViewsData.js';
import { isInvalid, parseGraphqlError } from 'utils/graphQlHelpers.js';
import { NEW_USER } from 'Data/useExistingUser.js';
import { useMutation } from 'Data/Api.js';
import {
  useNotifications,
  notifyError,
  notifySuccess,
} from 'utils/useNotifications.js';
import { useSelected } from 'Data/Selected.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import gql from 'graphql-tag';
import React, { useMemo } from 'react';
import View from './view.js';

let MUTATION_EXISTING_USER = gql`
  mutation insert_company_user($objects: [company_users_insert_input!]!) {
    insert_company_users(objects: $objects) {
      returning {
        user_id
      }
    }
  }
`;
let MUTATION_NEW_USER = gql`
  mutation insert_user($object: users_insert_input!) {
    insert_users(objects: [$object]) {
      returning {
        id
      }
    }
  }
`;

let ITEM = {
  role: null,
  user: NEW_USER,
  useExistingUser: false,
  companies_ids: [],
};

export default function Logic() {
  let setFlowTo = useSetFlowTo();
  let story = '/App/MyAccount/Content/Profile/Content/Admins';
  let [, notify] = useNotifications();
  let [selected, select] = useSelected();
  let [, executeMutationExistingUser] = useMutation(MUTATION_EXISTING_USER);
  let [, executeMutationNewUser] = useMutation(MUTATION_NEW_USER);

  let value = useMemo(
    () => ({
      company_user: { ...ITEM, companies_ids: selected.companies_ids },
    }),
    [] // eslint-disable-line
  );

  return (
    <DataProvider context="company_user" onSubmit={onSubmit} value={value}>
      <View />
    </DataProvider>
  );

  async function onSubmit(next) {
    if (isInvalid(ADD.ADMIN, next.company_user, notify)) return true;

    let mutationResponse = null;
    if (next.company_user.useExistingUser) {
      mutationResponse = await executeMutationExistingUser({
        objects: next.company_user.companies_ids.map((company_id) => ({
          company_id,
          role: next.company_user.role,
          user_id: next.company_user.user_id,
        })),
      });
    } else {
      mutationResponse = await executeMutationNewUser({
        object: {
          email: next.company_user.user.email,
          phone_number: next.company_user.user.phone_number,
          profile: {
            data: {
              first_name: next.company_user.user.profile.first_name,
              last_name: next.company_user.user.profile.last_name,
            },
          },
          company_users: {
            data: next.company_user.companies_ids.map((company_id) => ({
              company_id,
              role: next.company_user.role,
            })),
          },
        },
      });
    }

    if (mutationResponse.error) {
      notify(notifyError(parseGraphqlError(mutationResponse.error).message));
    } else {
      select({ nothing: Date.now() });
      let name = next.company_user.useExistingUser
        ? `${next.company_user.existingUser.profile.first_name} ${next.company_user.existingUser.profile.last_name}`
        : `${next.company_user.user.profile.first_name} ${next.company_user.user.profile.last_name}`;
      notify(notifySuccess(`${name} was successfully added!`));
      setFlowTo(`${story}/AddItem/No`);
    }
  }
}
