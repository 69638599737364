// import { _and, _in, _or, _wilike } from 'Data/hasura.js';
import { FixedSizeList as List } from 'react-window';
import { DataProvider } from 'Data/ViewsData.js';
import { useSelected } from 'Data/Selected.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import { useQuery } from 'Data/Api.js';
import AutoSizer from 'react-virtualized-auto-sizer';
import gql from 'graphql-tag';
import Info from 'DesignSystem/Info/view.js';
import View from './view.js';
import LoadingBar from 'DesignSystem/LoadingBar/logic.js';
import React from 'react';

let query = gql`
  query list_notifications($limit: Int, $where: notifications_bool_exp) {
    notifications(
      limit: $limit
      where: $where
      order_by: { updated_at: desc }
    ) {
      id
      notification
      status
      updated_at
    }
  }
`;

function where({ filter: rfilter, companies_ids }) {
  return null;
  // let filter = rfilter.trim();

  // return _and(
  //   _in('provider_location.company_id', companies_ids),
  //   _in('status', ['Active', 'Enrolled']),
  //   filter &&
  //     _or(
  //       /*_wilike('policy_id', filter), _wilike('status', filter), */ {
  //         profile: _or(
  //           _wilike('first_name', filter),
  //           _wilike('last_name', filter)
  //         ),
  //       }
  //     )
  // );
}

// this number is a combination of the 70px of the height plus the 8px
// of the marginTop from the Card in ListItems.view
// until we find a better way to
let ROW_HEIGHT = 78;

export default function Logic(props) {
  let [selected, select] = useSelected();
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: {
      limit: 10000,
      where: where({
        filter: props.filter,
        companies_ids: selected.companies_ids,
      }),
    },
  });
  let setFlowTo = useSetFlowTo();

  if (fetching)
    return (
      <LoadingBar
        alignSelf="flexStart"
        borderBottomLeftRadius={4}
        borderBottomRightRadius={4}
        borderTopLeftRadius={4}
        borderTopRightRadius={4}
        height={8}
        marginTop={20}
        width={200}
      />
    );

  if (error) return `😱 ${error.message}`;

  function Row({ index, style }) {
    let item = data.notifications[index];
    return (
      <DataProvider
        key={item.id}
        context="notification"
        value={{ notification: item }}
      >
        <View
          {...style}
          onClick={() => {
            select({ notificationId: item.id });
            setFlowTo('/App/MyAccount/Content/Comms/Item/Content');
          }}
        />
      </DataProvider>
    );
  }

  // TODO move as stories
  // ListItems/Empty
  // ListItems/Item
  if (data.notifications.length === 0) {
    return <Info text="No items" />;
  }

  // don't use react-window and autosizer in short lists
  // a simple Array.map works fine there
  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          height={height}
          itemCount={data.notifications.length}
          itemSize={ROW_HEIGHT}
          width={width}
        >
          {Row}
        </List>
      )}
    </AutoSizer>
  );
}
Logic.defaultProps = {
  filter: '',
};
