import { DataProvider } from 'Data/ViewsData.js';
import { EDIT } from 'Data/constants.js';
import { isInvalid, parseGraphqlError } from 'utils/graphQlHelpers.js';
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'utils/useNotifications.js';
import { useAuth } from 'Data/Auth.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import { useQuery, useMutation } from 'Data/Api.js';
import gql from 'graphql-tag';
import React from 'react';
import View from './view.js';

let query = gql`
  query get_user($id: uuid!) {
    user: users_by_pk(id: $id) {
      id
      email
      phone_number
      profile {
        id
        first_name
        last_name
      }
    }
  }
`;

let MUTATION = gql`
  mutation updateUser(
    $user_id: uuid
    $user: users_set_input
    $profile_id: uuid
    $profile: profiles_set_input
  ) {
    update_users(where: { id: { _eq: $user_id } }, _set: $user) {
      returning {
        id
        email
        phone_number
      }
    }
    update_profiles(where: { id: { _eq: $profile_id } }, _set: $profile) {
      returning {
        id
        first_name
        last_name
      }
    }
  }
`;

export default function Logic() {
  let story = '/App/MyAccount/Content/Profile/Content/UserProfile/Item/Content';
  let auth = useAuth();
  let setFlowTo = useSetFlowTo();
  let [, notify] = useNotifications();
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: {
      id: auth.data.userId,
    },
  });
  let [, executeMutation] = useMutation(MUTATION);

  if (fetching) return null;
  if (error) return `😱 ${error.message}`;

  return (
    <DataProvider context="user" onSubmit={onSubmit} value={data}>
      <View />
    </DataProvider>
  );

  async function onSubmit(next) {
    if (isInvalid(EDIT.USER_PROFILE, next, notify)) return true;

    let mutationResponse = await executeMutation({
      user_id: auth.data.userId,
      user: {
        email: next.user.email,
        phone_number: next.user.phone_number,
      },
      profile_id: data.user.profile.id,
      profile: {
        first_name: next.user.profile.first_name,
        last_name: next.user.profile.last_name,
      },
    });

    if (mutationResponse.error) {
      notify(notifyError(parseGraphqlError(mutationResponse.error).message));
    } else {
      notify(notifySuccess(`Your profile was successfully updated!`));
      setFlowTo(`${story}/Show`);
    }
  }
}
