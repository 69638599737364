import { ACTIVATE } from 'Data/constants.js';
import { DataProvider } from 'Data/ViewsData.js';
import { isInvalid, parseGraphqlError } from 'utils/graphQlHelpers.js';
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'utils/useNotifications.js';
import { useSelected } from 'Data/Selected.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import { useQuery, useMutation } from 'Data/Api.js';
import View from './view.js';
import gql from 'graphql-tag';
import React from 'react';

let query = gql`
  query get_member($id: uuid!) {
    member: members_by_pk(id: $id) {
      id
      original_effective_date: effective_date
      company {
        effective_date
        expiry_date
      }
      termination_date
      status
      related_members {
        id
        status
        profile {
          id
          first_name
        }
      }
      user {
        id
        email
      }
      profile {
        id
        first_name
        last_name
      }
    }
  }
`;

let MUTATION = gql`
  mutation update_members($id: uuid!, $effective_date: date) {
    update_members(
      where: {
        _or: [{ id: { _eq: $id } }, { main_member: { id: { _eq: $id } } }]
      }
      _set: {
        effective_date: $effective_date,
        termination_date: null,
        termination_reason: null
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export default function Logic() {
  let setFlowTo = useSetFlowTo();
  let [, notify] = useNotifications();
  let [selected, select] = useSelected();
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: { id: selected.memberId },
  });
  let [, executeMutation] = useMutation(MUTATION);

  if (fetching) return null;
  if (error) return `😱 ${error.message}`;

  return (
    <DataProvider context="member" onSubmit={onSubmit} value={data}>
      <View />
    </DataProvider>
  );

  async function onSubmit(next) {
    if (isInvalid(ACTIVATE.MEMBER, next, notify)) return true;

    let mutationResponse = await executeMutation({
      id: data.member.id,
      effective_date: next.member.effective_date,
    });

    if (mutationResponse.error) {
      notify(notifyError(parseGraphqlError(mutationResponse.error).message));
    } else {
      notify(
        notifySuccess(
          `${next.member.profile.first_name} ${next.member.profile.last_name}'s benefit activated successfully`
        )
      );
      select({ nothing: `${Date.now()}` });
      setFlowTo(
        '/App/MyAccount/Content/Profile/Content/Individuals/Item/Content/Show'
      );
    }
  }
}
