import { useData } from 'Data/ViewsData.js';
import { useExistingUser } from 'Data/useExistingUser.js';
import { useQuery } from 'Data/Api.js';
import View from './view.js';
import gql from 'graphql-tag';
import React from 'react';

let query = gql`
  query users($companies_ids: [uuid!]!) {
    members: users(
      where: {
        member: { company_id: { _in: $companies_ids } }
        _not: { company_users: {} }
      }
      order_by: { profile: { last_name: asc, first_name: asc } }
    ) {
      id
      email
      profile {
        id
        first_name
        last_name
      }
    }
    professionals: users(
      where: {
        professional: {
          professional_locations: {
            provider_location: { company_id: { _in: $companies_ids } }
          }
        }
        _not: { company_users: {} }
      }
      order_by: { profile: { last_name: asc, first_name: asc } }
    ) {
      id
      email
      profile {
        id
        first_name
        last_name
      }
    }
  }
`;

export default function Logic() {
  let dataCompanyUser = useData({
    context: 'company_user',
    path: 'company_user.companies_ids',
  });
  let [{ data }] = useQuery({
    query,
    variables: { companies_ids: dataCompanyUser.value },
  });
  let existingUser = useExistingUser({
    context: 'company_user',
    path: 'company_user',
    usersData: data,
  });
  return <View {...existingUser} />;
}
