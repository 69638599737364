import { useData } from 'Data/ViewsData.js'
import { useSetFlowTo } from 'Logic/ViewsFlow.js'
import View from './view.js'
import React, { useLayoutEffect } from 'react'

export default function Logic() {
  let item = useData({
    context: 'member',
    path: 'member.professional.id',
  })
  let setFlowTo = useSetFlowTo()

  useLayoutEffect(() => {
    setFlowTo(
      `/App/MyAccount/Content/Members/Item/Content/Show/Professional/${
        item.value ? 'Content' : 'No'
      }`
    )
  }, [item.value]) // eslint-disable-line
  // ignore setFlowTo

  return <View />
}
