import { useActAsSupport } from 'Logic/ActAsSupport.js'
import { useAuth } from 'Data/Auth.js'
import { DataProvider } from 'Data/ViewsData.js'
import View from './view.js'
import React from 'react'
import useFilter from 'useFilter.js'
import useIsAdmin from 'Data/isAdmin.js'

let value = {
  filtered_companies: [],
}

export default function Logic() {
  let filter = useFilter()
  let auth = useAuth()
  let isAdmin = useIsAdmin()
  let [isActingAsSupport] = useActAsSupport()

  return (
    <DataProvider context="filtered_companies" value={value}>
      <View
        {...filter}
        isAdmin={isAdmin || auth.permissions.isNewUser || isActingAsSupport}
      />
    </DataProvider>
  )
}
