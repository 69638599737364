// import { useSetFlowToBasedOnDataCapture } from 'Data/useSetFlowToBasedOnData.js';
import { useData } from 'Data/ViewsData.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  let item = useData({
    context: 'related_member',
    path: 'related_member.provider_location_id',
  });

  return (
    <View flowNo={item.value === null} flowContent={item.value !== null} />
  );

  //   useSetFlowToBasedOnDataCapture({
  //     path: 'provider_location_id',
  //     story:
  //       '/App/MyAccount/Content/Profile/Content/Employees/Item/Content/Change/Dependents/Professionals',
  //   });

  //   return <View />;
}
