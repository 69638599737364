import { downloadCsv } from 'utils/csv.js'
import { useQuery } from 'Data/Api.js'
import { useSetFlowTo } from 'Logic/ViewsFlow.js'
import { phoneNumberUS as formatPhoneNumber, memberType } from 'Data/format.js'
import { dateISOIn } from 'utils/dateHelpers.js'
import gql from 'graphql-tag'
import View from './view.js'
import React, { useEffect } from 'react'

let query = gql`
  query list_members {
    members(order_by: { profile: { last_name: asc, first_name: asc } }) {
      id
      effective_date
      expiry_date
      is_individual
      policy_id
      status
      company {
        legal_name
      }
      plan {
        name
      }
      professional {
        user {
          profile {
            first_name
            last_name
          }
        }
      }
      provider_location {
        id
        company_doing_business_as {
          name
          company {
            legal_name
          }
        }
      }
      email
      phone_number
      profile {
        last_name
        first_name
        date_of_birth
        gender
        marital_status
        social_security_number
      }
      user {
        affiliate_referral {
          affiliate_id
        }
      }
      addresses {
        id
        street
        city
        state
        zip
      }
      relationship_to_main_member
      main_member {
        policy_id
        phone_number
        company_id
        is_individual
        profile {
          first_name
          last_name
        }
        user {
          affiliate_referral {
            affiliate_id
          }
        }
      }
      created_at
    }
  }
`

let fields = [
  'Id',
  'Member Id',
  'Type',
  'First Name',
  'Last Name',
  'Date of Birth',
  'Gender',
  'Marital Status',
  'Email',
  'Phone Number',
  'Social Security Number',
  'Address',
  'City',
  'State',
  'Zip',
  'Status',
  'Effective Date',
  'Expiry Date',
  'Plan Name',
  'Company Name',
  'Professional',
  'Location Id',
  'Location',
  'Location Company Name',
  'Primary employee member',
  'Relationship to employee',
  'Created',
  'Affiliate Id',
]

function processData({ members }) {
  let data = members.map(member => [
    member.id,
    member.policy_id,
    memberType(member),
    member.profile.first_name,
    member.profile.last_name,
    dateISOIn(member.profile.date_of_birth),
    member.profile.gender,
    member.profile.marital_status,
    member.email,
    formatPhoneNumber(member.phone_number),
    member.profile.social_security_number,
    member.addresses && member.addresses[0] && member.addresses[0].street,
    member.addresses && member.addresses[0] && member.addresses[0].city,
    member.addresses && member.addresses[0] && member.addresses[0].state,
    member.addresses && member.addresses[0] && member.addresses[0].zip,
    member.status,
    dateISOIn(member.effective_date),
    dateISOIn(member.expiry_date),
    member.plan && member.plan.name,
    member.company && member.company.legal_name,
    member.professional &&
      `${member.professional.user.profile.first_name} ${member.professional.user.profile.last_name}`,
    member.provider_location && member.provider_location.id,
    `${
      (member.provider_location &&
        member.provider_location.company_doing_business_as &&
        member.provider_location.company_doing_business_as.name) ||
      '-'
    }`,

    member.provider_location &&
      member.provider_location.company_doing_business_as &&
      member.provider_location.company_doing_business_as.company &&
      member.provider_location.company_doing_business_as.company.legal_name,
    member.main_member ? member.main_member.policy_id : member.policy_id,
    member.relationship_to_main_member,
    member.created_at,
    (member.main_member
      ? member.main_member.user.affiliate_referral?.affiliate_id
      : member.user.affiliate_referral?.affiliate_id) || null,
  ])
  downloadCsv({
    name: 'greyfinch-all-members-report.csv',
    fields,
    data,
  })
}

let context = {
  fetchOptions: {
    headers: {
      'x-hasura-role': 'provider',
    },
  },
}

export default function Logic(props) {
  let [{ fetching, error, data }] = useQuery({
    query,
    context,
  })
  let setFlowTo = useSetFlowTo()

  let story =
    '/App/MyAccount/Content/Members/MoreActions/Content/DownloadAllCSV'
  useEffect(() => {
    if (error) {
      setFlowTo(`${story}/Failure`)
    } else {
      if (!fetching && data) {
        // Process the data
        try {
          processData(data)
          setFlowTo(`${story}/Done`)
        } catch (e) {
          console.error(e)
          setFlowTo(`${story}/Failure`)
        }
      }
    }
  }, [story, setFlowTo, data, fetching, error])
  return <View />
}
