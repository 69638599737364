import { _and, _in, _neq, _or, _wilike } from 'Data/hasura.js'
import { FixedSizeList as List } from 'react-window'
import { DataProvider, useData } from 'Data/ViewsData.js'
import { useSelected } from 'Data/Selected.js'
import { useSetFlowTo } from 'Logic/ViewsFlow.js'
import { useQuery } from 'Data/Api.js'
import AutoSizer from 'react-virtualized-auto-sizer'
import gql from 'graphql-tag'
import Info from 'DesignSystem/Info/view.js'
import View from './view.js'
import LoadingBar from 'DesignSystem/LoadingBar/logic.js'
import React, { useEffect } from 'react'

let query = gql`
  query list_professionals($limit: Int, $where: professionals_bool_exp) {
    professionals(
      limit: $limit
      where: $where
      order_by: { user: { profile: { last_name: asc, first_name: asc } } }
    ) {
      id
      license {
        id
        name
      }
      user {
        id
        profile {
          id
          first_name
          last_name
        }
      }
    }
  }
`

function where({ filter: rfilter, companies_ids, nothing }) {
  let filter = rfilter.trim()

  return _and(
    _in('professional_locations.provider_location.company_id', companies_ids),
    _neq('license_number', `${nothing}`),
    filter &&
      _or(
        _wilike('license.name', filter),
        _wilike('user.profile.first_name', filter),
        _wilike('user.profile.last_name', filter),
        _wilike('user.phone_number', filter)
      )
  )
}

// this number is a combination of the 70px of the height plus the 8px
// of the marginTop from the Card in ListItems.view
// until we find a better way to
let ROW_HEIGHT = 78

export default function Logic(props) {
  let filteredProfessionals = useData({
    viewPath: props.viewPath,
    path: 'filtered_professionals',
    context: 'filtered_professionals',
  })
  let [selected, select] = useSelected()
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: {
      limit: 10000,
      where: where({
        filter: props.filter,
        companies_ids: selected.companies_ids,
        nothing: selected.nothing,
      }),
    },
  })
  let setFlowTo = useSetFlowTo()

  useEffect(() => {
    if (!data) return

    filteredProfessionals.onChange({
      filtered_professionals: data.professionals.map(
        professional => professional.id
      ),
    })
  }, [data]) // eslint-disable-line

  if (fetching)
    return (
      <LoadingBar
        alignSelf="flexStart"
        borderBottomLeftRadius={4}
        borderBottomRightRadius={4}
        borderTopLeftRadius={4}
        borderTopRightRadius={4}
        height={8}
        marginTop={20}
        width={200}
      />
    )
  if (error) return `😱 ${error.message}`

  function Row({ index, style }) {
    let item = data.professionals[index]
    return (
      <DataProvider
        context="professional"
        key={item.id}
        value={{ professional: item }}
      >
        <View
          {...style}
          onClick={() => {
            select({ professionalId: item.id })
            setFlowTo(
              '/App/MyAccount/Content/Profile/Content/MyProfessionals/Item/Content'
            )
          }}
        />
      </DataProvider>
    )
  }

  // TODO move as stories
  // ListItems/Empty
  // ListItems/Item
  if (data.professionals.length === 0) {
    return <Info marginLeft={10} text="No professionals" />
  }

  // don't use react-window and autosizer in short lists
  // a simple Array.map works fine there
  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          height={height}
          itemCount={data.professionals.length}
          itemSize={ROW_HEIGHT}
          width={width}
        >
          {Row}
        </List>
      )}
    </AutoSizer>
  )
}

Logic.defaultProps = {
  filter: '',
}
