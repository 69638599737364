import useSetFlowToBasedOnData from 'Data/useSetFlowToBasedOnData.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  useSetFlowToBasedOnData({
    path: 'user.member.related_members',
    context: 'user',
    story:
      '/App/MyAccount/Content/Profile/Content/Individuals/Item/Content/Delete/Employee/Content/Dependents',
    isContent: (value) => value.length > 0,
  });

  return <View />;
}
