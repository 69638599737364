import { useData } from 'Data/ViewsData.js';
import React from 'react';
import View from './view.js';

export default function Logic() {
  let item = useData({
    context: 'company',
    path: 'company.physical_address',
  });

  return (
    <View
      onChooseAddress={(address) => {
        item.onChange({ ...item.value, ...address });
      }}
    />
  );
}
