import { _and, _in, _or, _wilike } from 'Data/hasura.js'
import { FixedSizeList as List } from 'react-window'
import { DataProvider, useData } from 'Data/ViewsData.js'
import { useSelected } from 'Data/Selected.js'
import { useSetFlowTo } from 'Logic/ViewsFlow.js'
import { useQuery } from 'Data/Api.js'
import AutoSizer from 'react-virtualized-auto-sizer'
import gql from 'graphql-tag'
import Info from 'DesignSystem/Info/view.js'
import View from './view.js'
import LoadingBar from 'DesignSystem/LoadingBar/logic.js'
import React, { useEffect } from 'react'

let query = gql`
  query list_members($limit: Int, $where: members_bool_exp) {
    members(
      limit: $limit
      where: $where
      order_by: { profile: { last_name: asc, first_name: asc } }
    ) {
      id
      status
      phone_number
      effective_date
      created_at
      stripe_subscription_status
      stripe_payment_metadata
      stripe_latest_invoice_url
      is_individual
      termination_date
      profile {
        first_name
        last_name
      }
      main_member {
        is_individual
        stripe_subscription_status
      }
    }
  }
`

function where({ filter: rfilter, companies_ids, membersStatuses }) {
  let filter = rfilter.trim()

  return _and(
    _in('provider_location.company_id', companies_ids),
    _in('status', membersStatuses),
    filter &&
      _or(
        /*_wilike('policy_id', filter), _wilike('status', filter), */ {
          profile: _or(
            _wilike('first_name', filter),
            _wilike('last_name', filter)
          ),
        }
      )
  )
}

// this number is a combination of the 70px of the height plus the 8px
// of the marginTop from the Card in ListItems.view
// until we find a better way to
let ROW_HEIGHT = 78

let context = {
  fetchOptions: {
    headers: {
      'x-hasura-role': 'provider',
    },
  },
}

export default function Logic(props) {
  let filteredMembers = useData({
    viewPath: props.viewPath,
    path: 'filtered_members',
    context: 'filtered_members',
  })

  let [selected, select] = useSelected()
  let [{ fetching, error, data }] = useQuery({
    query,
    context,
    variables: {
      limit: 10000,
      where: where({
        filter: props.filter,
        companies_ids: selected.companies_ids,
        membersStatuses: selected.membersStatuses,
      }),
    },
  })
  let setFlowTo = useSetFlowTo()

  useEffect(() => {
    if (!data) return

    filteredMembers.onChange({
      filtered_members: data.members.map(member => member.id),
    })
  }, [data]) // eslint-disable-line

  if (fetching)
    return (
      <LoadingBar
        alignSelf="flexStart"
        borderBottomLeftRadius={4}
        borderBottomRightRadius={4}
        borderTopLeftRadius={4}
        borderTopRightRadius={4}
        height={8}
        marginTop={20}
        width={200}
      />
    )

  if (error) return `😱 ${error.message}`

  function Row({ index, style }) {
    let item = data.members[index]
    return (
      <DataProvider key={item.id} context="member" value={{ member: item }}>
        <View
          {...style}
          onClick={() => {
            select({ memberId: item.id })
            setFlowTo('/App/MyAccount/Content/Members/Item/Content')
          }}
        />
      </DataProvider>
    )
  }

  // TODO move as stories
  // ListItems/Empty
  // ListItems/Item
  if (data.members.length === 0) {
    return <Info text="No items" />
  }

  // don't use react-window and autosizer in short lists
  // a simple Array.map works fine there
  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          height={height}
          itemCount={data.members.length}
          itemSize={ROW_HEIGHT}
          width={width}
        >
          {Row}
        </List>
      )}
    </AutoSizer>
  )
}
Logic.defaultProps = {
  filter: '',
}
