import View from './view.js';
import React from 'react';

export default function Logic(props) {
  function onChange(event) {
    let value = event.target.files[0];
    if (!value) {
      return;
    }

    if (typeof props.onChange === 'function') {
      props.onChange(value);
    }
  }

  return <View {...props} onChange={onChange} />;
}
