import { useData } from 'Data/ViewsData.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  let dataCompany = useData({
    context: 'company',
    path: 'company.company_doing_business_as',
  });
  return dataCompany.value.map((dba) => {
    return <View key={dba.id} value={dba.name} />;
  });
}
