import { useData } from 'Data/ViewsData.js';
import React from 'react';
import View from './view.js';

export default function Logic() {
  let dataProviderLocation = useData({
    context: 'provider_location',
    path: 'provider_location.address',
  });

  return (
    <View
      onChooseAddress={(address) => {
        dataProviderLocation.onChange({
          ...dataProviderLocation.value,
          ...address,
        });
      }}
    />
  );
}
