import { useData } from 'Data/ViewsData.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import View from './view.js';
import React, { useLayoutEffect } from 'react';

export default function Logic() {
  let item = useData({
    context: 'company',
    path: 'company.company_agreements',
  });
  let setFlowTo = useSetFlowTo();

  useLayoutEffect(() => {
    let isMemberGroup = item.value.some((item) => item.type === 'MemberGroup');
    setFlowTo(
      `/App/MyAccount/Content/Profile/Content/MyCompanies/Item/Content/Show/EmployerContribution/Content/BrokerLegalName/${
        isMemberGroup ? 'Content' : 'No'
      }`
    );
  }, [item.value]); // eslint-disable-line
  // ignore setFlowTo

  return <View />;
}
