let findComponentInPlace = (place, componentType, field = 'long_name') => {
  let component = place.address_components.find(ac =>
    ac.types.includes(componentType)
  );

  if (!component) return '';
  return component[field];
};

let mapPlaceToAddress = place => ({
  street: `${findComponentInPlace(
    place,
    'street_number'
  )} ${findComponentInPlace(place, 'route')}`.trim(),
  city:
    findComponentInPlace(place, 'locality') ||
    findComponentInPlace(place, 'sublocality'),
  state: findComponentInPlace(
    place,
    'administrative_area_level_1',
    'short_name'
  ),
  zip: findComponentInPlace(place, 'postal_code'),
});

export default mapPlaceToAddress;
