import View from './view.js';
import React from 'react';
import useSetFlowToBasedOnData from 'Data/useSetFlowToBasedOnData.js';

export default function Logic() {
  useSetFlowToBasedOnData({
    context: 'utilization_report_import',
    path: 'utilization_report_import.items',
    story:
      '/App/MyAccount/Content/Profile/Content/Utilization/Item/Content/Provide/Content/Data/Verify/InvalidData',
    isContent: (list) => list.some((item) => !item.is_valid),
  });

  return <View />;
}
