import { Api } from 'Data/Api.js';
import { Selected } from 'Data/Selected.js';
import { useAuth } from 'Data/Auth.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  let auth = useAuth();
  if (!auth.data.userId) return null;

  return (
    <Api>
      <Selected>
        <View />
      </Selected>
    </Api>
  );
}
