import { AUTH } from 'Data/constants.js';
import { DataProvider, useData } from 'Data/ViewsData.js';
import { decode } from 'Logic/base64.js';
import { isInvalid } from 'utils/graphQlHelpers.js';
import { notifyError, useNotifications } from 'utils/useNotifications.js';
import { useAuth } from 'Data/Auth.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import View from './view.js';
import React, { useMemo, useEffect } from 'react';
import qs from 'querystringify';

let ITEM = {
  signup: {
    is_invite: false,
    email: '',
    password: '',
    first_name: '',
    last_name: '',
  },
};

export default function Logic() {
  let [, notify] = useNotifications();
  let auth = useAuth();
  let dataAuth = useData({ context: 'auth', path: 'auth' });
  let setFlowTo = useSetFlowTo();

  let initialItem = useMemo(() => {
    let { invite } = qs.parse(window.location.search);
    try {
      let { email = '', first_name = '', last_name = '' } = JSON.parse(
        decode(invite)
      );
      dataAuth.onChange(email, 'auth.email');
      return {
        signup: {
          is_invite: true,
          email,
          first_name,
          last_name,
          password: '',
        },
      };
    } catch (error) {}

    return ITEM;
  }, []); // eslint-disable-line
  // run once

  useEffect(() => {
    if (!initialItem.signup.email) return;

    let cancel = false;

    (async () => {
      let userExists = await auth.userExists(initialItem.signup.email);
      if (cancel || !userExists) return;

      dataAuth.onChange(initialItem.signup.email, 'auth.email');
      window.history.pushState(null, null, '/');
      setFlowTo('/App/Auth/SignIn');
    })();

    return () => (cancel = true);
  }, [auth, initialItem]); // eslint-disable-line
  // ignore dataAuthEmail setFlowTo

  return (
    <DataProvider context="signup" value={initialItem} onSubmit={onSubmit}>
      <View />
    </DataProvider>
  );

  async function onSubmit(next) {
    if (isInvalid(AUTH.SIGNUP, next.signup, notify)) return true;

    try {
      dataAuth.onChange((state) => {
        state.auth.email = next.signup.email;
        state.auth.password = next.signup.password;
      });
      await auth.signUp(next.signup);
      // clear out invite
      window.history.pushState(null, null, '/');
      setFlowTo('/App/Auth/SignUpConfirm');
    } catch (error) {
      console.error(error);
      notify(notifyError('Something went wrong. Please, try again.'));
    }
  }
}
