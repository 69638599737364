import { INDIVIDUAL_STATUSES_FILTER } from 'Data/constants.js'
import { useSelected } from 'Data/Selected.js'
import View from './view.js'
import React from 'react'

export default function Logic() {
  let [selected, setSelected] = useSelected()

  return (
    <View
      from={INDIVIDUAL_STATUSES_FILTER}
      selected={selected.individualsStatuses}
      onClick={onClick}
    />
  )

  function onClick(individualsStatuses) {
    setSelected({ ...selected, individualsStatuses })
  }
}
