import { useData } from 'Data/ViewsData.js';
import View from './view.js';
import React, { useState } from 'react';

export default function Logic() {
  let data = useData({
    context: 'utilization_report',
    path: 'utilization_report.has_nothing_to_upload',
  });
  let [flow, setFlowTo] = useState(data.value ? 'No' : 'DownloadAction');

  return (
    <View
      flowNo={flow === 'No'}
      flowDownloadAction={flow === 'DownloadAction'}
      flowProcessing={flow === 'Processing'}
      flowDone={flow === 'Done'}
      flowFailure={flow === 'Failure'}
      onClickSetFlowDownloadAction={() => setFlowTo('DownloadAction')}
      onClickSetFlowProcessing={() => setFlowTo('Processing')}
      onClickSetFlowDone={() => setFlowTo('Done')}
      onClickSetFlowFailure={() => setFlowTo('Failure')}
    />
  );
}
