import { useData } from 'Data/ViewsData.js';
import React from 'react';
import View from './view.js';

export default function Logic() {
  let dataMember = useData({
    context: 'member',
    path: 'member.profile.address',
  });

  return (
    <View
      onChooseAddress={(address) => {
        dataMember.onChange({ ...dataMember.value, ...address });
      }}
    />
  );
}
