import React, { useEffect } from 'react'
import { _in } from 'Data/hasura.js'
import { dateISOIn } from 'utils/dateHelpers.js'
import { useQuery } from 'Data/Api.js'
import { useData } from 'Data/ViewsData.js'
import { useSetFlowTo } from 'Logic/ViewsFlow.js'
import gql from 'graphql-tag'
import { downloadCsv } from 'utils/csv.js'
import View from './view.js'

function where({ company_ids }) {
  return _in('id', company_ids)
}

let query = gql`
  query list_companies($where: companies_bool_exp) {
    companies(where: $where) {
      id
      financial_email
      business_type
      effective_date
      expiry_date
      federal_tax_id_number
      legal_name
      national_provider_id
      phone_number
      primary_industry_sic_code
      physical_address {
        city
        state
        street
        zip
      }
    }
  }
`

let fields = [
  'Id',
  'Billing Email',
  'Business Type',
  'Effective Date',
  'Expire Date',
  'Tax ID',
  'Legal Name',
  'Provider ID',
  'Phone Number',
  'SIC Code',
  'City',
  'State',
  'Street',
  'Zip',
]

function processData({ companies }) {
  let data = companies.map(company => [
    company.id,
    company.financial_email,
    company.business_type,
    dateISOIn(company.effective_date),
    dateISOIn(company.expiry_date),
    company.federal_tax_id_number,
    company.legal_name,
    company.national_provider_id,
    company.phone_number,
    company.primary_industry_sic_code,
    company.physical_address && company.physical_address.city,
    company.physical_address && company.physical_address.state,
    company.physical_address && company.physical_address.street,
    company.physical_address && company.physical_address.zip,
  ])

  downloadCsv({
    name: 'greyfinch-companies-report.csv',
    fields,
    data,
  })
}

export default function Logic(props) {
  let companyData = useData({
    viewPath: props.viewPath,
    path: 'filtered_companies',
    context: 'filtered_companies',
  })
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: {
      where: where({
        company_ids: companyData.value.filtered_companies,
      }),
    },
  })
  let setFlowTo = useSetFlowTo()

  let story =
    '/App/MyAccount/Content/Profile/Content/MyCompanies/MoreActions/Content/DownloadCSV'
  useEffect(() => {
    if (error) {
      setFlowTo(`${story}/Failure`)
    } else {
      if (!fetching && data) {
        // Process the data
        try {
          processData(data)
          setFlowTo(`${story}/Done`)
        } catch (e) {
          console.error(e)
          setFlowTo(`${story}/Failure`)
        }
      }
    }
  }, [story, setFlowTo, data, fetching, error])
  return <View />
}
