import { useData } from 'Data/ViewsData.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  let dataPlans = useData({ context: 'plans', path: 'plans' });
  let dataMember = useData({
    context: 'member',
    path: 'member.plan_id',
  });

  return dataPlans.value.map((plan) => (
    <View
      key={plan.id}
      id={plan.id}
      isSelected={dataMember.value === plan.id}
      onClick={() => dataMember.onChange(plan.id)}
      text={plan.name}
      isInvalid={!dataMember.value && dataMember._forceRequired}
    />
  ));
}
