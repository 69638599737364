import { useData } from 'Data/ViewsData.js';
import React from 'react';
import View from './view.js';

export default function Logic(props) {
  let item = useData({
    path: 'company.physical_address',
  });

  return (
    <View
      {...props}
      onChooseAddress={(address) => {
        item.onChange({ ...item.value, ...address });
      }}
    />
  );
}
