import { useEffect, useMemo, useRef, useState } from 'react';
import codes from 'Data/inputPhoneCodes.json';

export let DEFAULT_PHONE = '+1';

export default function useInputPhone(props) {
  let value = props.value || '';
  let [valueCode, onChangeValueCode] = useState(() => {
    let maybeCode = codes.find((item) => value.startsWith(item.id));
    return maybeCode ? maybeCode.id : DEFAULT_PHONE;
  });
  let [valueNumber, onChangeValueNumber] = useState(() =>
    value.replace(valueCode, '')
  );
  let canTriggerOnChange = useRef(false);
  let numberInput = useRef();

  let finalValue = useMemo(() => {
    let finalValue = `${valueCode}${valueNumber.replace(/\D/g, '')}`;
    if (finalValue === DEFAULT_PHONE) {
      finalValue = null;
    }
    return finalValue;
  }, [valueCode, valueNumber]);

  useEffect(() => {
    if (!props.onChange || !canTriggerOnChange.current) {
      canTriggerOnChange.current = true;
      return;
    }

    props.onChange(finalValue);
  }, [finalValue]); // eslint-disable-line
  // ignore props.onChange

  function onCopy(event) {
    if (finalValue) {
      event.clipboardData.setData('text', finalValue);
      event.preventDefault();
    }
  }

  function onPaste(event) {
    try {
      let pastedValue = event.clipboardData.getData('text').trim();

      if (pastedValue.startsWith('+')) {
        let code = codes.find((item) => pastedValue.startsWith(item.id));
        if (code) {
          event.preventDefault();
          onChangeValueCode(code.id);
          onChangeValueNumber(pastedValue.replace(code.id, ''));
          numberInput.current.focus();
        }
      }
    } catch (error) {}
  }

  function onBlurCode() {
    numberInput.current.focus();
  }

  return {
    codes,
    numberInput,
    onBlurCode,
    onChangeValueCode,
    onChangeValueNumber,
    onCopy,
    onPaste,
    valueCode,
    valueNumber,
  };
}
