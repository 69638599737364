import { _eq, _or } from 'Data/hasura.js'
import { useActAsSupport } from 'Logic/ActAsSupport.js'
import { useAuth } from 'Data/Auth.js'
import { useEffect, useMemo } from 'react'
import { useSelected } from 'Data/Selected.js'
import { useQuery } from 'urql'
import gql from 'graphql-tag'

let EMPTY_LIST = []

let DEFAULT_QUERY = gql`
  query get_companies(
    $company_agreements: company_agreements_bool_exp
    $company_users: company_users_bool_exp
  ) {
    companies(
      where: {
        company_agreements: $company_agreements
        company_users: $company_users
      }
    ) {
      id
      legal_name
    }
  }
`

export function useCompanyUsersFilter() {
  let auth = useAuth()
  let [isActingAsSupport] = useActAsSupport()

  return useMemo(
    () =>
      isActingAsSupport
        ? null
        : auth.permissions.isBroker
        ? _or(_eq('user_id', auth.data.userId), {
            company: {
              broker: { company_users: _eq('user_id', auth.data.userId) },
            },
          })
        : _eq('user_id', auth.data.userId),
    [auth.permissions.isBroker, auth.data.userId, isActingAsSupport]
  )
}

export function useCompanies({
  query = DEFAULT_QUERY,
  type = null,
  has_accepted_agreement = null,
} = {}) {
  let company_users = useCompanyUsersFilter()
  let company_agreements = useMemo(() => {
    let ret = {}

    if (type) {
      ret.type = { _eq: type }
    }

    if (has_accepted_agreement) {
      ret.has_accepted_agreement = { _eq: has_accepted_agreement }
    }

    return ret
  }, [type, has_accepted_agreement])

  let [{ fetching, error, data }] = useQuery({
    query,
    variables: { company_users, company_agreements },
  })

  return fetching || error ? EMPTY_LIST : data.companies
}

export function useCompaniesSelected(props) {
  let companies = useCompanies(props)
  let [selected, select] = useSelected()

  useEffect(() => {
    if (companies.length === 0) return

    if (companies.some(item => selected.companies_ids.includes(item.id))) {
      // there's a case when the filter was used on a set of companies that
      // aren't allowed to be selected anymore, eg, it was a list of all
      // companies and now it is a list of only providers - generally when
      // working as support
      // we need to adjust the selected companies to include the restricted
      // subset in that case
      let selectedMatchingCompanies = selected.companies_ids.filter(id =>
        companies.some(item => item.id === id)
      )
      if (selectedMatchingCompanies.length !== selected.companies_ids.length) {
        select({ companies_ids: selectedMatchingCompanies })
      }

      return
    }

    select({ companies_ids: [companies[0].id] })
  }, [companies]) // eslint-disable-line
  // ignore selected and select

  return {
    companies,
    selected: selected.companies_ids,
    onClick: companies_ids => select({ companies_ids }),
  }
}
