import { DataProvider } from 'Data/ViewsData.js';
import { useSelected } from 'Data/Selected.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import { useQuery, useMutation } from 'Data/Api.js';
import {
  useNotifications,
  notifyError,
  notifySuccess,
} from 'utils/useNotifications.js';
import { isInvalid, parseGraphqlError } from 'utils/graphQlHelpers.js';
import { EDIT } from 'Data/constants.js';
import gql from 'graphql-tag';
import React from 'react';
import View from './view.js';
import getChanges from 'Data/getChanges.js';

let query = gql`
  query getProfessionalAndCompanyDoingBusinessAs($id: uuid!) {
    professional: professionals_by_pk(id: $id) {
      id
      user {
        id
        email
        phone_number
        profile {
          id
          first_name
          last_name
        }
      }
      is_accepting_new_patients
      license_id
      license_number
      license_custom_age_max
      license_custom_age_min
      license {
        id
        name
        default_age_min
        default_age_max
        is_primary_care
        can_age_be_set_by_professional
      }
      professional_locations {
        provider_location_id
        provider_location {
          id
          address {
            id
            street
            city
            state
            zip
          }
          company_doing_business_as {
            id
            name
          }
        }
      }
    }
  }
`;

let MUTATION = gql`
  mutation UpdateProfessional(
    $delete_locations: professional_locations_bool_exp!
    $insert_locations: [professional_locations_insert_input!]!
    $user_id: uuid
    $update_user: users_set_input
    $profile_id: uuid
    $update_profile: profiles_set_input
    $professional_id: uuid
    $update_professional: professionals_set_input
  ) {
    delete_professional_locations(where: $delete_locations) {
      returning {
        professional_id
        provider_location_id
        professional {
          id
          professional_locations {
            provider_location_id
            provider_location {
              id
              address {
                id
                street
                city
                state
                zip
              }
              company_doing_business_as {
                id
                name
              }
            }
          }
        }
      }
    }

    insert_professional_locations(objects: $insert_locations) {
      returning {
        professional_id
        provider_location_id
        professional {
          id
          professional_locations {
            provider_location_id
            provider_location {
              id
              address {
                id
                street
                city
                state
                zip
              }
              company_doing_business_as {
                id
                name
              }
            }
          }
        }
      }
    }

    update_users(where: { id: { _eq: $user_id } }, _set: $update_user) {
      returning {
        id
        email
        phone_number
      }
    }

    update_profiles(
      where: { id: { _eq: $profile_id } }
      _set: $update_profile
    ) {
      returning {
        id
        first_name
        last_name
      }
    }

    update_professionals(
      where: { id: { _eq: $professional_id } }
      _set: $update_professional
    ) {
      returning {
        id
        is_accepting_new_patients
        license_id
        license_number
        # TODO add other fields like license number, etc
      }
    }
  }
`;

export default function Logic() {
  // TODO it would be handy if we would export the story from the view and
  // reference it here in setFlowTo as Content.storyPath, it would remove the need
  // to keep references as strings
  let story =
    '/App/MyAccount/Content/Profile/Content/MyProfessionals/Item/Content';
  let setFlowTo = useSetFlowTo();
  let [, notify] = useNotifications();
  let [selected] = useSelected();
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: { id: selected.professionalId },
  });
  let [, executeMutation] = useMutation(MUTATION);

  if (fetching) return null;
  if (error) return `😱 ${error.message}`;

  // canBeSet={get(
  //   captureData[0],
  //   'professional.license.can_age_be_set_by_professional'
  // )}

  return (
    <DataProvider context="professional" onSubmit={onSubmit} value={data}>
      <View />
    </DataProvider>
  );

  async function onSubmit(next) {
    if (isInvalid(EDIT.PROFESSIONAL, next.professional, notify)) return true;

    let entity = {
      key: 'professional_id',
      value: data.professional.id,
    };
    let [insert_locations, delete_locations] = getChanges({
      prev: data.professional.professional_locations,
      next: next.professional.professional_locations,
      pick: ['provider_location_id', 'provider_location_id'],
      entity,
    });

    let professionalVariables = {
      is_accepting_new_patients: next.professional.is_accepting_new_patients,
      license_id: next.professional.license.id,
      license_number: next.professional.license_number,
    };

    if (next.professional.license.is_primary_care) {
      if (next.professional.license.can_age_be_set_by_professional) {
        professionalVariables.license_custom_age_max =
          next.professional.license_custom_age_max;
        professionalVariables.license_custom_age_min =
          next.professional.license_custom_age_min;
      } else {
        professionalVariables.license_custom_age_max = null;
        professionalVariables.license_custom_age_min = null;
      }
    }

    let mutationResponse = await executeMutation({
      delete_locations,
      insert_locations,

      user_id: data.professional.user.id,
      update_user: {
        phone_number: next.professional.user.phone_number,
        email: next.professional.user.email,
      },

      profile_id: data.professional.user.profile.id,
      update_profile: {
        first_name: next.professional.user.profile.first_name,
        last_name: next.professional.user.profile.last_name,
      },

      professional_id: data.professional.id,
      update_professional: professionalVariables,
    });

    if (mutationResponse.error) {
      notify(notifyError(parseGraphqlError(mutationResponse.error).message));
    } else {
      notify(
        notifySuccess(
          `${next.professional.user.profile.first_name} successfully updated`
        )
      );
      setFlowTo(`${story}/Show`);
    }
  }
}
