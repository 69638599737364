import View from './view.js'
import React from 'react'
import useFilter from 'useFilter.js'
import { DataProvider } from 'Data/ViewsData.js'

let value = {
  filtered_members: [],
}

export default function Logic() {
  let filter = useFilter()
  return (
    <DataProvider context="filtered_members" value={value}>
      <View {...filter} />
    </DataProvider>
  )
}
