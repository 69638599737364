import { DataProvider } from 'Data/ViewsData.js';
import { EDIT } from 'Data/constants.js';
import { isInvalid, parseGraphqlError } from 'utils/graphQlHelpers.js';
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'utils/useNotifications.js';
import { useAuth } from 'Data/Auth.js';
import { useSelected } from 'Data/Selected.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import { useQuery, useMutation } from 'Data/Api.js';
import View from './view.js';
import gql from 'graphql-tag';
import isToday from 'date-fns/isToday';
import isPast from 'date-fns/isPast';
import parseISO from 'date-fns/parseISO';
import React from 'react';

let query = gql`
  query get_member_and_plans($id: uuid!) {
    plans {
      id
      name
      pricing
    }
    member: members_by_pk(id: $id) {
      id
      policy_id
      status
      effective_date
      has_accepted_agreement
      company {
        id
        expiry_date
        effective_date
      }
      plan_id
      plan {
        name
        pricing
      }
      user {
        id
        phone_number
        email
      }
      profile {
        id
        first_name
        last_name
        date_of_birth
        marital_status
        social_security_number
        address {
          id
          street
          city
          state
          zip
        }
      }
      provider_location_id
      professional_id
      created_at
      updated_at
      related_members_aggregate {
        aggregate {
          count
        }
      }
      related_members(
        order_by: { profile: { last_name: asc, first_name: asc } }
      ) {
        id
        effective_date
        main_member_id
        relationship_to_main_member
        provider_location_id
        professional_id
        status
        can_act_as_main_member
        user_id
        user {
          id
          email
        }
        profile {
          id
          first_name
          last_name
          date_of_birth
          gender
          social_security_number
        }
      }
      termination_date
    }
  }
`;
let MUTATION = gql`
  mutation update_member(
    $user_id: uuid!
    $user_update: users_set_input!
    $profile_id: uuid!
    $profile_update: profiles_set_input!
    $address_id: uuid!
    $address_update: addresses_set_input!
    $member_id: uuid!
    $member_update: members_set_input!
  ) {
    update_users(where: { id: { _eq: $user_id } }, _set: $user_update) {
      returning {
        id
        email
        phone_number
      }
    }

    update_profiles(
      where: { id: { _eq: $profile_id } }
      _set: $profile_update
    ) {
      returning {
        id
        first_name
        last_name
        date_of_birth
        marital_status
        social_security_number
      }
    }

    update_addresses(
      where: { id: { _eq: $address_id } }
      _set: $address_update
    ) {
      returning {
        id
        street
        city
        state
        zip
      }
    }

    update_members(where: { id: { _eq: $member_id } }, _set: $member_update) {
      returning {
        id
        provider_location_id
        provider_location {
          id
        }
        professional_id
        professional {
          id
        }
        related_members(
          order_by: { profile: { last_name: asc, first_name: asc } }
        ) {
          id
          effective_date
          main_member_id
          relationship_to_main_member
          provider_location_id
          professional_id
          can_act_as_main_member
          user_id
          user {
            id
            email
          }
          profile {
            id
            first_name
            last_name
            date_of_birth
            gender
            social_security_number
          }
        }
      }
    }
  }
`;

// TODO FIXME
// - data updates it doesn't refresh until the field is refetched
//   this is related to the caching mechanisms which we still need to properly
//   implement, either https://github.com/FormidableLabs/Data/Api.js-exchange-graphcache/blob/master/docs/architecture.md
//   or back to trying apollo (v3 might work better for our case now, worth
//   trying it
//   https://github.com/apollographql/apollo-client/tree/release-3.0/docs/source)
//
// - fields aren't highlighted because it's a custom nesting with data that I've
//   done there, see Content/Dependents/Content.view.logic.js
//   we need to address that in Views when we see data
let MUTATION_UPDATE_DEPENDENT = gql`
  mutation update_dependent(
    $profile_id: uuid!
    $profile_update: profiles_set_input!
    $member_id: uuid!
    $member_update: members_set_input!
  ) {
    update_profiles(
      where: { id: { _eq: $profile_id } }
      _set: $profile_update
    ) {
      returning {
        id
        first_name
        last_name
        date_of_birth
        marital_status
        social_security_number
      }
    }
    update_members(where: { id: { _eq: $member_id } }, _set: $member_update) {
      returning {
        id
        relationship_to_main_member
        provider_location_id
        professional_id
      }
    }
  }
`;
let MUTATION_INSERT_DEPENDENTS = gql`
  mutation insert_dependent($objects: [members_insert_input!]!) {
    insert_members(objects: $objects) {
      returning {
        id
        relationship_to_main_member
        effective_date
        professional_id
        provider_location_id
        profile {
          id
          first_name
          last_name
          date_of_birth
          marital_status
          social_security_number
        }
      }
    }
  }
`;

let MUTATION_INSERT_USERS = gql`
  mutation onboarding_insert_users($users: [users_insert_input!]!) {
    insert_users(objects: $users) {
      returning {
        id
        profile_id
      }
    }
  }
`;
let MUTATION_UPDATE_USER = gql`
  mutation update_user($user_id: uuid!, $user_update: users_set_input!) {
    update_users(where: { id: { _eq: $user_id } }, _set: $user_update) {
      returning {
        id
        email
      }
    }
  }
`;

export default function Logic() {
  // TODO it would be handy if we would export the story from the view and
  // reference it here in setFlowTo as Content.storyPath, it would remove the need
  // to keep references as strings
  let story = '/App/MyAccount/Content/Profile/Content/Employees/Item/Content';
  let setFlowTo = useSetFlowTo();
  let [, notify] = useNotifications();
  let [selected] = useSelected();
  let auth = useAuth();
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: { id: selected.memberId },
  });

  let [, executeMutation] = useMutation(MUTATION);
  let [, executeMutationInsertDependents] = useMutation(
    MUTATION_INSERT_DEPENDENTS
  );
  let [, executeMutationUpdateDependent] = useMutation(
    MUTATION_UPDATE_DEPENDENT
  );
  let [, executeMutationInsertUsers] = useMutation(MUTATION_INSERT_USERS);
  let [, executeMutationUpdateUser] = useMutation(MUTATION_UPDATE_USER);

  if (fetching) return null;
  if (error) return `😱 ${error.message}`;

  return (
    <DataProvider context="plans" value={data}>
      <DataProvider context="member" onSubmit={onSubmit} value={data}>
        <View />
      </DataProvider>
    </DataProvider>
  );

  async function onSubmit(next) {
    if (isInvalid(EDIT.MEMBER, next, notify)) return true;

    let mutationVariables = {
      user_id: data.member.user.id,
      user_update: {
        email: next.member.user.email,
        phone_number: next.member.user.phone_number,
      },

      profile_id: data.member.profile.id,
      profile_update: {
        first_name: next.member.profile.first_name,
        last_name: next.member.profile.last_name,
        date_of_birth: next.member.profile.date_of_birth,
        marital_status: next.member.profile.marital_status,
        social_security_number: next.member.profile.social_security_number,
      },

      member_id: data.member.id,
      member_update: {
        status: next.member.status,
        professional_id: next.member.professional_id,
        provider_location_id: next.member.provider_location_id,
        effective_date: next.member.effective_date,
        plan_id: next.member.plan_id,
      },

      address_id: data.member.profile.address.id,
      address_update: {
        street: next.member.profile.address.street,
        city: next.member.profile.address.city,
        state: next.member.profile.address.state,
        zip: next.member.profile.address.zip,
      },
    };

    let related_members_to_update = next.member.related_members.filter(
      (item) => item.main_member_id !== null
    );

    let relatedMembersProfileIdToUserId = {};
    let usersToCreate = related_members_to_update.filter(
      (item) => item.can_act_as_main_member && !item.user_id
    );
    if (usersToCreate.length > 0) {
      let mutationResponseInsertUsers = await executeMutationInsertUsers({
        users: usersToCreate.map((item) => ({
          profile_id: item.profile.id,
          email: item.user.email,
        })),
      });
      if (mutationResponseInsertUsers.error) {
        notify(
          notifyError(
            parseGraphqlError(mutationResponseInsertUsers.error).message
          )
        );
        return true;
      }

      mutationResponseInsertUsers.data.insert_users.returning.forEach(
        (item) => {
          relatedMembersProfileIdToUserId[item.profile_id] = item.id;
        }
      );
    }

    let usersToUpdate = related_members_to_update.filter(
      (item) => item.can_act_as_main_member && item.user_id
    );
    if (usersToUpdate.length > 0) {
      let mutationResponseUpdateUser = await Promise.all(
        usersToUpdate.map((item) =>
          executeMutationUpdateUser({
            user_id: item.user_id,
            user_update: { email: item.user.email },
          })
        )
      );
      if (mutationResponseUpdateUser.some((item) => item.error)) {
        mutationResponseUpdateUser
          .filter((item) => item.error)
          .forEach((item) =>
            notify(notifyError(parseGraphqlError(item.error).message))
          );
        return true;
      }
    }

    let mutationResponseUpdateDependents = await Promise.all(
      related_members_to_update.map((item) =>
        executeMutationUpdateDependent({
          profile_id: item.profile.id,
          profile_update: {
            first_name: item.profile.first_name,
            last_name: item.profile.last_name,
            date_of_birth: item.profile.date_of_birth,
            gender: item.profile.gender,
            social_security_number: item.profile.social_security_number,
          },
          member_id: item.id,
          member_update: {
            relationship_to_main_member: item.relationship_to_main_member,
            provider_location_id: item.provider_location_id,
            professional_id: item.professional_id,
            effective_date: item.effective_date,
            plan_id: next.member.plan_id,
            can_act_as_main_member: item.can_act_as_main_member,
            user_id:
              item.user_id ||
              relatedMembersProfileIdToUserId[item.profile.id] ||
              null,
          },
        })
      )
    );
    if (mutationResponseUpdateDependents.some((item) => item.error)) {
      mutationResponseUpdateDependents
        .filter((item) => item.error)
        .forEach((item) =>
          notify(notifyError(parseGraphqlError(item.error).message))
        );

      return true;
    }

    let related_members_to_insert = next.member.related_members.filter(
      (item) => item.main_member_id === null
    );
    if (related_members_to_insert.length > 0) {
      let mutationResponseInsertDependents = await executeMutationInsertDependents(
        {
          objects: related_members_to_insert.map((item) => {
            let effective_date = parseISO(item.effective_date);

            let mitem = {
              main_member_id: data.member.id,
              plan_id: data.member.plan_id,
              relationship_to_main_member: item.relationship_to_main_member,
              provider_location_id: item.provider_location_id,
              professional_id: item.professional_id,
              effective_date: item.effective_date,
              status: 'Enrolled',
              is_configuration_up_to_member: false,
              can_act_as_main_member: item.can_act_as_main_member,
              profile: {
                data: {
                  first_name: item.profile.first_name,
                  last_name: item.profile.last_name,
                  date_of_birth: item.profile.date_of_birth,
                  gender: item.profile.gender,
                  social_security_number: item.profile.social_security_number,
                },
              },
            };

            if (data.member.has_accepted_agreement) {
              mitem.accepted_agreement_on = 'now()';
              mitem.accepted_agreement_by_user_id = auth.data.userId;
              mitem.has_accepted_agreement = true;

              if (isToday(effective_date) || isPast(effective_date)) {
                mitem.status = 'Active';
              }
            }

            if (item.can_act_as_main_member) {
              mitem.user = {
                data: {
                  email: item.user.email,
                },
              };
            }

            return mitem;
          }),
        }
      );

      if (mutationResponseInsertDependents.error) {
        notify(
          notifyError(
            parseGraphqlError(mutationResponseInsertDependents.error).message
          )
        );
        return true;
      }
    }

    let mutationResponse = await executeMutation(mutationVariables);
    if (mutationResponse.error) {
      notify(notifyError(parseGraphqlError(mutationResponse.error).message));
      return true;
    }

    notify(
      notifySuccess(
        `${next.member.profile.first_name} ${next.member.profile.last_name} was successfully updated!`
      )
    );
    setFlowTo(`${story}/Show`);
  }
}
