import { useData } from 'Data/ViewsData.js';
import View from './view.js';
import React from 'react';
import sicCodes from 'Data/sicCodes.json';

let PROVIDER_SIC = sicCodes.find((item) => item.id.startsWith('8021')).id;

export default function Logic() {
  let dataCompany = useData({ context: 'company', path: 'company.isProvider' });

  return (
    <View
      onClick={() => {
        dataCompany.onChange((next) => {
          let isProvider = !next.company.isProvider;

          next.company.isProvider = isProvider;
          next.company.isMemberGroup = !isProvider;
          next.company.effective_date = '';
          next.company.member_group_contributions = [
            {
              contribution: {
                one_person: 0,
                two_people: 0,
                three_people: 0,
                four_people: 0,
                five_people_or_more: 0,
              },
            },
            {
              contribution: {
                one_person: 0,
                two_people: 0,
                three_people: 0,
                four_people: 0,
                five_people_or_more: 0,
              },
            },
          ];

          if (isProvider) {
            next.company.primary_industry_sic_code = PROVIDER_SIC;
          }
        });
      }}
      isSelected={dataCompany.value}
    />
  );
}
