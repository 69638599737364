import View from './view.js';
import React from 'react';
import useSetFlowToBasedOnData from 'Data/useSetFlowToBasedOnData.js';

export default function Logic() {
  useSetFlowToBasedOnData({
    context: 'user',
    path: 'user.professional.members_aggregate.aggregate.count',
    story:
      '/App/MyAccount/Content/Profile/Content/Employees/Item/Content/Delete/Professional/Content/Delete/Content/MembersWillBeReassigned',
    isContent: (value) => value > 0,
  });

  return <View />;
}
