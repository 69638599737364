import React, { useImperativeHandle, useRef } from 'react';
import View from './view.js';
import useMaskedInput from '@viewstools/use-masked-input';

let MASKS = {
  date: {
    mask: [/[0-1]/, /\d/, '/', /[0-3]/, /\d/, '/', /[1-2]/, /\d/, /\d/, /\d/],
    guide: true,
  },
  phone: {
    mask: [
      '(',
      /\d/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
    guide: true,
  },
  fein: {
    mask: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    guide: true,
  },
  twoDigits: {
    mask: [/\d/, /\d/],
    guide: false,
  },
  threeDigits: {
    mask: [/\d/, /\d/, /\d/],
    guide: true,
  },
  fourDigits: {
    mask: [/\d/, /\d/, /\d/, /\d/],
    guide: false,
  },
  fiveDigits: {
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/],
    guide: true,
  },
  sixDigits: {
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    guide: true,
  },
  nineDigits: {
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    guide: true,
  },
  tenDigits: {
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    guide: true,
  },
  currency: {
    mask: [/\d/, /\d/, '.', /\d/, /\d/],
    guide: false,
  },
  ssn: {
    mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    guide: true,
  },
};

function Logic(props, ref) {
  let input = useRef(null);
  if (process.env.NODE_ENV === 'development' && !(props.mask in MASKS)) {
    throw new Error(
      `${props.mask} isn't a valid mask. Choose from ${Object.keys(MASKS)}`
    );
  }

  useImperativeHandle(ref, () => ({
    focus: () => {
      input.current.focus();
    },
  }));

  let onChange = useMaskedInput({
    input,
    ...MASKS[props.mask],
    onChange: (event) => props.onChange(event.target.value),
    value: props.value,
  });

  return (
    <View
      {...props}
      innerRef={input}
      onChange={onChange}
      onKeyUp={(event) => {
        if (event.key === 'Enter' && typeof props.onSubmit === 'function') {
          props.onSubmit();
        }
      }}
      onSubmit={props.onSubmit}
    />
  );
}
Logic.defaultProps = {
  mask: 'phone',
  value: '',
};

export default React.forwardRef(Logic);
