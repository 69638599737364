import { _and, _in, _neq, _or, _wilike } from 'Data/hasura.js'
import { FixedSizeList as List } from 'react-window'
import { DataProvider, useData } from 'Data/ViewsData.js'
import { useSelected } from 'Data/Selected.js'
import { useSetFlowTo } from 'Logic/ViewsFlow.js'
import { useQuery } from 'Data/Api.js'
import AutoSizer from 'react-virtualized-auto-sizer'
import gql from 'graphql-tag'
import Info from 'DesignSystem/Info/view.js'
import View from './view.js'
import LoadingBar from 'DesignSystem/LoadingBar/logic.js'
import React, { useEffect } from 'react'

let query = gql`
  query list_provider_locations(
    $limit: Int
    $where: provider_locations_bool_exp
  ) {
    provider_locations(limit: $limit, where: $where) {
      id
      company_doing_business_as {
        id
        name
      }
      address {
        id
        street
        city
        state
      }
    }
  }
`

function where({ filter: rfilter, companies_ids, nothing }) {
  let filter = rfilter.trim()

  return _and(
    _in('company_id', companies_ids),
    _neq('company.legal_name', `${nothing}`),
    filter &&
      _or(
        _wilike('company_doing_business_as.name', filter),
        _wilike('address.street', filter),
        _wilike('address.city', filter),
        _wilike('address.state', filter)
        // TODO how to filter by zip code
      )
  )
}

let ROW_HEIGHT = 78

export default function Logic(props) {
  let filteredLocations = useData({
    viewPath: props.viewPath,
    path: 'filtered_locations',
    context: 'filtered_locations',
  })
  let [selected, select] = useSelected()
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: {
      limit: 10000,
      where: where({
        filter: props.filter,
        companies_ids: selected.companies_ids,
        nothing: selected.nothing,
      }),
    },
  })
  let setFlowTo = useSetFlowTo()

  useEffect(() => {
    if (!data) return

    filteredLocations.onChange({
      filtered_locations: data.provider_locations.map(location => location.id),
    })
  }, [data]) // eslint-disable-line

  if (fetching)
    return (
      <LoadingBar
        alignSelf="flexStart"
        borderBottomLeftRadius={4}
        borderBottomRightRadius={4}
        borderTopLeftRadius={4}
        borderTopRightRadius={4}
        height={8}
        marginTop={20}
        width={200}
      />
    )
  if (error) return `😱 ${error.message}`

  function Row({ index, style }) {
    let item = data.provider_locations[index]
    return (
      <DataProvider
        context="provider_location"
        key={item.id}
        value={{ provider_location: item }}
      >
        <View
          {...style}
          onClick={() => {
            select({ locationId: item.id })
            setFlowTo(
              '/App/MyAccount/Content/Profile/Content/MyLocations/Item/Content'
            )
          }}
        />
      </DataProvider>
    )
  }

  // TODO move as stories
  // ListItems/Empty
  // ListItems/Item
  if (data.provider_locations.length === 0) {
    return <Info marginLeft={10} text="No locations" />
  }

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          height={height}
          itemCount={data.provider_locations.length}
          itemSize={ROW_HEIGHT}
          width={width}
        >
          {Row}
        </List>
      )}
    </AutoSizer>
  )
}

Logic.defaultProps = {
  filter: '',
}
