import { AUTH } from 'Data/constants.js'
import { DataProvider, useData } from 'Data/ViewsData.js'
import { isInvalid } from 'utils/graphQlHelpers.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'utils/useNotifications.js'
import { useAuth } from 'Data/Auth.js'
import { useSetFlowTo } from 'Logic/ViewsFlow.js'
import View from './view.js'
import React from 'react'

let ITEM = {
  signup_confirm: {
    code: '',
  },
}

export default function Logic() {
  let [, notify] = useNotifications()
  let auth = useAuth()
  let dataAuth = useData({ context: 'auth', path: 'auth' })
  let setFlowTo = useSetFlowTo()

  return (
    <DataProvider context="signup_confirm" value={ITEM} onSubmit={onSubmit}>
      <View onClickResendCode={onClickResendCode} />
    </DataProvider>
  )

  async function onSubmit(next) {
    if (isInvalid(AUTH.SIGNUP_CONFIRM, next.signup_confirm, notify)) return true

    try {
      await auth.signUpConfirm({
        email: dataAuth.value.email,
        ...next.signup_confirm,
      })
    } catch (error) {
      handleError(error)
    }

    try {
      let res = await auth.signIn({
        email: dataAuth.value.email,
        password: dataAuth.value.password,
      })
      if (res.challengeName) {
        setFlowTo('/App/Auth/SignIn')
        return
      }
      auth.getUserContext(res)
    } catch (error) {
      setFlowTo('/App/Auth/SignIn')
    }
  }

  async function onClickResendCode() {
    try {
      await auth.signUpConfirmResendCode({ email: dataAuth.value.email })
      notify(notifySuccess(`A new code it's way to ${dataAuth.value.email}`))
    } catch (error) {
      handleError(error)
    }
  }

  function handleError(error) {
    console.error(error)
    if (
      (error.code === 'NotAuthorizedException' &&
        error.message.includes('Current status is CONFIRMED')) ||
      (error.code === 'InvalidParameterException' &&
        error.message.includes('already confirmed'))
    ) {
      return notify(
        notifyError('Verification already completed. Please sign in.')
      )
    }

    if (error.code === 'UserLambdaValidationException') {
      setFlowTo('/App/RuntimeError')
      return
    }

    return notify(notifyError('Please, verify your details'))
  }
}
