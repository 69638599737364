import View from './view.js'
import React from 'react'
let ChoiceRadialLogic = (props) => {
  let id = props.item ? props.item.id : props.id
  let text = props.item ? props.item.text : props.text

  return (
    <View
      {...props}
      id={id}
      text={text}
      isSelected={props.isSelected || (id && id === props.selected)}
      onClick={() => {
        props.onClick(id)
      }}
    />
  )
}

export default ChoiceRadialLogic
