import useSetFlowToBasedOnData from 'Data/useSetFlowToBasedOnData.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  useSetFlowToBasedOnData({
    path: 'user.company_users',
    context: 'user',
    story:
      '/App/MyAccount/Content/Profile/Content/Employees/Item/Content/Delete/Admin',
    isContent: (value) => value.length > 0,
  });

  return <View />;
}
