import { useData } from 'Data/ViewsData.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  let data = useData({
    context: 'signature',
    path: 'signature.company.has_accepted_agreement',
  });
  return <View isDisabled={!data.value} />;
}
