import { conformToMask } from 'text-mask-core'
import { ROLES } from './constants.js'
import codes from 'Data/inputPhoneCodes.json'
import differenceInCalendarYears from 'date-fns/differenceInCalendarYears'
import _formatDate from 'date-fns/format'
import _formatNumber from 'number-format.js'
import get from 'lodash/get'
import isValidDate from 'date-fns/isValid'
import padStart from 'lodash/padStart'
import parseDate from 'date-fns/parse'
import parseISO from 'date-fns/parseISO'
import addMonths from 'date-fns/addMonths'
import subMonths from 'date-fns/subMonths'

function formatDate(rvalue, formatIn, formatOut) {
  let value =
    formatIn === 'iso'
      ? parseISO(rvalue)
      : parseDate(rvalue, formatIn, new Date())
  return isValidDate(value) ? _formatDate(value, formatOut) : rvalue
}

export function paymentDisplay(value) {
  if (!value) return '-'

  return `${
    value?.last_payment_update && dateTimeISOIn(value?.last_payment_update)
  } ${paymentDisplayStatus(value)}
  `
}

export function paymentDisplayStatus(value) {
  if (!value) return '-'

  return `${value?.status === 'succeeded' ? '✓' : '×'}`
}

export function paymentDisplayDate(value) {
  if (!value) return '-'

  return `${
    value?.last_payment_update && dateTimeISOIn(value?.last_payment_update)
  }`
}

export function paymentDetailDisplay(value) {
  if (!value) return '-'
  return `${value?.last_payment_detail}`
}

export function today() {
  let today = new Date()
  return `${today.getDate()} ${today.toLocaleString('default', {
    month: 'short',
  })} ${today.getFullYear()}`
}

export function formatNumber(value, format) {
  return typeof value === 'number' ? _formatNumber(format, value) : value
}

export function money(value) {
  return formatNumber(value, '$#,##0.00')
}

export function address(address) {
  return `${address.street}, ${address.city}, ${address.state}, ${address.zip}`
}

export function age(date_of_birth) {
  return differenceInCalendarYears(new Date(), parseISO(date_of_birth))
}

let SOCIAL_SECURITY_NUMBER = /^\d{3}-\d{2}-\d{4}$/
export function socialSecurityNumber(input) {
  if (input && SOCIAL_SECURITY_NUMBER.test(input)) return input
  let normalizedValue = input.replace(/[^0-9]/g, '')
  if (normalizedValue.length === 9) {
    let tokens = [
      normalizedValue.slice(0, 3),
      normalizedValue.slice(3, 5),
      normalizedValue.slice(5, 9),
    ]
    return tokens.join('-')
  }
  return input
}
let SOCIAL_SECURITY_NUMBER_TO_HIDE = /^\d{3}-\d{2}/
export function socialSecurityNumberHide(value) {
  return value && value.replace(SOCIAL_SECURITY_NUMBER_TO_HIDE, '•••-••')
}

export function memberId(value) {
  return typeof value === 'string' ? padStart(value, 8, '0') : value
}

export function textToNumber(value) {
  return typeof value === 'string'
    ? parseFloat(value.replace(/[^0-9.]/g, ''), 10)
    : value
}

export function dateShortIn(value) {
  return formatDate(value, 'yyyy-MM-dd', 'MM/dd/yyyy')
}
export function dateShortOut(value) {
  return formatDate(value, 'MM/dd/yyyy', 'yyyy-MM-dd')
}

export function dateyyyyMMddToMMyyyy(value) {
  return formatDate(value, 'yyyy-MM-dd', 'MM/yyyy')
}

export function dateyyyyMMddToQuarterWithMonths(value) {
  let date = subMonths(parseISO(value), 3)
  let month1 = _formatDate(date, 'MMM')
  let month2 = _formatDate(addMonths(date, 1), 'MMM')
  let month3 = _formatDate(addMonths(date, 2), 'MMM')
  let quarter = _formatDate(date, 'QQQ-yyyy')

  return `${quarter} (${month1}, ${month2}, ${month3})`
}

export function dateISOIn(value) {
  return formatDate(value, 'iso', 'MM/dd/yyyy')
}
export function dateISOOut(value) {
  return formatDate(value, 'MM/dd/yyyy', 'iso')
}

export function dateTimeISOIn(value) {
  return formatDate(value, 'iso', 'MM/dd/yyyy HH:mm')
}

export function timestampISOIn(value) {
  return formatDate(value, 'iso', 'HH:mm MM/dd/yyyy z')
}

let PHONE_NUMBER_US_MASK = [
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]
let DEFAULT_PHONE_CODE = '+1'
export function phoneNumberUS(rvalue) {
  if (!rvalue) return rvalue

  let code = codes.find(item => rvalue.startsWith(item.id))
  let value = rvalue.replace(code ? code.id : DEFAULT_PHONE_CODE, '')
  return conformToMask(value, PHONE_NUMBER_US_MASK).conformedValue
}

export function procedureFee(value, data) {
  return formatNumber(
    value,
    data.procedure.is_patient_fee_percentage ? '###.##%' : '$#,##0.00'
  )
}

export function prePaid(value, data) {
  return data.procedure.prepaid === 0 ? '-' : data.procedure.prepaid
}

export function companyUserRole(value) {
  let role = ROLES.find(role => role.id === value)
  return role ? role.text : '-'
}

export function booleanToYesNo(value) {
  return value ? 'Yes' : 'No'
}

export function getPrice(pricing, people) {
  return pricing[getTier(people)]
}

function memberEmployerContributionValue(value, data) {
  let plan = get(
    data,
    'member.plan.member_group_contributions[0].contribution',
    null
  )
  if (!plan) return null

  let people =
    get(data, 'member.related_members_aggregate.aggregate.count', 0) + 1

  return getPrice(plan, people)
}

export function memberEmployerContribution(value, data) {
  return formatNumber(memberEmployerContributionValue(value, data), '$#,##0.00')
}

export function memberCost(value, data) {
  let employerContribution = memberEmployerContributionValue(value, data)
  let totalCost = memberTotalCostValue(value, data)

  return formatNumber(totalCost - employerContribution, '$#,##0.00')
}

function getTier(people) {
  switch (people) {
    case 1:
      return 'one_person'

    case 2:
      return 'two_people'

    case 3:
      return 'three_people'

    case 4:
      return 'four_people'

    default:
      return 'five_people_or_more'
  }
}

function memberTotalCostValue(value, data) {
  let pricing = get(data, 'member.plan.pricing', null)
  if (!pricing) return null
  let people =
    get(data, 'member.related_members_aggregate.aggregate.count', 0) + 1
  let tier = getTier(people)

  return getPrice(pricing, tier)
}

export function memberTotalCost(value, data) {
  return formatNumber(memberTotalCostValue(value, data), '$#,##0.00')
}

export function memberCostTier(data, tier) {
  let cost = data.plan.pricing[tier]
  let contribution = data.plan.member_group_contributions[0].contribution[tier]

  return formatNumber(cost - contribution, '$#,##0.00')
}

export function memberCostOnePerson(value, data) {
  return memberCostTier(data, 'one_person')
}
export function memberCostTwoPeople(value, data) {
  return memberCostTier(data, 'two_people')
}
export function memberCostThreePeople(value, data) {
  return memberCostTier(data, 'three_people')
}
export function memberCostFourPeople(value, data) {
  return memberCostTier(data, 'four_people')
}
export function memberCostFivePeopleOrMore(value, data) {
  return memberCostTier(data, 'five_people_or_more')
}

export function memberCoveredMembers(value) {
  return value + 1
}

export function numberThousand(value) {
  return formatNumber(value, '#,##0.00')
}

export function addressStreetZipCityState(value) {
  return value
    ? `${value.street}, ${value.zip}, ${value.city}, ${value.state}`
    : '-'
}

export function locationsProfessional(value) {
  return value.professional_locations
    .map(item => item.provider_location.doing_business_as_and_address)
    .join('; ')
}

export function locationsLastProfessional(value) {
  return value.professional_locations
    .filter(
      item =>
        get(
          item,
          'provider_location.professional_locations_aggregate.aggregate.count'
        ) === 1
    )
    .map(item => item.provider_location.doing_business_as_and_address)
    .join('; ')
}

export function locationsLastPrimaryCareProfessional(value) {
  return (
    value.license &&
    value.license.is_primary_care &&
    value.professional_locations
      .some(
        item =>
          get(
            item,
            'provider_location.primary_care_professional_locations_aggregate.aggregate.count'
          ) === 1
      )
      .map(item => item.provider_location.doing_business_as_and_address)
      .join('; ')
  )
}

export function isLastProfessionalInALocation(value) {
  return value.professional_locations.some(
    item =>
      get(
        item,
        'provider_location.professional_locations_aggregate.aggregate.count'
      ) === 1
  )
}

export function isLastPrimaryCareProfessionalInALocation(value) {
  return (
    value.license &&
    value.license.is_primary_care &&
    value.professional_locations.some(
      item =>
        get(
          item,
          'provider_location.primary_care_professional_locations_aggregate.aggregate.count'
        ) === 1
    )
  )
}

export function isLastSuperAdminInCompany(user) {
  if (
    !Array.isArray(user.company_users) ||
    user.company_users.length === 0 ||
    user.company_users[0].role === 'company-user'
  )
    return false

  return user.company_users.some(
    item => get(item, 'company.company_users_aggregate.aggregate.count') === 1
  )
}

export function relatedMembers(value) {
  return value.map(item => item.profile.first_name).join(', ')
}

export function inviteLinkAdmin(value) {
  return `${process.env.REACT_APP_APP_URL_ADMIN}?invite=${btoa(
    JSON.stringify({
      email: value.email,
      first_name: value.profile.first_name,
      last_name: value.profile.last_name,
    })
  )}`
}

export function memberType(member) {
  if (member.is_individual && !member.main_member) {
    return 'Individual'
  } else if (member.company) {
    return 'Employee'
  } else if (member.main_member?.is_individual) {
    return 'Individual dependent'
  } else if (member.main_member?.company_id) {
    return 'Employee dependent'
  }

  return 'Unknown'
}
