import { useCompanies } from 'Logic/useFilterByCompanies.js';
import { useData } from 'Data/ViewsData.js';
import gql from 'graphql-tag';
import View from './view.js';
import React, { useEffect } from 'react';

let query = gql`
  query get_companies(
    $company_agreements: company_agreements_bool_exp
    $company_users: company_users_bool_exp
  ) {
    companies(
      where: {
        company_agreements: $company_agreements
        company_users: $company_users
      }
    ) {
      id
      legal_name
      expiry_date
      effective_date
    }
  }
`;

export default function Logic() {
  let dataEmployeesImport = useData({
    context: 'employees_import',
    path: 'employees_import.company',
  });
  let companies = useCompanies({
    type: 'MemberGroup',
    has_accepted_agreement: true,
    query,
  });

  useEffect(() => {
    if (dataEmployeesImport.value) return;

    dataEmployeesImport.onChange((next) => {
      next.employees_import.company = companies.find(
        (item) => item.id === next.employees_import.company_id
      );
    });
  }, [dataEmployeesImport, companies]);

  return (
    <View
      from={companies}
      onChangeManual={(id) => {
        dataEmployeesImport.onChange((next) => {
          next.employees_import.company = companies.find(
            (item) => item.id === id
          );
          next.employees_import.company_id = id;
        });
      }}
    />
  );
}
