import { useAuth } from 'Data/Auth.js';
import { useActAsSupport } from 'Logic/ActAsSupport.js'
import View from './view.js';
import React from 'react';
import useSetFlowToBasedOnData from 'Data/useSetFlowToBasedOnData.js';

export default function Logic() {
  let auth = useAuth();
  let [isActingAsSupport] = useActAsSupport();

  useSetFlowToBasedOnData({
    context: 'company',
    path: 'company.company_agreements',
    isContent: (value) =>
      (isActingAsSupport || !auth.permissions.isBroker) &&
      value.some((item) => !item.has_accepted_agreement),
    story:
      '/App/MyAccount/Content/Profile/Content/MyCompanies/Item/Content/Show/SignAgreementAction',
    dependency: `${auth.permissions.isBroker}${isActingAsSupport}`,
  });

  return <View />;
}
