import { DataProvider, useData } from 'Data/ViewsData.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  let dataMember = useData({
    context: 'member',
    path: 'member.related_members',
  });

  return dataMember.value.map((related_member) => (
    <DataProvider
      context="related_member"
      value={{ related_member }}
      key={related_member.id}
    >
      <View />
    </DataProvider>
  ));
}
