import useSetFlowToBasedOnData from 'Data/useSetFlowToBasedOnData.js';
import isBefore from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';
import View from './view.js';
import React from 'react';

export default function Logic() {
  useSetFlowToBasedOnData({
    path: 'user.member',
    context: 'user',
    story:
      '/App/MyAccount/Content/Profile/Content/Admins/Item/Content/Delete/OtherRoles/Content/Employee/Content/Delete/Content/WillBeDeleted',
    isContent: (value) =>
      value.termination_date &&
      value.status === 'Invited' &&
      isBefore(
        parseISO(value.termination_date),
        parseISO(value.effective_date)
      ),
  });

  return <View />;
}
