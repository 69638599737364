import * as isValid from 'Data/validate.js'
import {
  ADD,
  AUTH,
  EDIT,
  INVITE,
  VERIFY_MEMBERSHIP,
  DELETE,
  ACTIVATE,
} from 'Data/constants.js'
import { notifyInvalid } from './useNotifications.js'

let checksContributions = (contributions, i, plan) => {
  let { contribution } = contributions[i]
  let maxContribution = isValid.MAX_PLAN_CONTRIB[i === 0 ? 'BASIC' : 'ENHANCED']

  return (
    [
      Number(contribution.one_person) > maxContribution.ONE && 'employee',
      Number(contribution.two_people) > maxContribution.TWO &&
        'first dependent',
      Number(contribution.three_people) > maxContribution.THREE &&
        'second dependent',
      Number(contribution.four_people) > maxContribution.FOUR &&
        'third dependent',
      Number(contribution.five_or_more_people) > maxContribution.FIVE &&
        'four or more dependents',
    ].filter(Boolean).length > 0
  )
}

let getInvalidContributions = contributions => {
  let basics = checksContributions(contributions, 0)
  let enhanced = checksContributions(contributions, 1)
  let invalid = []
  if (basics) invalid.push('Basic plan contributions')
  if (enhanced) invalid.push('Enhanced plan contributions')
  return invalid
}

function getInvalidFields(formName, values) {
  switch (formName) {
    case ADD.COMPANY: {
      return [
        !values.company.legal_name && 'legal name',
        !values.company.business_type && 'business type',
        !isValid.email(values.company.financial_email) && 'financial email',
        values.company.isMemberGroup &&
          !isValid.email(values.company.hr_email) &&
          'HR email',
        !isValid.phoneNumber(values.company.phone_number) && 'phone number',
        !isValid.textInput(values.company.physical_address.street) && 'street',
        !isValid.textInput(values.company.physical_address.city) && 'city',
        !isValid.state(values.company.physical_address.state) && 'state',
        !isValid.zip(values.company.physical_address.zip) && 'zip',
        !isValid.fein(values.company.federal_tax_id_number) &&
          'federal tax id (FEIN)',
        !isValid.sic(values.company.primary_industry_sic_code) &&
          'primary industry sic code',
        // TODO bring in NPI validation but not as required
        // values.company.isProvider &&
        //   !values.company.national_provider_id &&
        //   'National Provider ID',
        values.company.company_doing_business_as.some(
          item => !isValid.textInput(item.name)
        ) && 'doing business as',
        values.company.isMemberGroup &&
          !isValid.effectiveDate(values.company.effective_date) &&
          'effective date',
        ...getInvalidContributions(values.company.member_group_contributions),
      ]
    }

    case EDIT.COMPANY: {
      let isMemberGroup = values.company.company_agreements.some(
        item => item.type === 'MemberGroup'
      )
      let isProvider = values.company.company_agreements.some(
        item => item.type === 'Provider'
      )

      return [
        !values.company.legal_name && 'legal name',
        !isValid.email(values.company.financial_email) && 'financial email',
        isMemberGroup && !isValid.email(values.company.hr_email) && 'HR email',
        !isValid.phoneNumber(values.company.phone_number) && 'phone number',
        !isValid.textInput(values.company.physical_address.street) && 'street',
        !isValid.textInput(values.company.physical_address.city) && 'city',
        !isValid.state(values.company.physical_address.state) && 'state',
        !isValid.zip(values.company.physical_address.zip) && 'zip',
        !isValid.fein(values.company.federal_tax_id_number) &&
          'federal tax id (FEIN)',
        // TODO uncomment the sic validation once the sic input on company change is fixed
        // !isValid.sic(values.company.primary_industry_sic_code) &&
        // 'primary industry sic code',
        // TODO bring in NPI validation but not as required
        // values.company.type === 'Provider' &&
        //   !values.national_provider_id &&
        //   'National Provider ID',
        isProvider &&
          (values.company.company_doing_business_as.length === 0 ||
            values.company.company_doing_business_as.some(
              item => !isValid.textInput(item.name)
            )) &&
          'doing business as',
        // values.company.isMemberGroup &&
        //   !isValid.date(values.company.effective_date) &&
        //   'effective date',
        ...getInvalidContributions(values.company.member_group_contributions),
      ]
    }

    case INVITE.ADMIN_TO_SIGN: {
      return values.useExistingUser
        ? [
            !isValid.uuid(values.user_id) && 'existing user',
            values.companies_ids.length === 0 && 'companies',
          ]
        : [
            !isValid.name(values.user.profile.first_name) && 'first name',
            !isValid.name(values.user.profile.last_name) && 'last name',
            !isValid.email(values.user.email) && 'email',
            !isValid.phoneNumber(values.user.phone_number) && 'phone number',
          ]
    }

    case ADD.ACCEPT_AGREEMENT: {
      return [
        !values.company.has_accepted_agreement && 'accept agreement',
        !isValid.name(values.user.profile.title) && 'job title',
        (!values.user.profile.signature ||
          values.user.profile.signature.blob.type !== 'image/png') &&
          'signature',
      ]
    }

    case ADD.MANY_MEMBERS: {
      return [
        (values.employees.length === 0 ||
          values.employees.some(item => !item.is_valid)) &&
          'no valid employee data to import',
        !isValid.uuid(values.company_id) && 'company',
        !isValid.effectiveDate(values.effective_date) && 'effective date',
      ]
    }

    case ADD.MEMBER: {
      let validation = [
        !isValid.uuid(values.company_id) && 'company',
        !isValid.phoneNumber(values.user.phone_number) && 'phone number',
        !isValid.birthday(values.user.profile.date_of_birth) && 'date of birth',
        !isValid.maritalStatus(values.user.profile.marital_status) &&
          'marital status',
        !isValid.social_security_number(
          values.user.profile.social_security_number
        ) && 'social security number',
        (!values.user.profile.address ||
          !isValid.textInput(values.user.profile.address.street)) &&
          'street',
        (!values.user.profile.address ||
          !isValid.textInput(values.user.profile.address.city)) &&
          'city',
        (!values.user.profile.address ||
          !isValid.state(values.user.profile.address.state)) &&
          'state',
        (!values.user.profile.address ||
          !isValid.zip(values.user.profile.address.zip)) &&
          'zip',
        !isValid.uuid(values.plan && values.plan.id) && 'plan type',
        !isValid.effectiveDate(values.effective_date) && 'effective date',
        values.related_members.map((values, i) => [
          !isValid.relationship(values.relationship_to_main_member) &&
            `dependent #${i + 1} relationship`,
          !isValid.name(values.profile.first_name) &&
            `dependent #${i + 1} first name`,
          !isValid.name(values.profile.last_name) &&
            `dependent #${i + 1} last name`,
          !isValid.birthday(values.profile.date_of_birth) &&
            `dependent #${i + 1} date of birth`,
          !isValid.gender(values.profile.gender) &&
            `dependent #${i + 1} gender`,
          !isValid.social_security_number(
            values.profile.social_security_number
          ) && `dependent #${i + 1} social security number`,
        ]),
      ]
      return values.useExistingUser
        ? [!isValid.uuid(values.user_id) && 'existing user', ...validation]
        : [
            !isValid.name(values.user.profile.first_name) && 'first name',
            !isValid.name(values.user.profile.last_name) && 'last name',
            !isValid.email(values.user.email) && 'email',
            ...validation,
          ]
    }
    case EDIT.MEMBER_INDIVIDUAL: {
      return [
        !isValid.name(values.member.profile.first_name) && 'first name',
        !isValid.name(values.member.profile.last_name) && 'last name',
        !isValid.email(values.member.user.email) && 'email',
        !isValid.phoneNumber(values.member.user.phone_number) && 'phone number',
        !isValid.birthday(values.member.profile.date_of_birth) &&
          'date of birth',
        !isValid.maritalStatus(values.member.profile.marital_status) &&
          'marital status',
        !isValid.street(values.member.profile.address.street) && 'street',
        !isValid.city(values.member.profile.address.city) && 'city',
        !isValid.state(values.member.profile.address.state) && 'state',
        !isValid.zip(values.member.profile.address.zip) && 'zip',
        values.member.related_members.map((values, i) => [
          !isValid.relationship(values.relationship_to_main_member) &&
            `dependent #${i + 1} relationship`,
          !isValid.name(values.profile.first_name) &&
            `dependent #${i + 1} first name`,
          !isValid.name(values.profile.last_name) &&
            `dependent #${i + 1} last name`,
          !isValid.birthday(values.profile.date_of_birth) &&
            `dependent #${i + 1} date of birth`,
          !isValid.gender(values.profile.gender) &&
            `dependent #${i + 1} gender`,
          !isValid.effectiveDate(values.effective_date) &&
            `dependent #${i + 1} effective date`,
          values.can_act_as_main_member &&
            (!values.user || !isValid.email(values.user.email)) &&
            `dependent #${i + 1} email`,
        ]),
      ]
    }
    case EDIT.MEMBER: {
      return [
        !isValid.name(values.member.profile.first_name) && 'first name',
        !isValid.name(values.member.profile.last_name) && 'last name',
        !isValid.email(values.member.user.email) && 'email',
        !isValid.phoneNumber(values.member.user.phone_number) && 'phone number',
        !isValid.birthday(values.member.profile.date_of_birth) &&
          'date of birth',
        !isValid.maritalStatus(values.member.profile.marital_status) &&
          'marital status',
        !isValid.social_security_number(
          values.member.profile.social_security_number
        ) && 'social security number',
        !isValid.street(values.member.profile.address.street) && 'street',
        !isValid.city(values.member.profile.address.city) && 'city',
        !isValid.state(values.member.profile.address.state) && 'state',
        !isValid.zip(values.member.profile.address.zip) && 'zip',
        values.member.related_members.map((values, i) => [
          !isValid.relationship(values.relationship_to_main_member) &&
            `dependent #${i + 1} relationship`,
          !isValid.name(values.profile.first_name) &&
            `dependent #${i + 1} first name`,
          !isValid.name(values.profile.last_name) &&
            `dependent #${i + 1} last name`,
          !isValid.birthday(values.profile.date_of_birth) &&
            `dependent #${i + 1} date of birth`,
          !isValid.gender(values.profile.gender) &&
            `dependent #${i + 1} gender`,
          !isValid.social_security_number(
            values.profile.social_security_number
          ) && `dependent #${i + 1} social security number`,
          !isValid.effectiveDate(values.effective_date) &&
            `dependent #${i + 1} effective date`,
          values.can_act_as_main_member &&
            (!values.user || !isValid.email(values.user.email)) &&
            `dependent #${i + 1} email`,
        ]),
      ]
    }
    case INVITE.MEMBER: {
      return values.useExistingUser
        ? [
            !isValid.uuid(values.user_id) && 'existing user',
            !isValid.effectiveDate(values.effective_date) && 'effective date',
            !isValid.phoneNumber(values.user.phone_number) && 'phone number',
          ]
        : [
            !isValid.name(values.user.profile.first_name) && 'first name',
            !isValid.name(values.user.profile.last_name) && 'last name',
            !isValid.email(values.user.email) && 'email',
            !isValid.phoneNumber(values.user.phone_number) && 'phone number',
            !isValid.effectiveDate(values.effective_date) && 'effective date',
          ]
    }

    case ACTIVATE.MEMBER: {
      return [
        !isValid.effectiveDate(values.member.effective_date) &&
          'effective date',
      ]
    }

    case ADD.PROFESSIONAL: {
      return values.useExistingUser
        ? [
            !isValid.uuid(values.user_id) && 'existing user',
            !isValid.uuid(values.license.id) && 'license',
            !isValid.licenseNumber(values.license_number) && 'license number',
            !values.professional_locations.length > 0 && 'locations',
          ]
        : [
            !isValid.email(values.user.email) && 'email',
            values.user.phone_number &&
              !isValid.phoneNumber(values.user.phone_number) &&
              'phone number',
            !isValid.name(values.user.profile.first_name) && 'first name',
            !isValid.name(values.user.profile.last_name) && 'last name',
            !isValid.uuid(values.license.id) && 'license',
            !isValid.licenseNumber(values.license_number) && 'license number',
            !values.professional_locations.length > 0 && 'locations',
          ]
    }
    case EDIT.PROFESSIONAL: {
      return [
        !isValid.email(values.user.email) && 'email',
        values.user.phone_number &&
          !isValid.phoneNumber(values.user.phone_number) &&
          'phone number',
        !values.professional_locations.length > 0 && 'locations',
        !isValid.uuid(values.license.id) && 'license',
        !isValid.licenseNumber(values.license_number) && 'license number',
      ]
    }

    case ADD.LOCATION: {
      return [
        !isValid.uuid(values.company_doing_business_as_id) && 'name',
        values.phone_number !== '' &&
          !isValid.phoneNumber(values.phone_number) &&
          'phone number',
        !isValid.website(values.website) && 'website',
        !isValid.textInput(values.address.street) && 'street',
        !isValid.textInput(values.address.city) && 'city',
        !isValid.state(values.address.state) && 'state',
        !isValid.zip(values.address.zip) && 'zip',
      ]
    }
    case EDIT.LOCATION: {
      return [
        !isValid.uuid(values.provider_location.company_doing_business_as_id) &&
          'name',
        !isValid.website(values.provider_location.website) && 'website',
        !isValid.textInput(values.provider_location.address.street) && 'street',
        !isValid.textInput(values.provider_location.address.city) && 'city',
        !isValid.state(values.provider_location.address.state) && 'state',
        !isValid.zip(values.provider_location.address.zip) && 'zip',
      ]
    }

    case ADD.ADMIN: {
      return values.useExistingUser
        ? [
            !values.user_id && 'existing user',
            !values.role && 'role',
            values.companies_ids.length === 0 && 'companies',
          ]
        : [
            !isValid.name(values.user.profile.first_name) && 'first name',
            !isValid.name(values.user.profile.last_name) && 'last name',
            !isValid.email(values.user.email) && 'email',
            values.user.phone_number &&
              !isValid.phoneNumber(values.user.phone_number) &&
              'phone number',
            !values.role && 'role',
            values.companies_ids.length === 0 && 'companies',
          ]
    }

    case EDIT.ADMIN: {
      return [
        !isValid.name(values.user.profile.first_name) && 'first name',
        !isValid.name(values.user.profile.last_name) && 'last name',
        !isValid.email(values.user.email) && 'email',
        values.user.phone_number &&
          !isValid.phoneNumber(values.user.phone_number) &&
          'phone number',
        !values.role && 'role',
        values.companies_ids.length === 0 && 'companies',
      ]
    }

    case EDIT.USER_PROFILE: {
      return [
        !isValid.name(values.user.profile.first_name) && 'first name',
        !isValid.name(values.user.profile.last_name) && 'last name',
        !isValid.email(values.user.email) && 'email',
        !isValid.phoneNumber(values.user.phone_number) && 'phone number',
      ]
    }

    case EDIT.UTILIZATION_REPORT: {
      return [
        !values.has_nothing_to_upload &&
          (values.items.length === 0 ||
            values.items.some(item => !item.is_valid)) &&
          'no valid report data to import',
      ]
    }

    case AUTH.CHANGE_PASSWORD: {
      return [
        !isValid.password(values.old_password) && 'current password',
        !isValid.password(values.new_password) && 'new password',
        values.new_password === values.old_password &&
          'passwords should be different',
      ]
    }

    case AUTH.SIGNIN: {
      if (process.env.NODE_ENV === 'development') {
        return [!isValid.email(values.email) && 'email']
      }

      return [
        !isValid.email(values.email) && 'email',
        !isValid.password(values.password) && 'password',
      ]
    }
    case AUTH.SIGNIN_NEW_PASSWORD_REQUIRED: {
      return [!isValid.password(values.password) && 'password']
    }
    case AUTH.SIGNUP: {
      return [
        !isValid.email(values.email) && 'email',
        !values.first_name && 'first name',
        !values.last_name && 'last name',
        !isValid.password(values.password) && 'password',
      ]
    }
    case AUTH.SIGNUP_CONFIRM: {
      return [!isValid.signupCode(values.code) && 'code']
    }
    case AUTH.RESET_PASSWORD: {
      return [!isValid.email(values.email) && 'email']
    }
    case AUTH.RESET_PASSWORD_CONFIRM: {
      return [
        !isValid.signupCode(values.code) && 'code',
        !isValid.password(values.password) && 'password',
      ]
    }

    case VERIFY_MEMBERSHIP: {
      return [
        !isValid.phoneNumber(values.phone_number) && 'phone number',
        !isValid.birthday(values.date_of_birth) && 'date of birth',
        !isValid.textInput(values.last_name) && 'member last name',
      ]
    }

    case DELETE.USER: {
      return [
        values.user.is_deleting_member &&
          !isValid.terminationDate(values.user.member.termination_date) &&
          'termination date',
        values.user.is_deleting_member &&
          !isValid.terminationReason(values.user.member.termination_reason) &&
          'termination reason',
        values.user.is_deleting_member &&
          !isValid.email(values.user.email) &&
          'email',
      ]
    }

    default: {
      if (process.env.NODE_ENV === 'development') {
        throw new Error(`getInvalidFields unreckognised formName "${formName}"`)
      }
      return []
    }
  }
}

export function isInvalid(formName, values, notify) {
  try {
    let list = getInvalidFields(formName, values).flat(Infinity).filter(Boolean)
    if (list.length > 0) {
      if (typeof notify === 'function') {
        notify(notifyInvalid(list, formName))
      }
      return true
    }
    return false
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error('isInvalid', error)
    }
    return true
  }
}

export let GRAPHQL_ERRORS = {
  NOT_UNIQUE:
    /Uniqueness violation. duplicate key value violates unique constraint "(.+?)"/i,
  NOT_NULL:
    /Not-NULL violation. null value in column "(.+?)" violates not-null constraint/i,
  FOREIGN_KEY:
    /Foreign key violation. update or delete on table "(.+?)" violates foreign key constraint "(.+?)" on table "(.+?)"/,
  NETWORK: 'NETWORK',
  UNKNOWN: 'UNKNOWN',
}

export function parseGraphqlError(combinedError) {
  let error = { type: GRAPHQL_ERRORS.UNKNOWN, message: null }
  // Hasura seems to be returning one error at the time

  try {
    if (combinedError.networkError) {
      error.type = GRAPHQL_ERRORS.NETWORK
      error.message = window.navigator.onLine
        ? `There was a problem contacting the server. Please try again later.`
        : `You seem to be offline. Please check your connection and try again.`
    } else if (combinedError.graphQLErrors.length > 0) {
      let [first] = combinedError.graphQLErrors

      if (GRAPHQL_ERRORS.NOT_UNIQUE.test(first.message)) {
        let [, key] = first.message.match(GRAPHQL_ERRORS.NOT_UNIQUE)
        error.type = GRAPHQL_ERRORS.NOT_UNIQUE
        error.key = key

        let [table, ...column] = key.replace(/_key$/, '').split('_')
        error.table = table
        error.column = column.join('_')
        error.columnReadable = column.join(' ')

        error.message = `A ${table.replace(/s$/, '')} with that ${
          error.columnReadable
        } already exists. Change the ${error.columnReadable} or cancel.`
      } else if (GRAPHQL_ERRORS.NOT_NULL.test(first.message)) {
        let [, column] = first.message.match(GRAPHQL_ERRORS.NOT_NULL)
        error.type = GRAPHQL_ERRORS.NOT_NULL
        error.column = column
        error.columnReadable = column.split('_').join(' ')
      } else if (GRAPHQL_ERRORS.FOREIGN_KEY.test(first.message)) {
        let [, tableSource, key, tableKey] = first.message.match(
          GRAPHQL_ERRORS.FOREIGN_KEY
        )
        error.type = GRAPHQL_ERRORS.FOREIGN_KEY
        error.key = key
        error.tableSource = tableSource
        error.tableKey = tableKey
      }
    }
  } catch (error) {
    console.error('Failed to parse error', error, combinedError)
  }

  return error
}
