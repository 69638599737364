import { ADD } from 'Data/constants.js';
import { isInvalid } from 'utils/graphQlHelpers.js';
import { useData } from 'Data/ViewsData.js';
import View from './view.js';
import React, { useMemo } from 'react';

export default function Logic() {
  let data = useData({
    context: 'company',
    path: 'company',
  });

  let isDisabled = useMemo(() => {
    let company_doing_business_as = data.value.company_doing_business_as.map(
      ({ name }) => ({ name })
    );
    if (company_doing_business_as.length === 0) {
      company_doing_business_as = [{ name: data.value.legal_name }];
    }

    return isInvalid(ADD.COMPANY, {
      company: {
        ...data.value,
        company_doing_business_as,
      },
    });
  }, [data.value]);
  return <View isDisabled={isDisabled} />;
}
