import { useData } from 'Data/ViewsData.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  let dataMember = useData({
    context: 'member',
    path: 'member.useExistingUser',
  });
  return <View isExistingUser={dataMember.value} />;
}
