import { useActAsSupport } from 'Logic/ActAsSupport.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  let [value, onClick] = useActAsSupport();
  let setFlowTo = useSetFlowTo();

  return (
    <View
      value={value}
      onClick={(next) => {
        onClick(next);

        // TODO fix this, the flow gets unmounted, that's why we need to re-set
        // it but this hack isn't very good. Maybe when we have URLs it will
        // help?
        setTimeout(() => {
          setFlowTo('/App/MyAccount/Content/Profile/Content/UserProfile');
        }, 50);
      }}
    />
  );
}
