import { ADD, INVITE } from 'Data/constants.js';
import { DataProvider } from 'Data/ViewsData.js';
import { isInvalid, parseGraphqlError } from 'utils/graphQlHelpers.js';
import { useAuth } from 'Data/Auth.js';
import { useSelected } from 'Data/Selected.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import { useMutation, useQuery } from 'Data/Api.js';
import {
  useNotifications,
  notifyError,
  notifySuccess,
} from 'utils/useNotifications.js';
import View from './view.js';
import ensureValuesAreCurrency from 'Data/ensureValuesAreCurrency.js';
import get from 'lodash/get';
import gql from 'graphql-tag';
import React from 'react';

// TODO refactor together with insert-company-broker-id event to derive the
// broker_id from the user that creates the company - if it is a user of a
// broker company, then we assign that broker, otherwise we assign the top level
// one
let query = gql`
  query get_broker_and_plans($user_id: uuid!) {
    companies(
      limit: 1
      where: {
        company_agreements: { type: { _eq: "Broker" } }
        _or: [
          { broker_id: { _is_null: true } }
          { company_users: { user_id: { _eq: $user_id } } }
        ]
      }
      order_by: { broker_id: asc_nulls_last }
    ) {
      id
    }
    # we need this for now to initialise the contributions to empty objects
    plans {
      id
    }
  }
`;

let COMPANY_MUTATION = gql`
  mutation insert_company($object: companies_insert_input!) {
    insert_companies(objects: [$object]) {
      returning {
        id
        financial_email
        hr_email
        legal_name
        federal_tax_id_number
        primary_industry_sic_code
        effective_date
        expiry_date
        physical_address_id
        phone_number
        company_doing_business_as {
          id
        }
        company_agreements {
          id
          type
          has_accepted_agreement
        }
      }
    }
  }
`;

let NEW_ITEM = {
  company: {
    legal_name: '',
    financial_email: '',
    hr_email: '',
    company_doing_business_as: [],
    phone_number: '',
    federal_tax_id_number: '',
    primary_industry_sic_code: '',
    physical_address: {
      street: '',
      city: '',
      state: '',
      zip: '',
    },
    effective_date: '',
    isProvider: false,
    isMemberGroup: true,
    isContractAccepted: false,
    national_provider_id: '',
    business_type: '',
    member_group_contributions: [
      {
        contribution: {
          one_person: '0.00',
          two_people: '0.00',
          three_people: '0.00',
          four_people: '0.00',
          five_people_or_more: '0.00',
        },
      },
      {
        contribution: {
          one_person: '0.00',
          two_people: '0.00',
          three_people: '0.00',
          four_people: '0.00',
          five_people_or_more: '0.00',
        },
      },
    ],

    useExistingUser: false,
    user_id: null,
    user: {
      email: '',
      phone_number: null,
      profile: {
        first_name: '',
        last_name: '',
      },
    },
    // used by InviteNewAdmin/ExistingUsers
    companies_ids: [],
    role: 'company-admin',
  },
};

export default function Logic() {
  let [, notify] = useNotifications();
  let [selected, select] = useSelected();
  let auth = useAuth();
  let setFlowTo = useSetFlowTo();
  let story = '/App/MyAccount/Content/Profile/Content/MyCompanies';

  let [{ fetching, error, data }] = useQuery({
    query,
    variables: { user_id: auth.data.userId },
  });
  let [
    { data: dataInsertCompanyMutation },
    executeMutationInsertCompany,
  ] = useMutation(COMPANY_MUTATION);

  if (fetching) return null;
  if (error) return `😱 ${error.message}`;

  return (
    <DataProvider context="company" onSubmit={onSubmit} value={NEW_ITEM}>
      <View
        companyId={get(
          dataInsertCompanyMutation,
          'insert_companies.returning[0].id',
          null
        )}
      />
    </DataProvider>
  );

  async function onSubmit(next, shouldSignAgreement = true) {
    let company_doing_business_as = next.company.company_doing_business_as.map(
      ({ name }) => ({ name })
    );
    if (company_doing_business_as.length === 0) {
      company_doing_business_as = [{ name: next.company.legal_name }];
    }
    if (
      isInvalid(
        ADD.COMPANY,
        { company: { ...next.company, company_doing_business_as } },
        notify
      )
    ) {
      return true;
    }

    if (
      !shouldSignAgreement &&
      isInvalid(INVITE.ADMIN_TO_SIGN, next.company, notify)
    ) {
      return true;
    }

    // Because brokers, users, and members require a company id, we must first
    // create a company, and then use it's newly generated id to make a second mutation
    let mutationResponse = await executeMutationInsertCompany({
      object: {
        financial_email: next.company.financial_email,
        hr_email: next.company.isMemberGroup ? next.company.hr_email : null,
        federal_tax_id_number: next.company.federal_tax_id_number,
        legal_name: next.company.legal_name,
        phone_number: next.company.phone_number,
        effective_date: next.company.isMemberGroup
          ? next.company.effective_date
          : null,
        primary_industry_sic_code: next.company.primary_industry_sic_code,
        physical_address: {
          data: {
            street: next.company.physical_address.street,
            city: next.company.physical_address.city,
            state: next.company.physical_address.state,
            zip: next.company.physical_address.zip,
          },
        },
        company_users: {
          data: [
            // if user is broker, don't add the user as an admin to the company
            !auth.permissions.isBroker && {
              role: 'company-admin',
              user_id: auth.data.userId,
            },
            !shouldSignAgreement &&
              !next.company.useExistingUser && {
                role: 'company-admin',
                user: {
                  data: {
                    email: next.company.user.email,
                    phone_number: next.company.user.phone_number,
                    profile: {
                      data: {
                        first_name: next.company.user.profile.first_name,
                        last_name: next.company.user.profile.last_name,
                      },
                    },
                  },
                },
              },
            !shouldSignAgreement &&
              next.company.useExistingUser && {
                role: 'company-admin',
                user_id: next.company.user_id,
              },
          ].filter(Boolean),
        },
        company_doing_business_as: {
          data: company_doing_business_as,
        },
        broker_id: get(data, 'companies[0].id', null),
        national_provider_id: next.company.isProvider
          ? next.company.national_provider_id
          : null,
        company_agreements: {
          data: [
            next.company.isProvider && {
              type: 'Provider',
            },
            next.company.isMemberGroup && {
              type: 'MemberGroup',
            },
          ].filter(Boolean),
        },
        business_type: next.company.business_type,
        member_group_contributions: {
          data: data.plans.map((item, index) => ({
            plan_id: item.id,
            contribution: ensureValuesAreCurrency(
              next.company.member_group_contributions[index].contribution
            ),
          })),
        },
      },
    });

    if (mutationResponse.error) {
      notify(notifyError(parseGraphqlError(mutationResponse.error).message));
    } else {
      let companyId = get(
        mutationResponse,
        'data.insert_companies.returning[0].id'
      );

      // TODO we need it to make sure the list gets updated until we address the cache
      // await executeMutationCompanyTouchHack({
      //   id: companyId,
      //   object: {
      //     legal_name: next.company.legal_name,
      //   },
      // });

      // TODO
      // this isn't enough to get the companyId through to the signature due to a
      // timing issue, so we're passing it as a prop down there to make it simpler
      // for now, we'll want to revisit it later
      // FIXME it seems that when I add a few companies in a row something gets
      // broken
      select({
        companyId,
        companies_ids: [companyId, ...selected.companies_ids],
        nothing: Date.now(),
      });

      await auth.refreshToken();

      if (shouldSignAgreement) {
        setFlowTo(`${story}/AddItem/Content/Signature`);
      } else {
        setFlowTo(`${story}/AddItem/No`);
      }

      notify(
        notifySuccess(`${next.company.legal_name} was successfully added!`)
      );
    }
  }
}
