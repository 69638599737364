import { DataProvider } from 'Data/ViewsData.js';
import { useSelected } from 'Data/Selected.js';
import { useQuery } from 'Data/Api.js';
import gql from 'graphql-tag';
import React from 'react';
import View from './view.js';

let query = gql`
  query get_member($id: uuid!) {
    member: members_by_pk(id: $id) {
      id
      policy_id
      status
      effective_date
      termination_date
      termination_reason
      created_at
      updated_at
      plan_cost_total
      plan_cost_member
      plan_cost_employer
      plan_members_count
      company {
        id
        expiry_date
        effective_date
      }
      plan {
        id
        name
      }
      user {
        id
        phone_number
        email
      }
      profile {
        id
        first_name
        last_name
        date_of_birth
        marital_status
        social_security_number
        address {
          id
          street
          city
          state
          zip
        }
      }
      provider_location {
        id
        doing_business_as_and_address
      }
      professional {
        id
        full_name_and_license
      }
      related_members_aggregate {
        aggregate {
          count
        }
      }
      related_members(
        order_by: { profile: { last_name: asc, first_name: asc } }
      ) {
        id
        effective_date
        main_member_id
        relationship_to_main_member
        status
        can_act_as_main_member
        provider_location {
          id
          doing_business_as_and_address
        }
        professional {
          id
          full_name_and_license
        }
        user {
          id
          email
        }
        profile {
          id
          first_name
          last_name
          date_of_birth
          gender
          social_security_number
        }
      }
    }
  }
`;

export default function Logic() {
  let [selected] = useSelected();
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: { id: selected.memberId },
    pollInterval: 1000,
  });

  if (fetching) return null;
  if (error) return `😱 ${error.message}`;

  return (
    <DataProvider context="member" value={data}>
      <View />
    </DataProvider>
  );
}
