import View from './view.js';
import React from 'react';
import useSetFlowToBasedOnData from 'Data/useSetFlowToBasedOnData.js';

export default function Logic() {
  useSetFlowToBasedOnData({
    context: 'employees_import',
    path: 'employees_import.employees',
    isContent: (value) =>
      value.length > 0 && value.every((item) => item.is_valid),
    story:
      '/App/MyAccount/Content/Profile/Content/Employees/AddManyItems/Content/ImportAction',
  });

  return <View />;
}
