import { downloadCsv } from 'utils/csv.js';
import { useData } from 'Data/ViewsData.js';
import { useQuery } from 'Data/Api.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import { dateISOIn, formatDate } from 'utils/dateHelpers.js';
import format from 'number-format.js';
import gql from 'graphql-tag';
import View from './view.js';
import React, { useEffect } from 'react';
import toSlugCase from 'to-slug-case';

let query = gql`
  query get_utilization_report_data($id: uuid!) {
    utilization_report: utilization_reports_by_pk(id: $id) {
      id
      company {
        legal_name
      }
      reported_month
      data
    }
  }
`;

let fields = [
  'Company Name',
  'Reported Month',
  'Member Id',
  'Member First Name',
  'Member Last Name',
  'Member DOB',
  'Date of Treatment',
  'Treatment Location',
  'Procedure Code',
  'Procedure Description',
  'Fee Charged',
];

function processData(utilization_report) {
  let data = utilization_report.data.map((item) => {
    return [
      utilization_report.company.legal_name,
      dateISOIn(utilization_report.reported_month),
      item.member_id,
      item.member_first_name,
      item.member_last_name,
      dateISOIn(item.member_dob),
      dateISOIn(item.date_of_treatment),
      item.treatment_location,
      item.procedure_code,
      item.procedure_description,
      format('$#,##0.00', item.fee_charged),
    ];
  });
  downloadCsv({
    name: `direct-dental-care-utilization-report-${toSlugCase(
      utilization_report.company.legal_name
    )}-${formatDate(utilization_report.reported_month, 'iso', 'yyyy-MM')}.csv`,
    fields,
    data,
  });
}

export default function Logic(props) {
  let dataUtilizationReport = useData({
    context: 'utilization_report',
    path: 'utilization_report.id',
  });
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: {
      id: dataUtilizationReport.value,
    },
  });
  let setFlowTo = useSetFlowTo();

  // let story =
  //   '/App/MyAccount/Content/Profile/Content/Utilization/ListItems/DownloadCSV';
  useEffect(() => {
    if (error) {
      props.onClickSetFlowFailure();
      // setFlowTo(`${story}/Failure`);
    } else {
      if (!fetching && data) {
        // Process the data
        try {
          processData(data.utilization_report);
          // setFlowTo(`${story}/Done`);
          props.onClickSetFlowDone();
        } catch (e) {
          console.error(e);
          props.onClickSetFlowFailure();
          // setFlowTo(`${story}/Failure`);
        }
      }
    }
  }, [/*story, */ setFlowTo, data, fetching, error]); // eslint-disable-line
  // ignore props

  return <View />;
}
