import { isBroker, useAuth } from 'Data/Auth.js';
import { useSelected } from 'Data/Selected.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import React from 'react';
import View from './view.js';

export default function Logic(props) {
  let auth = useAuth();
  let [, select] = useSelected();
  let setFlowTo = useSetFlowTo();

  let company = null;
  if (auth.permissions.isBrokerAdmin) {
    company = auth.data.companies
      .filter(isBroker)
      .find((item) =>
        item.company_agreements.some((aitem) => !aitem.has_accepted_agreement)
      );
  } else if (auth.permissions.isAdmin) {
    company = auth.data.companies.find((item) =>
      item.company_agreements.some((aitem) => !aitem.has_accepted_agreement)
    );
  }
  let hasToAcceptAndSignAgreement = !!company;

  return (
    <View
      {...props}
      {...auth.permissions}
      hasToAcceptAndSignAgreement={hasToAcceptAndSignAgreement}
      isEmployerAdmin={
        auth.permissions.isMemberGroupAdmin && !auth.permissions.isProviderAdmin
      }
      onClickAcceptAndSignNextAgreement={() => {
        select({ companyId: company.id });
        setFlowTo(
          '/App/MyAccount/Content/Profile/Content/MyCompanies/AddItem/Content/Signature'
        );
      }}
    />
  );
}
