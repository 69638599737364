import { useData } from 'Data/ViewsData.js';
import { useQuery } from 'Data/Api.js';
import View from './view.js';
import gql from 'graphql-tag';
import React from 'react';

let query = gql`
  query get_provider_locations {
    provider_locations {
      id
      address {
        id
        street
        city
        state
        zip
      }
      company_doing_business_as {
        id
        name
      }
    }
  }
`;

// TODO review this and the parent query
export default function Logic() {
  let dataProfessional = useData({
    context: 'professional',
    path: 'professional.professional_locations',
  });
  let [{ fetching, error, data }] = useQuery({ query });

  if (fetching) return null;
  if (error) return `😱 ${error.message}`;

  return data.provider_locations.map((item) => {
    let isSelected = dataProfessional.value.some(
      (litem) => litem.provider_location.id === item.id
    );

    return (
      <View
        id={item.id}
        key={item.id}
        isSelected={isSelected}
        onClick={(id) => {
          dataProfessional.onChange(
            isSelected
              ? dataProfessional.value.filter(
                  (item) => item.provider_location.id !== id
                )
              : [
                  ...dataProfessional.value,
                  { provider_location_id: id, provider_location: item },
                ]
          );
        }}
        value={`${item.company_doing_business_as.name} - ${item.address.street}, ${item.address.city}, ${item.address.state}, ${item.address.zip}`}
      />
    );
  });
}
