import React from 'react';
import View from './view.js';
import useCapture from 'useCapture.js';

let InputAnimatedLabelLogic = React.forwardRef((props, ref) => {
  let capture = useCapture(props);

  return (
    <View {...props} {...capture} onChange={props.onChange} innerRef={ref} />
  );
});

export default InputAnimatedLabelLogic;
