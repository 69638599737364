import { useData } from 'Data/ViewsData.js';
import View from './view.js';
import get from 'lodash/get';
import React from 'react';

export default function Logic() {
  let dataCompany = useData({
    context: 'company',
    path: 'company.company_doing_business_as',
  });

  return dataCompany.value.map((dba, index) => {
    let canDelete =
      get(dba, 'provider_locations_aggregate.aggregate.count', 0) === 0;

    return (
      <View
        key={dba.id}
        onChange={(value) => {
          dataCompany.onChange(
            value,
            `company.company_doing_business_as[${index}].name`
          );
        }}
        onSubmit={dataCompany.onSubmit}
        canDelete={canDelete}
        onClickDelete={() => {
          if (!canDelete) return;

          dataCompany.onChange(
            dataCompany.value.filter((item) => item.id !== dba.id)
          );
        }}
        value={dba.name}
      />
    );
  });
}
