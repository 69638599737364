import { useCompanies } from 'Logic/useFilterByCompanies.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  let companies = useCompanies({
    type: 'Provider',
    has_accepted_agreement: true,
  });
  return <View from={companies} />;
}
