import { useData } from 'Data/ViewsData.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  let dataCompany = useData({
    context: 'company',
    path: 'company.company_agreements',
  });

  return (
    <View
      isSelected={dataCompany.value.some((item) => item.type === 'Provider')}
    />
  );
}
