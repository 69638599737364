import { DataProvider } from 'Data/ViewsData.js';
import { textInput as isValidTextInput } from 'Data/validate.js';
import { useMutation } from 'Data/Api.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import View from './view.js';
import gql from 'graphql-tag';
import React from 'react';

let ITEM = {
  feedback: {
    message: '',
    app: process.env.REACT_APP_NAME,
  },
};

let MUTATION = gql`
  mutation feedback($feedback: feedback_insert_input!) {
    insert_feedback(objects: [$feedback]) {
      affected_rows
    }
  }
`;

let context = {
  fetchOptions: {
    headers: {
      'x-hasura-role': 'public',
    },
  },
};

export default function Logic() {
  let setFlowTo = useSetFlowTo();
  let [, executeMutation] = useMutation(MUTATION);

  return (
    <DataProvider context="feedback" value={ITEM} onSubmit={onSubmit}>
      <View />
    </DataProvider>
  );

  async function onSubmit(next) {
    if (!isValidTextInput(next.feedback.message)) return;

    let mutationResponse = await executeMutation(
      { feedback: next.feedback },
      context
    );

    setFlowTo(
      mutationResponse.error
        ? '/App/RuntimeError/Error'
        : '/App/Auth/GetHelp/HelpMessageReceived'
    );
  }
}
