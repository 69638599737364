import { AUTH } from 'Data/constants.js';
import { DataProvider, useData } from 'Data/ViewsData.js';
import { isInvalid } from 'utils/graphQlHelpers.js';
import { notifyError, useNotifications } from 'utils/useNotifications.js';
import { useAuth } from 'Data/Auth.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import View from './view.js';
import React from 'react';

let ITEM = {
  reset_password: {
    email: '',
  },
};

export default function Logic() {
  let [, notify] = useNotifications();
  let auth = useAuth();
  let dataAuthEmail = useData({ context: 'auth', path: 'auth.email' });
  let setFlowTo = useSetFlowTo();

  return (
    <DataProvider context="reset_password" value={ITEM} onSubmit={onSubmit}>
      <View />
    </DataProvider>
  );

  async function onSubmit(next) {
    if (isInvalid(AUTH.RESET_PASSWORD, next.reset_password, notify))
      return true;

    try {
      dataAuthEmail.onChange(next.reset_password.email);
      await auth.resetPassword(next.reset_password);
      setFlowTo('/App/Auth/ResetPasswordConfirm');
    } catch (error) {
      console.error(error);
      notify(notifyError('Something went wrong'));
    }
  }
}
