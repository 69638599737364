import { useData } from 'Data/ViewsData.js'
import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'utils/useNotifications.js'
import { parseGraphqlError } from 'utils/graphQlHelpers.js'
import gql from 'graphql-tag'
import View from './view.js'
import React from 'react'

export default function Logic(props) {
  let [, notify] = useNotifications()

  let dataMembers = useData({
    context: 'member',
    path: 'member.related_members',
  })

  let dataMember = useData({
    context: 'related_member',
    path: 'related_member',
  })

  let [, executeMutationRemoveDependent] = useMutation(
    dataMember?.user?.id ? MUTATION_DELETE_WITH_USER : MUTATION_DELETE
  )

  async function onClick() {
    if (
      window.confirm(
        `⚠️ Deleting dependent ${dataMember.value.profile.first_name} ${dataMember.value.profile.last_name}. \n\n Are you sure you wish to proceed?`
      )
    ) {
      let mutationResponse = await executeMutationRemoveDependent({
        member_id: dataMember.value.id,
        profile_id: dataMember.value.profile.id,
        user_id: dataMember.value?.user?.id,
      })

      if (mutationResponse.error) {
        notify(notifyError(parseGraphqlError(mutationResponse.error).message))
        return true
      }

      dataMembers.onChange(next => {
        next.member.related_members = next.member.related_members.filter(
          item => item.id !== dataMember.value.id
        )
      })

      notify(
        notifySuccess(
          `${dataMember.value.profile.first_name} ${dataMember.value.profile.last_name} was successfully deleted!`
        )
      )
    }
  }

  return props.isNew ? null : <View onClick={onClick} />
}

let MUTATION_DELETE_WITH_USER = gql`
  mutation ($member_id: uuid!, $user_id: uuid!, $profile_id: uuid!) {
    delete_members_by_pk(id: $member_id) {
      id
    }
    delete_notifications(where: { target_id: { _eq: $user_id } }) {
      returning {
        id
      }
    }
    delete_users_by_pk(id: $user_id) {
      id
    }
    delete_profiles_by_pk(id: $profile_id) {
      id
    }
  }
`

let MUTATION_DELETE = gql`
  mutation ($member_id: uuid!, $profile_id: uuid!) {
    delete_members_by_pk(id: $member_id) {
      id
    }
    delete_profiles_by_pk(id: $profile_id) {
      id
    }
  }
`
