import View from './view.js';
import React from 'react';
import useSetFlowToBasedOnData from 'Data/useSetFlowToBasedOnData.js';

export default function Logic() {
  useSetFlowToBasedOnData({
    context: 'company',
    path: 'company.company_agreements',
    story:
      '/App/MyAccount/Content/Profile/Content/MyCompanies/Item/Content/Show/UtilizationReportUser',
    isContent: (value) => value.some((item) => item.type === 'Provider'),
  });

  return <View />;
}
