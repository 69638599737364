import React, { useEffect } from 'react';
import View from './view.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';

export default function Logic() {
  let setFlowTo = useSetFlowTo();

  useEffect(() => {
    setTimeout(() => {
      setFlowTo(`/App/Auth/SignIn`);
    }, 2000);
  }, []); // eslint-disable-line

  return <View />;
}
