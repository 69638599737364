import { useData } from 'Data/ViewsData.js';
import { formatNumber } from 'Data/format.js';
import View from './view.js';
import React from 'react';

export default function Logic(props) {
  let data = useData({
    context: 'utilization_report_import_item',
    path: 'utilization_report_import_item.fee_charged',
  });

  return (
    <View
      isHovered={props.isHovered}
      value={formatNumber(data.value, '$#,##0.00')}
    />
  );
}
