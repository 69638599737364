import { useData } from 'Data/ViewsData.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  let data = useData({
    context: 'company',
    path: 'company.useExistingUser',
  });
  return <View isExistingUser={data.value} />;
}
