import { useEffect, useRef } from 'react'
import { useFlowState } from '../Logic/ViewsFlow.js'
import { useTrack } from './Track.js'

export function useTrackFlow() {
  let track = useTrack()
  let flowState = useFlowState()
  let prevFlow = useRef(null)
  useEffect(() => {
    if (prevFlow.current) {
      track('action', flowState.actions[0])
    }
    prevFlow.current = flowState.actions
  }, [flowState.actions]) // eslint-disable-line
  // track will not change and therefore does not need to be a dependency
}
