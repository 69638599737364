import View from './view.js';
import React from 'react';
import useSetFlowToBasedOnData from 'Data/useSetFlowToBasedOnData.js';

export default function Logic() {
  useSetFlowToBasedOnData({
    context: 'employees_import',
    path: 'employees_import.employees',
    story:
      '/App/MyAccount/Content/Profile/Content/Employees/AddManyItems/Content/Data/Verify/InvalidData',
    isContent: (list) => list.some((item) => !item.is_valid),
  });

  return <View />;
}
