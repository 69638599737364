import { useData } from 'Data/ViewsData.js';
import View from './view.js';
import React, { useMemo } from 'react';

export default function Logic(props) {
  let dataCompany = useData({
    context: 'company',
    path: 'company.company_agreements',
  });

  let isMemberGroup = useMemo(
    () => dataCompany.value.some((item) => item.type === 'MemberGroup'),
    [dataCompany.value]
  ); // eslint-disable-line
  // ignore setFlowTo

  return <View isHovered={props.isHovered} isMemberGroup={isMemberGroup} />;
}
