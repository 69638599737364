import { useAuth } from 'Data/Auth.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import View from './view.js';
import React, { useLayoutEffect } from 'react';

export default function Logic() {
  let auth = useAuth();
  let setFlowTo = useSetFlowTo();

  useLayoutEffect(() => {
    setFlowTo(
      `/App/MyAccount/Content/Profile/Content/MyProfessionals/Item/Content/Show/DeleteAction/${
        auth.permissions.isAdmin ? 'Content' : 'No'
      }`
    );
  }, [setFlowTo, auth.permissions.isAdmin]);

  return <View />;
}
