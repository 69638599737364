import { DataProvider } from 'Data/ViewsData.js';
import { isInvalid, parseGraphqlError } from 'utils/graphQlHelpers.js';
import { ADD } from 'Data/constants.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import { NEW_USER } from 'Data/useExistingUser.js';
import { useMutation, useQuery } from 'urql';
import {
  useNotifications,
  notifyError,
  notifySuccess,
} from 'utils/useNotifications.js';
import { useSelected } from 'Data/Selected.js';
import gql from 'graphql-tag';
import View from './view.js';
import React, { useMemo } from 'react';

let story = '/App/MyAccount/Content/Profile/Content/Employees/AddItem';

let query = gql`
  query {
    plans {
      id
      name
      pricing
    }
  }
`;

let MUTATION_INSERT_MEMBER = gql`
  mutation insert_member($member: members_insert_input!) {
    insert_members(objects: [$member]) {
      returning {
        id
      }
    }
  }
`;
let MUTATION_INSERT_ADDRESS = gql`
  mutation insert_address($address: addresses_insert_input!) {
    insert_addresses(objects: [$address]) {
      returning {
        id
      }
    }
  }
`;
let MUTATION_INSERT_USER = gql`
  mutation insert_user($user: users_insert_input!) {
    insert_users(objects: [$user]) {
      returning {
        id
        profile_id
      }
    }
  }
`;

let MUTATION_UPDATE_ADDRESS = gql`
  mutation update_address($address: addresses_set_input!, $address_id: uuid!) {
    update_addresses(_set: $address, where: { id: { _eq: $address_id } }) {
      returning {
        id
      }
    }
  }
`;
let MUTATION_UPDATE_PROFILE = gql`
  mutation update_profile($profile: profiles_set_input!, $profile_id: uuid!) {
    update_profiles(_set: $profile, where: { id: { _eq: $profile_id } }) {
      returning {
        id
      }
    }
  }
`;
let MUTATION_UPDATE_USER = gql`
  mutation update_user($user: users_set_input!, $user_id: uuid!) {
    update_users(_set: $user, where: { id: { _eq: $user_id } }) {
      returning {
        id
      }
    }
  }
`;

export default function Logic() {
  let setFlowTo = useSetFlowTo();
  let [selected, select] = useSelected();
  let [, notify] = useNotifications();
  let [, executeMutationInsertMember] = useMutation(MUTATION_INSERT_MEMBER);
  let [, executeMutationInsertUser] = useMutation(MUTATION_INSERT_USER);
  let [, executeMutationInsertAddress] = useMutation(MUTATION_INSERT_ADDRESS);
  let [, executeMutationUpdateAddress] = useMutation(MUTATION_UPDATE_ADDRESS);
  let [, executeMutationUpdateProfile] = useMutation(MUTATION_UPDATE_PROFILE);
  let [, executeMutationUpdateUser] = useMutation(MUTATION_UPDATE_USER);
  let [{ fetching, error, data }] = useQuery({ query });
  let memberValue = useMemo(
    () => ({
      member: {
        company_id: selected.companies_ids[0] || null,
        useExistingUser: false,
        user: NEW_USER,
        effective_date: '',
        related_members: [],
      },
    }),
    [] // eslint-disable-line
  );

  if (fetching) return null;
  if (error) return `😱 ${error.message}`;

  return (
    <DataProvider context="plans" value={data}>
      <DataProvider context="member" onSubmit={onSubmit} value={memberValue}>
        <View />
      </DataProvider>
    </DataProvider>
  );

  async function onSubmit(next) {
    if (isInvalid(ADD.MEMBER, next.member, notify, data.company)) return true;

    let related_members =
      next.member.related_members &&
      next.member.related_members.map((related) => ({
        relationship_to_main_member: related.relationship_to_main_member,
        plan_id: next.member.plan && next.member.plan.id,
        effective_date: next.member.effective_date,
        provider_location_id: related.provider_location_id,
        professional_id: related.professional_id,
        profile: {
          data: {
            first_name: related.profile.first_name,
            last_name: related.profile.last_name,
            gender: related.profile.gender,
            social_security_number: related.profile.social_security_number,
            date_of_birth: related.profile.date_of_birth,
          },
        },
      }));

    let member = {
      company_id: next.member.company_id,
      is_configuration_up_to_member: false,
      plan_id: next.member.plan && next.member.plan.id,
      effective_date: next.member.effective_date,
      provider_location_id: next.member.provider_location_id,
      professional_id: next.member.professional_id,
      related_members: { data: related_members },
    };

    if (next.member.useExistingUser) {
      member.user_id = next.member.user_id;
      member.profile_id = next.member.existingUser.profile.id;
    } else {
      let user = {
        user: {
          email: next.member.user.email,
          phone_number: next.member.user.phone_number,
          profile: {
            data: {
              first_name: next.member.user.profile.first_name,
              last_name: next.member.user.profile.last_name,
              date_of_birth: next.member.user.profile.date_of_birth,
              marital_status: next.member.user.profile.marital_status,
              gender: next.member.user.profile.gender,
              social_security_number:
                next.member.user.profile.social_security_number,
              address: {
                data: {
                  street: next.member.user.profile.address.street,
                  city: next.member.user.profile.address.city,
                  state: next.member.user.profile.address.state,
                  zip: next.member.user.profile.address.zip,
                },
              },
            },
          },
        },
      };
      let mutationResponseInsertUser = await executeMutationInsertUser(user);
      if (mutationResponseInsertUser.error) {
        notify(
          notifyError(
            parseGraphqlError(mutationResponseInsertUser.error).message
          )
        );
        return;
      }

      member.user_id =
        mutationResponseInsertUser.data.insert_users.returning[0].id;
      member.profile_id =
        mutationResponseInsertUser.data.insert_users.returning[0].profile_id;
    }
    let mutationResponseInsertMember = await executeMutationInsertMember({
      member,
    });
    if (mutationResponseInsertMember.error) {
      notify(
        notifyError(
          parseGraphqlError(mutationResponseInsertMember.error).message
        )
      );
      return true;
    }

    // this needs to happen afterwards otherwise the company-admin doesn't have
    // the permission to edit the address of the member's profile if it has to
    if (next.member.useExistingUser) {
      let profile = {
        date_of_birth: next.member.user.profile.date_of_birth,
        marital_status: next.member.user.profile.marital_status,
        gender: next.member.user.profile.gender,
        social_security_number: next.member.user.profile.social_security_number,
      };

      let mutationResponseUpdateUser = await executeMutationUpdateUser({
        user_id: member.user_id,
        user: {
          phone_number: next.member.user.phone_number,
        },
      });
      if (mutationResponseUpdateUser.error) {
        notify(
          notifyError(
            parseGraphqlError(mutationResponseUpdateUser.error).message
          )
        );
        return true;
      }

      if (next.member.existingUser.profile.address_id) {
        let mutationResponseUpdateAddress = await executeMutationUpdateAddress({
          address_id: next.member.existingUser.profile.address_id,
          address: {
            street: next.member.user.profile.address.street,
            city: next.member.user.profile.address.city,
            state: next.member.user.profile.address.state,
            zip: next.member.user.profile.address.zip,
          },
        });
        if (mutationResponseUpdateAddress.error) {
          notify(
            notifyError(
              parseGraphqlError(mutationResponseUpdateAddress.error).message
            )
          );
          return true;
        }
      } else {
        let mutationResponseInsertAddress = await executeMutationInsertAddress({
          address: {
            street: next.member.user.profile.address.street,
            city: next.member.user.profile.address.city,
            state: next.member.user.profile.address.state,
            zip: next.member.user.profile.address.zip,
          },
        });
        if (mutationResponseInsertAddress.error) {
          notify(
            notifyError(
              parseGraphqlError(mutationResponseInsertAddress.error).message
            )
          );
          return true;
        }

        profile.address_id =
          mutationResponseInsertAddress.data.insert_addresses.returning[0].id;
      }

      let mutationResponseUpdateProfile = await executeMutationUpdateProfile({
        profile_id: member.profile_id,
        profile,
      });
      if (mutationResponseUpdateProfile.error) {
        notify(
          notifyError(
            parseGraphqlError(mutationResponseUpdateProfile.error).message
          )
        );
        return true;
      }
    }

    select({ nothing: Date.now() });
    let user = next.member.existingUser || next.member.user;
    notify(
      notifySuccess(
        `${user.profile.first_name} ${user.profile.last_name} was successfully added!`
      )
    );
    setFlowTo(`${story}/No`);
  }
}
