import { useActAsSupport } from 'Logic/ActAsSupport.js'
import { useData } from 'Data/ViewsData.js'
import getValidEffectiveDates from 'Logic/getValidEffectiveDates.js'
import View from './view.js'
import formatDate from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import React, { useMemo } from 'react'

export default function Logic() {
  let [isActingAsSupport] = useActAsSupport()
  let dataMember = useData({ context: 'member', path: 'member' })

  let list = useMemo(() => {
    let list = getValidEffectiveDates(
      dataMember.value.company.effective_date,
      dataMember.value.company.expiry_date,
      isActingAsSupport
    )

    if (
      dataMember.value.original_effective_date &&
      !hasCobraDate(list, dataMember.value.original_effective_date)
    ) {
      list = [
        {
          id: dataMember.value.original_effective_date,
          text: `${formatDate(
            parseISO(dataMember.value.original_effective_date),
            'MM/dd/yyyy'
          )} (COBRA only)`,
        },
        ...list,
      ]
    }
    return list
  }, [
    isActingAsSupport,
    dataMember.value.company.effective_date,
    dataMember.value.company.expiry_date,
    dataMember.value.original_effective_date,
  ])

  return <View from={list} />
}

function hasCobraDate(list, cobraDate) {
  return list.some((item) => item.id === cobraDate)
}
