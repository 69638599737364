import { AUTH } from 'Data/constants.js';
import { DataProvider, useData } from 'Data/ViewsData.js';
import { isInvalid } from 'utils/graphQlHelpers.js';
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'utils/useNotifications.js';
import { useAuth } from 'Data/Auth.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import View from './view.js';
import React, { useMemo } from 'react';

export default function Logic() {
  let [, notify] = useNotifications();
  let auth = useAuth();
  let setFlowTo = useSetFlowTo();
  let dataAuth = useData({ context: 'auth', path: 'auth.email' });
  let value = useMemo(
    () => ({ signin: { email: dataAuth.value.email, password: '' } }),
    [] // eslint-disable-line
  );

  return (
    <DataProvider context="signin" value={value} onSubmit={onSubmit}>
      <View />
    </DataProvider>
  );

  async function onSubmit(next) {
    if (isInvalid(AUTH.SIGNIN, next.signin, notify)) return true;

    try {
      dataAuth.onChange(next.signin.email, 'auth.email');

      let res = await auth.signIn(next.signin);
      // https://aws-amplify.github.io/docs/js/authentication#sign-in
      switch (res.challengeName) {
        case 'MFA_SETUP':
        case 'SMS_MFA':
        case 'SOFTWARE_TOKEN_MFA': {
          notify(
            notifyError(`Multi-factor authentication isn't supported yet`)
          );
          return;
        }

        case 'NEW_PASSWORD_REQUIRED': {
          dataAuth.onChange(res, 'auth.user');
          setFlowTo('/App/Auth/SignInNewPasswordRequired');
          notify(notifySuccess(`Please, set a new password before logging in`));
          return;
        }

        default: {
          auth.getUserContext(res);
          return;
        }
      }
      // TODO logic to confirm signup if needed
      // either here or on Data/Auth
    } catch (error) {
      switch (error.code) {
        case 'UserNotConfirmedException': {
          dataAuth.onChange(next.signin.password, 'auth.password');
          notify(notifySuccess('Please, confirm your account'));
          setFlowTo('/App/Auth/SignUpConfirm');
          return;
        }

        case 'PasswordResetRequiredException': {
          notify(
            notifySuccess(`Please, reset your password before logging in`)
          );
          setFlowTo('/App/Auth/ResetPassword');
          return;
        }

        default: {
          return notify(notifyError('Please, verify your credentials'));
        }
      }
    }
  }
}
