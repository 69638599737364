import View from './view.js';
import React from 'react';

export default function Logic(props) {
  return (
    <form
      autoComplete="off"
      onSubmit={(event) => {
        event.preventDefault();
      }}
    >
      <View {...props} />
    </form>
  );
}
