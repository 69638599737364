import { DataProvider } from 'Data/ViewsData.js'
import { useMutation, useQuery } from 'Data/Api.js'
import { useActAsSupport } from 'Logic/ActAsSupport.js'
import { useSelected } from 'Data/Selected.js'
import { useSetFlowTo } from 'Logic/ViewsFlow.js'
import {
  useNotifications,
  notifyError,
  notifySuccess,
} from 'utils/useNotifications.js'
import { isInvalid, parseGraphqlError } from 'utils/graphQlHelpers.js'
import { EDIT } from 'Data/constants.js'
import ensureValuesAreCurrency from 'Data/ensureValuesAreCurrency.js'
import getChanges from 'Data/getChanges.js'
import gql from 'graphql-tag'
import React from 'react'
import View from './view.js'

let query = gql`
  query get_company($id: uuid!) {
    company: companies_by_pk(id: $id) {
      id
      active
      financial_email
      hr_email
      legal_name
      federal_tax_id_number
      phone_number
      primary_industry_sic_code
      national_provider_id
      business_type
      effective_date
      expiry_date
      company_agreements {
        id
        type
        has_accepted_agreement
      }
      physical_address {
        id
        street
        city
        state
        zip
      }
      broker {
        id
        legal_name
      }
      utilization_report_user_id
      utilization_report_user {
        id
        profile {
          id
          full_name
        }
      }
      company_doing_business_as {
        id
        name
        provider_locations_aggregate {
          aggregate {
            count
          }
        }
      }
      member_group_contributions(order_by: { plan: { name: asc } }) {
        contribution
        company_id
        plan_id
        plan {
          name
          id
        }
      }
    }
  }
`

let COMPANY_MUTATION = gql`
  mutation update_companies(
    $company_id: uuid!
    $company_update: companies_set_input
    $address_id: uuid!
    $address_update: addresses_set_input
    $company_doing_business_as_insert: [company_doing_business_as_insert_input!]!
    $company_doing_business_as_delete: company_doing_business_as_bool_exp!
    $member_group_contributions_basic: jsonb
    $member_group_contributions_enhanced: jsonb
  ) {
    update_companies(
      where: { id: { _eq: $company_id } }
      _set: $company_update
    ) {
      returning {
        id
        active
        financial_email
        hr_email
        legal_name
        federal_tax_id_number
        phone_number
        primary_industry_sic_code
        national_provider_id
        business_type
        effective_date
        expiry_date
        company_agreements {
          id
          type
          has_accepted_agreement
        }
        physical_address {
          id
          street
          city
          state
          zip
        }
        broker {
          id
          legal_name
        }
        utilization_report_user_id
        utilization_report_user {
          id
          profile {
            id
            full_name
          }
        }
        company_doing_business_as {
          name
          provider_locations_aggregate {
            aggregate {
              count
            }
          }
        }
        member_group_contributions(order_by: { plan: { name: asc } }) {
          contribution
          company_id
          plan_id
          plan {
            name
            id
          }
        }
      }
    }
    update_addresses(
      where: { id: { _eq: $address_id } }
      _set: $address_update
    ) {
      returning {
        id
        street
        city
        state
        zip
      }
    }
    insert_company_doing_business_as(
      objects: $company_doing_business_as_insert
    ) {
      returning {
        company {
          id
          company_doing_business_as {
            id
            name
            provider_locations_aggregate {
              aggregate {
                count
              }
            }
          }
        }
      }
    }
    delete_company_doing_business_as(where: $company_doing_business_as_delete) {
      returning {
        id
        name
        provider_locations_aggregate {
          aggregate {
            count
          }
        }
      }
    }
    basic: update_member_group_contributions(
      where: {
        company_id: { _eq: $company_id }
        plan: { name: { _eq: "Basic" } }
      }
      _set: { contribution: $member_group_contributions_basic }
    ) {
      returning {
        contribution
        plan_id
        company_id
      }
    }
    enhanced: update_member_group_contributions(
      where: {
        company_id: { _eq: $company_id }
        plan: { name: { _eq: "Enhanced" } }
      }
      _set: { contribution: $member_group_contributions_enhanced }
    ) {
      returning {
        contribution
        plan_id
        company_id
      }
    }
  }
`

let COMPANY_DOING_BUSINESS_AS_MUTATION = gql`
  mutation update_company_doing_business_as(
    $id: uuid!
    $company_doing_business_as: company_doing_business_as_set_input
  ) {
    update_company_doing_business_as(
      where: { id: { _eq: $id } }
      _set: $company_doing_business_as
    ) {
      returning {
        company {
          id
          company_doing_business_as {
            id
            name
            provider_locations_aggregate {
              aggregate {
                count
              }
            }
          }
        }
      }
    }
  }
`

export default function Logic() {
  // TODO it would be handy if we would export the story from the view and
  // reference it here in setFlowTo as Change.storyPath, it would remove the need
  // to keep references as strings
  let story = '/App/MyAccount/Content/Profile/Content/MyCompanies/Item/Content'
  let setFlowTo = useSetFlowTo()
  let [isActingAsSupport] = useActAsSupport()
  let [, notify] = useNotifications()
  let [selected] = useSelected()
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: {
      id: selected.companyId,
    },
  })
  let [, updateCompany] = useMutation(COMPANY_MUTATION)
  let [, updateCompanyDoingBusinessAs] = useMutation(
    COMPANY_DOING_BUSINESS_AS_MUTATION
  )

  if (fetching) return null
  if (error) return `😱 ${error.message}`

  return (
    <DataProvider context="company" onSubmit={onSubmit} value={data}>
      <View />
    </DataProvider>
  )

  async function onSubmit(next) {
    if (isInvalid(EDIT.COMPANY, next, notify)) return true

    let [
      company_doing_business_as_insert,
      company_doing_business_as_delete,
      company_doing_business_as_update,
    ] = getChanges({
      prev: data.company.company_doing_business_as,
      next: next.company.company_doing_business_as,
      pick: ['id', 'name'],
      entity: {
        key: 'company_id',
        value: data.company.id,
      },
    })

    let basic = ensureValuesAreCurrency(
      next.company.member_group_contributions[0].contribution
    )
    let enhanced = ensureValuesAreCurrency(
      next.company.member_group_contributions[1].contribution
    )

    let company_update = {
      business_type: next.company.business_type,
      financial_email: next.company.financial_email,
      hr_email: next.company.hr_email,
      phone_number: next.company.phone_number,
      legal_name: next.company.legal_name,
      federal_tax_id_number: next.company.federal_tax_id_number,
      primary_industry_sic_code: next.company.primary_industry_sic_code,
      national_provider_id: next.company.national_provider_id,
      utilization_report_user_id: next.company.utilization_report_user_id,
    }

    if (isActingAsSupport) {
      company_update.active = next.company.active
    }

    let mutationResponse = await Promise.all([
      updateCompany({
        company_id: data.company.id,
        company_update,
        member_group_contributions_basic: basic,
        member_group_contributions_enhanced: enhanced,
        // TODO what if the address didn't exist? we need to create it
        // don't remember if that's a case here, I think we need an address
        // when making the company, anyway, if that were the case we'd need to
        // use getChanges I suppose
        address_id: data.company.physical_address.id,
        address_update: {
          street: next.company.physical_address.street,
          city: next.company.physical_address.city,
          state: next.company.physical_address.state,
          zip: next.company.physical_address.zip,
        },
        company_doing_business_as_insert,
        company_doing_business_as_delete,
        // TODO swap delete_* for deleted_at field
        // TODO update deleted fields on query
      }),
      // we need to do it on a separate mutation because we need to change many
      // at once and hasura doesn't support multiple updates of unrelated fields
      ...company_doing_business_as_update.map(
        ([id, company_doing_business_as]) =>
          updateCompanyDoingBusinessAs({
            id,
            company_doing_business_as,
          })
      ),
    ])

    if (mutationResponse.some((mutation) => mutation.error)) {
      mutationResponse
        .filter((item) => item.error)
        .forEach((item) =>
          notify(notifyError(parseGraphqlError(item.error).message))
        )
    } else {
      notify(notifySuccess(`${next.company.legal_name} successfully updated`))
      setFlowTo(`${story}/Show`)
    }
  }
}
