import { useData } from 'Data/ViewsData.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import View from './view.js';
import React, { useEffect } from 'react';

export default function Logic() {
  let setFlowTo = useSetFlowTo();
  let dataSignature = useData({
    context: 'signature',
    path: 'signature.company',
  });

  useEffect(() => {
    let story = '/App/MyAccount/Content/Profile/Content/MyCompanies/AddItem';

    let agreementsLeftToAccept = dataSignature.value.company_agreements.filter(
      (item) => !item.has_accepted_agreement
    );

    if (agreementsLeftToAccept.length > 0) {
      let providerLeft = agreementsLeftToAccept.some(
        (item) => item.type === 'Provider'
      );
      let memberGroupLeft = agreementsLeftToAccept.some(
        (item) => item.type === 'MemberGroup'
      );

      let contract = null;
      if (providerLeft && memberGroupLeft) {
        contract = 'ProviderMemberGroupContract';
      } else if (providerLeft) {
        contract = 'ProviderContract';
      } else if (memberGroupLeft) {
        contract = 'MemberGroupContract';
      }

      story = contract
        ? `${story}/Content/Signature/TermsAndConditions/${contract}`
        : `${story}/No`;
    } else {
      story = `${story}/No`;
    }

    setFlowTo(story);
  }, []); // eslint-disable-line
  // ignore setFlowTo

  return <View />;
}
