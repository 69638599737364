import View from './view.js';
import React from 'react';
import useSetFlowToBasedOnData from 'Data/useSetFlowToBasedOnData.js';

export default function Logic() {
  useSetFlowToBasedOnData({
    context: 'utilization_report_import',
    path: 'utilization_report_import.has_nothing_to_upload',
    story:
      '/App/MyAccount/Content/Profile/Content/Utilization/Item/Content/Provide',
    isContent: (value) => !value,
  });

  return <View />;
}
