import { DataProvider } from 'Data/ViewsData.js'
import { DELETE } from 'Data/constants.js'
import { isInvalid, parseGraphqlError } from 'utils/graphQlHelpers.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'utils/useNotifications.js'
import { useSelected } from 'Data/Selected.js'
import { useSetFlowTo } from 'Logic/ViewsFlow.js'
import { useQuery, useMutation } from 'Data/Api.js'
import View from './view.js'
import isBefore from 'date-fns/isBefore'
import parseISO from 'date-fns/parseISO'
import gql from 'graphql-tag'
import React, { useMemo, useState } from 'react'

let query = gql`
  query get_member($id: uuid!) {
    member: members_by_pk(id: $id) {
      id
      user {
        id
        email
        profile {
          id
          first_name
          last_name
          address {
            id
          }
        }
        member {
          id
          effective_date
          termination_date
          termination_reason
          status
          related_members {
            id
            profile {
              id
              first_name
            }
          }
        }
        company_users {
          role
          company {
            created_by_user_id
            company_agreements {
              accepted_agreement_by_user_id
            }
            company_users_aggregate(where: { role: { _eq: "company-admin" } }) {
              aggregate {
                count
              }
            }
          }
        }
        professional {
          id
          members {
            id
          }
          professional_locations {
            provider_location {
              doing_business_as_and_address
              professional_locations_aggregate {
                aggregate {
                  count
                }
              }
              primary_care_professional_locations_aggregate: professional_locations_aggregate(
                where: {
                  professional: { license: { is_primary_care: { _eq: true } } }
                }
              ) {
                aggregate {
                  count
                }
              }
            }
          }
        }
      }
    }
  }
`

let MUTATION_TERMINATE_MEMBER = `
  update_members(
    where: {
      _or: [
        { user_id: { _eq: $user_id } }
        { main_member: { user_id: { _eq: $user_id } } }
      ]
    }
    _set: {
      termination_date: $termination_date,
      termination_reason: $termination_reason
    }
  ) {
    returning {
      id
      termination_date
    }
  }
  update_users(where: { id: { _eq: $user_id } }, _set: { email: $email }) {
    returning {
      id
      email
    }
  }
`

let MUTATION_TERMINATE_MEMBER_IMMEDIATELY = `
  update_members(
    where: {
      _or: [
        { user_id: { _eq: $user_id } }
        { main_member: { user_id: { _eq: $user_id } } }
      ]
    }
    _set: {
      termination_date: $termination_date,
      termination_reason: $termination_reason,
      status: "Inactive"
    }
  ) {
    returning {
      id
      termination_date
    }
  }
  update_users(where: { id: { _eq: $user_id } }, _set: { email: $email }) {
    returning {
      id
      email
    }
  }
`

let MUTATION_DELETE_MEMBER = `
  delete_members(where: { user_id: { _eq: $user_id } }) {
    affected_rows
  }
`

let MUTATION_DELETE_PROFESSIONAL = `
  # Unassign members from this professional, keeping them linked to the location
  update_members(
    where: { professional: { user_id: { _eq: $user_id } } }
    _set: { professional_id: null }
  ) {
    affected_rows
  }
  delete_professional_locations(
    where: { professional: { user_id: { _eq: $user_id } } }
  ) {
    affected_rows
  }
  delete_professionals(where: { user_id: { _eq: $user_id } }) {
    affected_rows
  }
`

let MUTATION_DELETE_COMPANY_USER = `
  delete_company_users(where: { user_id: { _eq: $user_id } }) {
    affected_rows
  }
`

// TODO when we terminate a member that has
// enrollment data but the termination date is less than the effective_date
// but has dependents, we should also be deleting the dependents profiles/users
let MUTATION_DELETE_USER_AND_PROFILE = `
  delete_notifications(
    where: {
      _or: [
        { target_id: { _eq: $user_id } }
        { source_id: { _eq: $user_id } }
      ]
    }
  ) {
    affected_rows
  }
  delete_users(where: { id: { _eq: $user_id } }) {
    affected_rows
  }
  delete_profiles(where: { id: { _eq: $profile_id } }) {
    affected_rows
  }
`

let MUTATION_DELETE_ADDRESS = `
  delete_addresses(where: { id: { _eq: $address_id } }) {
    affected_rows
  }
`

function shouldTerminateImmediately(termination_date) {
  let today = new Date()
  return isBefore(parseISO(termination_date), today)
    ? MUTATION_TERMINATE_MEMBER_IMMEDIATELY
    : MUTATION_TERMINATE_MEMBER
}

export default function Logic() {
  let setFlowTo = useSetFlowTo()
  let [, notify] = useNotifications()
  let [selected, select] = useSelected()
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: { id: selected.memberId },
  })
  let [deleting, setDeleting] = useState({
    member_terminate: true,
    member_fully: false,
    professional: false,
    company_user: false,
    user: false,
    termination_date: null,
    address: false,
  })

  let [, executeMutation] = useMutation(gql`
  mutation delete_user($user_id: uuid!, $termination_date: date, $termination_reason: String, $email: citext, $profile_id: uuid, $address_id: uuid) {
    ${
      deleting.member_terminate
        ? shouldTerminateImmediately(deleting.termination_date)
        : ''
    }
    ${deleting.member_fully ? MUTATION_DELETE_MEMBER : ''}
    ${deleting.professional ? MUTATION_DELETE_PROFESSIONAL : ''}
    ${deleting.company_user ? MUTATION_DELETE_COMPANY_USER : ''}
    ${deleting.user ? MUTATION_DELETE_USER_AND_PROFILE : ''}
    ${deleting.address ? MUTATION_DELETE_ADDRESS : ''}
    ${
      !deleting.member_terminate &&
      !deleting.member_fully &&
      !deleting.professional &&
      !deleting.company_user &&
      !deleting.user &&
      !deleting.address
        ? 'noop { empty_not_to_make_gql_fail }'
        : ''
    }
  }`)

  let value = useMemo(() => {
    if (!data) return data

    return {
      user: {
        ...data.member.user,
        is_deleting_member: true,
        is_deleting_admin: false,
        is_deleting_professional: false,
      },
    }
  }, [data])

  if (fetching) return null
  if (error) return `😱 ${error.message}`

  return (
    <DataProvider
      context="user"
      onChange={onChange}
      onSubmit={onSubmit}
      value={value}
    >
      <View />
    </DataProvider>
  )

  async function onSubmit(next) {
    if (isInvalid(DELETE.USER, next, notify)) return true

    let mutationResponse = await executeMutation({
      user_id: next.user.id,
      profile_id: next.user.profile.id,
      address_id: next.user.profile.address_id,
      termination_date: next.user.member.termination_date,
      termination_reason: next.user.member.termination_reason,
      email: next.user.email,
    })

    if (mutationResponse.error) {
      notify(notifyError(parseGraphqlError(mutationResponse.error).message))
    } else {
      let what = [
        next.user.is_deleting_member && 'benefit terminated',
        next.user.is_deleting_admin && 'admin access removed',
        next.user.is_deleting_professional &&
          `professional removed and relocated ${mutationResponse.data.update_members.affected_rows} members`,
      ]
        .filter(Boolean)
        .join(', ')

      notify(
        notifySuccess(
          `${next.user.profile.first_name} ${next.user.profile.last_name}'s ${what} successfully`
        )
      )

      select({ nothing: `${Date.now()}` })

      if (deleting.user) {
        setFlowTo('/App/MyAccount/Content/Profile/Content/Individuals/Item/No')
      } else {
        setFlowTo(
          '/App/MyAccount/Content/Profile/Content/Individuals/Item/Content/Show'
        )
      }
    }
  }

  function onChange(next, change) {
    if (
      next.user.is_deleting_member &&
      next.user.member.termination_date &&
      !next.user.member.dependents_termination_date
    ) {
      change((next) => {
        next.user.member.dependents_termination_date =
          next.user.member.termination_date
      })
    }

    let memberUserCanBeDeleted =
      next.user.is_deleting_member &&
      next.user.member.status === 'Invited' &&
      isBefore(
        parseISO(next.user.member.termination_date),
        parseISO(next.user.member.effective_date)
      )

    if (
      next.user.is_deleting_admin !== deleting.company_user ||
      next.user.is_deleting_member !== deleting.member ||
      memberUserCanBeDeleted ||
      next.user.is_deleting_professional !== deleting.professional
    ) {
      let user =
        (!next.user.member || memberUserCanBeDeleted) &&
        (!next.user.professional || next.user.is_deleting_professional) &&
        (next.user.company_users.length === 0 ||
          (next.user.is_deleting_admin &&
            next.user.company_users.every(
              (item) =>
                item.company.created_by_user_id !== next.user.id &&
                item.company.company_agreements.every(
                  (item) => item.accepted_agreement_by_user_id !== next.user.id
                )
            )))

      setDeleting({
        company_user: next.user.is_deleting_admin,
        member_terminate:
          next.user.is_deleting_member && !memberUserCanBeDeleted,
        member_fully: memberUserCanBeDeleted,
        professional: next.user.is_deleting_professional,
        user,
        termination_date: next.user.member.termination_date,
        address: user && next.user.profile.address_id,
      })
    }
  }
}
