import View from './view.js';
import React from 'react';
import useSetFlowToBasedOnData from 'Data/useSetFlowToBasedOnData.js';

export default function Logic() {
  useSetFlowToBasedOnData({
    context: 'member',
    path: 'member',
    isContent: (value) =>
      value.status === 'Inactive' || value.termination_date !== null,
    story:
      '/App/MyAccount/Content/Profile/Content/Individuals/Item/Content/Show/ActivateAction',
  });

  return <View />;
}
