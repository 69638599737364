import { DataProvider } from 'Data/ViewsData.js';
import { isInvalid } from 'utils/graphQlHelpers.js';
import { AUTH } from 'Data/constants.js';
import { useAuth } from 'Data/Auth.js';
import {
  useNotifications,
  notifyError,
  notifySuccess,
} from 'utils/useNotifications.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import React from 'react';
import View from './view.js';

let ITEM = {
  password: {
    old_password: '',
    new_password: '',
  },
};

export default function Logic() {
  let auth = useAuth();
  let setFlowTo = useSetFlowTo();
  let [, notify] = useNotifications();

  return (
    <DataProvider context="password" onSubmit={onSubmit} value={ITEM}>
      <View />
    </DataProvider>
  );

  async function onSubmit(next) {
    if (isInvalid(AUTH.CHANGE_PASSWORD, next.password, notify)) return true;

    try {
      await auth.changePassword(next.password);
      notify(notifySuccess(`You've changed your password`));
      setFlowTo('/App/MyAccount/Content/Profile/Content/UserProfile/Item/No');
    } catch (error) {
      notify(notifyError(error.message));
    }
  }
}
