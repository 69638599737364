import { useData } from 'Data/ViewsData.js'
import isAfter from 'date-fns/isAfter'
import parseISO from 'date-fns/parseISO'
import React from 'react'
import View from './view.js'

// the month argument is 0-based so 9 is October
let DATE_SWAP_MONTHS_TO_QUARTERS = new Date(2021, 9, 1)

export default function Logic(props) {
  let data = useData({
    context: 'utilization_report_import',
    path: 'utilization_report_import.utilization_report.reported_month',
    viewPath: props.viewPath,
  })
  let isReportedQuarter = isAfter(parseISO(data.value), DATE_SWAP_MONTHS_TO_QUARTERS)
  let isReportedMonth = !isReportedQuarter

  return (
    <View
      viewPath={props.viewPath}
      {...props}
      isReportedQuarter={isReportedQuarter}
      isReportedMonth={isReportedMonth}
    />
  )
}
