import { ADD } from 'Data/constants.js';
import { DataProvider } from 'Data/ViewsData.js';
import { isInvalid, parseGraphqlError } from 'utils/graphQlHelpers.js';
import { NEW_USER } from 'Data/useExistingUser.js';
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'utils/useNotifications.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import { useSelected } from 'Data/Selected.js';
import { useMutation } from 'Data/Api.js';
import View from './view.js';
import gql from 'graphql-tag';
import React, { useMemo } from 'react';

let MUTATION_INSERT_USER = gql`
  mutation insertUser($user: users_insert_input!) {
    insert_users(objects: [$user]) {
      returning {
        id
      }
    }
  }
`;

let MUTATION_INSERT_PROFESSIONAL = gql`
  mutation insertProfessional($professional: professionals_insert_input!) {
    insert_professionals(objects: [$professional]) {
      returning {
        id
      }
    }
  }
`;

export default function Logic() {
  let story = '/App/MyAccount/Content/Profile/Content/MyProfessionals/Item';
  let setFlowTo = useSetFlowTo();
  let [selected, select] = useSelected();
  let [, notify] = useNotifications();
  // TODO can we use only one mutation?
  let [, executeMutationInsertProfessional] = useMutation(
    MUTATION_INSERT_PROFESSIONAL
  );
  let [, executeMutationInsertUser] = useMutation(MUTATION_INSERT_USER);

  let valueProfessional = useMemo(
    () => ({
      professional: {
        companies_ids: selected.companies_ids,
        user: NEW_USER,
        give_access_as_user: false,
        useExistingUser: false,
        license: {
          name: '',
          id: '',
          default_age_min: '',
          default_age_max: '',
          is_primary_care: false,
          can_age_be_set_by_professional: false,
        },
        license_custom_age_max: '',
        license_custom_age_min: '',
        license_number: '',
        professional_locations: [],
        national_provider_id: '',
        is_accepting_new_patients: true,
      },
    }),
    [] // eslint-disable-line
  );

  return (
    <DataProvider
      context="professional"
      onSubmit={onSubmit}
      value={valueProfessional}
    >
      <View />
    </DataProvider>
  );

  async function onSubmit(next) {
    if (isInvalid(ADD.PROFESSIONAL, next.professional, notify)) return true;

    try {
      let user_id = next.professional.useExistingUser
        ? next.professional.user_id
        : null;
      if (!user_id) {
        let user = {
          phone_number: next.professional.user.phone_number,
          email: next.professional.user.email,
          profile: {
            data: {
              first_name: next.professional.user.profile.first_name,
              last_name: next.professional.user.profile.last_name,
            },
          },
        };

        if (next.professional.give_access_as_user) {
          user.company_users = {
            data: [
              ...new Set(
                next.professional.professional_locations.map(
                  (item) => item.provider_location.company_id
                )
              ),
            ].map((company_id) => ({
              company_id,
              role: 'company-user',
            })),
          };
        }

        let mutationResponseInsertUser = await executeMutationInsertUser({
          user,
        });
        if (mutationResponseInsertUser.error) {
          notify(
            notifyError(
              parseGraphqlError(mutationResponseInsertUser.error).message
            )
          );
          return true;
        } else {
          user_id =
            mutationResponseInsertUser.data.insert_users.returning[0].id;
        }
      }

      let professional = {
        is_accepting_new_patients: next.professional.is_accepting_new_patients,
        license_id: next.professional.license.id,
        license_number: next.professional.license_number,
        professional_locations: {
          data: next.professional.professional_locations.map(
            ({ provider_location_id }) => ({
              provider_location_id,
            })
          ),
        },
        user_id,
      };
      if (next.professional.license.can_age_be_set_by_professional) {
        professional.license_custom_age_max = next.professional
          .license_custom_age_max
          ? parseInt(next.professional.license_custom_age_max, 10)
          : null;
        professional.license_custom_age_min = next.professional
          .license_custom_age_min
          ? parseInt(next.professional.license_custom_age_min, 10)
          : null;
      }

      let mutationResponseInsertProfessional = await executeMutationInsertProfessional(
        { professional }
      );

      if (mutationResponseInsertProfessional.error) {
        notify(
          notifyError(
            parseGraphqlError(mutationResponseInsertProfessional.error).message
          )
        );
      } else {
        select({ nothing: Date.now() });
        notify(
          notifySuccess(
            `${next.professional.user.profile.first_name} ${next.professional.user.profile.last_name} was successfully added!`
          )
        );
        setFlowTo(`${story}/No`);
      }
    } catch (error) {
      notify(notifyError(error.message));
    }
  }
}
