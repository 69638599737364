import { useData } from 'Data/ViewsData.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  let dataCompanyUser = useData({
    context: 'company_user',
    path: 'company_user.useExistingUser',
  });
  return <View isExistingUser={dataCompanyUser.value} />;
}
