import { useAuth } from 'Data/Auth.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import React, { useLayoutEffect } from 'react';
import View from './view.js';

export default function Logic() {
  let auth = useAuth();
  let setFlowTo = useSetFlowTo();

  useLayoutEffect(() => {
    setFlowTo(
      `/App/MyAccount/Content/Profile/Content/UserProfile/Support/${
        auth.permissions.canBeSupport ? 'Content' : 'No'
      }`
    );
  }, [auth.permissions.canBeSupport]); // eslint-disable-line
  // ignore setFlowTo

  return <View />;
}
