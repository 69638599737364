import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import Text2Image from '@xg4/text2image';
import View from './view.js';
import SignaturePad from 'signature_pad';

let convertTextToImage = new Text2Image({
  fontSize: 32,
  color: '#161616',
  fontFamily: 'Caveat',
  fontWeight: '400',
  type: 'image/png',
  quality: 0.92,
});

async function convertDataUrlToBlob(dataURL) {
  let response = await fetch(dataURL);
  return await response.blob();
}

export default function Logic(props) {
  let signature = useRef(null);
  let [type, setType] = useState(props.initialType); // types: sign, write, upload
  let [dataWrite, setDataWrite] = useState('');
  let [dataDraw, setDataDraw] = useState(null);
  // let [dataPointsDraw, setDataPointsDraw] = useState(null);
  let [dataFileUpload, setFileUpload] = useState('');
  let [dataUpload, setDataUpload] = useState('');
  let [signatureCaptureKey, setSignatureCaptureKey] = useState(0);

  let canvas = useRef(null);

  let onEnd = () => {
    // setDataPointsDraw(signature.current.toData());
    setDataDraw(signature.current.toDataURL());
  };

  useLayoutEffect(() => {
    if (!canvas.current) return;

    signature.current = new SignaturePad(canvas.current, {
      penColor: props.penColor,
      onEnd,
    });
    // TODO load signature
    // (async () => {
    //   if (type !== 'sign') return;

    //   if (props.value && props.value.blob) {
    //     let dataUrl = await getBase64(props.value.blob);
    //     signature.current.fromDataURL(dataUrl);
    //     console.log('dataurl', dataUrl)
    //   } else if (dataDraw) {
    //     signature.current.fromData(dataPointsDraw);
    //   }
    // })();

    return () => {
      signature.current.off();
    };
  }, [canvas.current]); // eslint-disable-line
  // we are assigning the value of signature.current here, so we only care about changes in canvas

  useEffect(() => {
    if (typeof props.onChange !== 'function') return;

    (async () => {
      let change = {};
      switch (type) {
        case 'sign':
          change = {
            type: 'image/png',
            ext: 'png',
            blob: await convertDataUrlToBlob(dataDraw),
          };
          break;

        case 'write':
          try {
            let text = dataWrite.trim();
            if (text !== '') {
              change = {
                type: 'image/png',
                ext: 'png',
                blob: await convertDataUrlToBlob(
                  convertTextToImage.toDataURL(text)
                ),
              };
            }
          } catch (error) {}
          break;

        case 'upload':
          try {
            if (dataFileUpload) {
              change = {
                type: dataFileUpload.type,
                ext: dataFileUpload.type.split('/')[1],
                blob: dataFileUpload,
              };
            }
          } catch (error) {}
          break;

        default:
          break;
      }

      if (change) {
        props.onChange(change);
      }
    })();
  }, [dataWrite, dataDraw, dataUpload, type]); // eslint-disable-line
  // props.onChange is unlikely itself to change, so we don't need to account for it

  let toggleSignatureType = () => {
    setType(type === 'sign' ? 'write' : 'sign');
  };

  let setSignatureTypeUpload = () => {
    setType('upload');
  };

  let clear = () => {
    switch (type) {
      case 'sign':
        setDataDraw('');
        signature.current.clear();
        break;
      case 'write':
        setDataWrite('');
        break;
      case 'upload':
        setDataUpload('');
        setFileUpload('');
        setSignatureCaptureKey(signatureCaptureKey + 1);
        break;
      default:
        throw new Error(`wrong signature type provided ${type}`);
    }
  };

  let setUpload = async (value) => {
    setFileUpload(value);
    let result = await getBase64(value);
    setDataUpload(result);
  };

  async function getBase64(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
  }

  return (
    <View
      {...props}
      isSign={type === 'sign'}
      isWrite={type === 'write'}
      isUpload={type === 'upload'}
      toggleSignatureType={toggleSignatureType}
      setSignatureTypeUpload={setSignatureTypeUpload}
      setName={setDataWrite}
      setUpload={setUpload}
      signatureCaptureKey={signatureCaptureKey}
      canvas={canvas}
      clear={clear}
      dataWrite={dataWrite}
      dataUpload={dataFileUpload}
    />
  );
}

Logic.defaultProps = {
  initialType: 'sign',
  value: '',
};
// once issue with data being undefined is resolved we could remove the defaultProps safely
