import { FixedSizeList as List } from 'react-window'
import { DataProvider } from 'Data/ViewsData.js'
import { useQuery } from 'Data/Api.js'
import AutoSizer from 'react-virtualized-auto-sizer'
import gql from 'graphql-tag'
import View from './view.js'
import LoadingBar from 'DesignSystem/LoadingBar/logic.js'
import React from 'react'
import Info from 'DesignSystem/Info/view.js'

let query = gql`
  query {
    affiliates_referrals(order_by: { created_at: desc }) {
      id
      created_at
      affiliate {
        id
        code
      }
      referred_user {
        id
        email
        member {
          id
          updated_at
          policy_id
          status
        }
      }
    }
  }
`

// this number is a combination of the 70px of the height plus the 8px
// of the marginTop from the Card in ListItems.view
// until we find a better way to
let ROW_HEIGHT = 78

export default function Logic(props) {
  let [{ fetching, error, data }] = useQuery({
    query,
  })

  if (fetching)
    return (
      <LoadingBar
        alignSelf="flexStart"
        borderBottomLeftRadius={4}
        borderBottomRightRadius={4}
        borderTopLeftRadius={4}
        borderTopRightRadius={4}
        height={8}
        marginTop={20}
        width={200}
      />
    )
  if (error) return `😱 ${error.message}`

  if (data.affiliates_referrals.length === 0) {
    return <Info text="No affiliate referrals" />
  }

  function Row({ index, style }) {
    let item = data.affiliates_referrals[index]
    return (
      <DataProvider
        context="affiliate_referral"
        key={item.id}
        value={{ affiliate_referral: item }}
      >
        <View {...style} />
      </DataProvider>
    )
  }

  // don't use react-window and autosizer in short lists
  // a simple Array.map works fine there
  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          height={height}
          itemCount={data.affiliates_referrals.length}
          itemSize={ROW_HEIGHT}
          width={width}
        >
          {Row}
        </List>
      )}
    </AutoSizer>
  )
}
Logic.defaultProps = {
  filter: '',
}
