import { useCompanies } from 'Logic/useFilterByCompanies.js';
import { useData } from 'Data/ViewsData.js';
import gql from 'graphql-tag';
import View from './view.js';
import React, { useEffect } from 'react';

let query = gql`
  query get_companies(
    $company_agreements: company_agreements_bool_exp
    $company_users: company_users_bool_exp
  ) {
    companies(
      where: {
        company_agreements: $company_agreements
        company_users: $company_users
      }
    ) {
      id
      legal_name
      expiry_date
      effective_date
    }
  }
`;

export default function Logic() {
  let dataMember = useData({ context: 'member', path: 'member.company' });
  let companies = useCompanies({
    type: 'MemberGroup',
    has_accepted_agreement: true,
    query,
  });

  useEffect(() => {
    if (dataMember.value) return;

    dataMember.onChange((next) => {
      next.member.company = companies.find(
        (item) => item.id === next.member.company_id
      );
    });
  }, [dataMember, companies]);

  return (
    <View
      from={companies}
      onChangeManual={(id) => {
        dataMember.onChange((next) => {
          next.member.company = companies.find((item) => item.id === id);
          next.member.company_id = id;
        });
      }}
    />
  );
}
