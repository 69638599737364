import { useData } from 'Data/ViewsData.js';
import { useMemo } from 'react';
import get from 'lodash/get';
import set from 'lodash/set';

export let NEW_USER = {
  email: '',
  phone_number: null,
  profile: {
    first_name: '',
    last_name: '',
  },
};

let getUserIds = list => new Set(list.map(item => item.id));

let NEW_USER_ID = 'NEW_USER_ID';

export function useExistingUser({ context, path, usersData }) {
  let [users, existingUsers] = useMemo(() => {
    let members = get(usersData, 'members', []);
    let professionals = get(usersData, 'professionals', []);
    let company_users = get(usersData, 'company_users', []);

    let members_ids = getUserIds(members);
    let professionals_ids = getUserIds(professionals);
    let company_users_ids = getUserIds(company_users);

    let users = [
      ...company_users.map(item => ({
        ...item,
        isCompanyUser: true,
        isProfessional: professionals_ids.has(item.id),
        isMember: members_ids.has(item.id),
      })),
      ...professionals
        .filter(item => !company_users_ids.has(item.id))
        .map(item => ({
          ...item,
          isCompanyUser: false,
          isProfessional: true,
          isMember: members_ids.has(item.id),
        })),
      ...members
        .filter(
          item =>
            !company_users_ids.has(item.id) && !professionals_ids.has(item.id)
        )
        .map(item => ({
          ...item,
          isCompanyUser: false,
          isProfessional: false,
          isMember: members_ids.has(item.id),
        })),
    ];

    let existingUsers = [
      {
        id: NEW_USER_ID,
        text: users.length === 0 ? 'No users' : 'None selected',
      },
      ...users.map(user => {
        let roles = [
          user.isMember && 'employee',
          user.isProfessional && 'professional',
          user.isCompanyUser && 'admin',
        ].filter(Boolean);

        return {
          id: user.id,
          text: [
            `${get(user, 'profile.last_name')}, ${get(
              user,
              'profile.first_name'
            )}`,
            get(user, 'email'),
            roles.join(', '),
          ]
            .filter(Boolean)
            .join(' • '),
        };
      }),
    ];

    return [users, existingUsers];
  }, [usersData]);

  let data = useData({ context, path });
  let selected = data.value.useExistingUser ? data.value.user_id : NEW_USER_ID;

  function onClick(id) {
    data.onChange(next => {
      set(next, `${path}.useExistingUser`, id !== NEW_USER_ID);
      set(next, `${path}.user_id`, id === NEW_USER_ID ? null : id);
      set(
        next,
        `${path}.existingUser`,
        id === NEW_USER_ID ? null : users.find(item => item.id === id)
      );
    });
  }

  return { selected, onClick, from: existingUsers };
}
