import { useFlow, useSetFlowTo } from 'Logic/ViewsFlow.js'
import View from './view.js'
import { useData } from 'Data/ViewsData.js'
import React, { useRef } from 'react'
import useOnClickOutside from 'use-onclickoutside'

export default function Logic(props) {
  let professionalData = useData({
    viewPath: props.viewPath,
    path: 'filtered_professionals',
    context: 'filtered_professionals',
  })
  let dropdownRef = useRef()
  let flow = useFlow()
  let setFlowTo = useSetFlowTo()
  let story =
    '/App/MyAccount/Content/Profile/Content/MyProfessionals/MoreActions'
  let isShowing = flow.has(`${story}/Content`)

  useOnClickOutside(dropdownRef, () => {
    if (isShowing) {
      setFlowTo(`${story}/No`)
    }
  })

  return (
    <View
      hasSelected={professionalData.value?.filtered_professionals?.length > 0}
      dropdownRef={dropdownRef}
      isShowing={isShowing}
      onClick={() => {
        setFlowTo(`${story}/${isShowing ? 'No' : 'Content'}`)
      }}
    />
  )
}
