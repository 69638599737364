import { DataProvider } from 'Data/ViewsData.js';
import { EDIT } from 'Data/constants.js';
import { isInvalid, parseGraphqlError } from 'utils/graphQlHelpers.js';
import { useSelected } from 'Data/Selected.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import { useQuery, useMutation } from 'Data/Api.js';
import {
  useNotifications,
  notifyError,
  notifySuccess,
} from 'utils/useNotifications.js';
import gql from 'graphql-tag';
import React, { useMemo } from 'react';
import View from './view.js';

let query = gql`
  query getAdmin($id: uuid!) {
    user: users_by_pk(id: $id) {
      id
      email
      phone_number
      profile {
        id
        first_name
        last_name
      }
      company_users {
        company_id
        role
      }
    }
  }
`;

// TODO user not updating without reload at the moment

let MUTATION = gql`
  mutation updateAdmin(
    $userId: uuid!
    $updateUser: users_set_input
    $updateProfile: profiles_set_input
    $updateRole: company_users_set_input
    $company_users: [company_users_insert_input!]!
  ) {
    update_users(where: { id: { _eq: $userId } }, _set: $updateUser) {
      returning {
        id
        email
        phone_number
      }
    }
    update_profiles(
      where: { user: { id: { _eq: $userId } } }
      _set: $updateProfile
    ) {
      returning {
        id
        first_name
        last_name
      }
    }
    delete_company_users(where: { user_id: { _eq: $userId } }) {
      returning {
        company_id
        user_id
        role
      }
    }
    insert_company_users(objects: $company_users) {
      returning {
        user_id
        company_id
        role
      }
    }
  }
`;

export default function Logic() {
  // TODO it would be handy if we would export the story from the view and
  // reference it here in setFlowTo as Content.storyPath, it would remove the need
  // to keep references as strings
  let story = '/App/MyAccount/Content/Profile/Content/Admins/Item/Content';
  let setFlowTo = useSetFlowTo();
  let [, notify] = useNotifications();
  let [selected] = useSelected();
  let [, executeMutation] = useMutation(MUTATION);
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: { id: selected.adminId },
  });

  let initialItem = useMemo(() => {
    if (!data) return null;

    return {
      company_user: {
        user: data.user,
        role: data.user.company_users[0].role,
        companies_ids: data.user.company_users.map((item) => item.company_id),
      },
    };
  }, [data]);

  if (fetching) return null;
  if (error) return `😱 ${error.message}`;

  return (
    <DataProvider
      context="company_user"
      onSubmit={onSubmit}
      value={initialItem}
    >
      <View />
    </DataProvider>
  );

  async function onSubmit(next) {
    if (isInvalid(EDIT.ADMIN, next.company_user, notify)) return true;

    let mutationResponse = await executeMutation({
      userId: data.user.id,
      // TODO we should be able to make this smarter by:
      // 1) picking up diffs between objects
      // 2) only sending what changed
      updateUser: {
        email: next.company_user.user.email,
        phone_number: next.company_user.user.phone_number,
      },
      updateProfile: {
        first_name: next.company_user.user.profile.first_name,
        last_name: next.company_user.user.profile.last_name,
      },
      company_users: next.company_user.companies_ids.map((company_id) => ({
        company_id,
        user_id: data.user.id,
        role: next.company_user.role,
      })),
    });

    if (mutationResponse.error) {
      notify(notifyError(parseGraphqlError(mutationResponse.error).message));
    } else {
      notify(
        notifySuccess(
          `${next.company_user.user.profile.first_name} ${next.company_user.user.profile.last_name} was successfully edited!`
        )
      );
      setFlowTo(`${story}/Show`);
    }
  }
}
