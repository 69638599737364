import View from './view.js'
import React from 'react'
import { useData } from 'Data/ViewsData.js'

let MEMBERSHIP_STATUS_ACTIVE = 'Active'
let MEMBERSHIP_STATUS_INACTIVE = 'Inactive'

export default function Logic(props) {
  let member = useData({
    context: 'member',
    path: 'member',
  })

  //incomplete, incomplete_expired, trialing, active, past_due, canceled, or unpaid
  return member.value.stripe_subscription_status || member.value.status ? (
    <View
      isTerminated={
        member.value.status === MEMBERSHIP_STATUS_INACTIVE &&
        member.value.termination_date // red
      }
      isPastDue={
        ['incomplete_expired', 'past_due'].includes(
          member.value.stripe_subscription_status ||
            member.value?.main_member?.stripe_subscription_status
        ) && member.value.status !== MEMBERSHIP_STATUS_INACTIVE // red
      }
      isPending={
        ['incomplete', 'not_started'].includes(
          member.value.stripe_subscription_status ||
            member.value?.main_member?.stripe_subscription_status
        ) && member.value.status !== MEMBERSHIP_STATUS_INACTIVE //orange
      }
      isUnpaid={
        ['unpaid'].includes(
          member.value.stripe_subscription_status ||
            member.value?.main_member?.stripe_subscription_status
        ) && member.value.status !== MEMBERSHIP_STATUS_INACTIVE //orange
      }
      isCurrent={
        (['active', 'trialing'].includes(
          member.value.stripe_subscription_status ||
            member.value?.main_member?.stripe_subscription_status
        ) &&
          member.value.status !== MEMBERSHIP_STATUS_INACTIVE) ||
        (member.value.status === MEMBERSHIP_STATUS_ACTIVE &&
          !member.value.is_individual)
      } //green
    />
  ) : null
}
