import { DataProvider } from 'Data/ViewsData.js';
import { useAuth } from 'Data/Auth.js';
import { useQuery } from 'Data/Api.js';
import gql from 'graphql-tag';
import React from 'react';
import View from './view.js';

let query = gql`
  query get_user($id: uuid!) {
    user: users_by_pk(id: $id) {
      id
      email
      phone_number
      profile {
        id
        first_name
        last_name
      }
    }
  }
`;

export default function Logic() {
  let auth = useAuth();
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: {
      id: auth.data.userId,
    },
  });

  if (fetching) return null;
  if (error) return `😱 ${error.message}`;

  return (
    <DataProvider context="user" value={data}>
      <View />
    </DataProvider>
  );
}
