import React from 'react';
import View from './view.js';

export default function Logic() {
  return (
    <View
      value={`${process.env.REACT_APP_VERSION} ${process.env.REACT_APP_ENV}`}
    />
  );
}
