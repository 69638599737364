import { useData } from 'Data/ViewsData.js';
import { useQuery } from 'Data/Api.js';
import View from './view.js';
import gql from 'graphql-tag';
import React, { useMemo } from 'react';

let query = gql`
  query users($company_id: uuid) {
    users(
      where: {
        company_users: {
          company_id: { _eq: $company_id }
          role: { _eq: "company-admin" }
        }
      }
      order_by: { profile: { last_name: asc, first_name: asc } }
    ) {
      id
      email
      profile {
        id
        full_name
      }
    }
  }
`;

export default function Logic() {
  let item = useData({ context: 'company', path: 'company.id' });
  let [{ data }] = useQuery({
    query,
    variables: { company_id: item.value },
  });

  let list = useMemo(() => {
    if (!data) return data;

    return data.users.map((item) => ({
      id: item.id,
      text: `${item.profile.full_name} • ${item.email}`,
    }));
  }, [data]);

  return <View from={list} />;
}
