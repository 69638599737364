import { useSetFlowTo } from 'Logic/ViewsFlow.js'
import { useActAsSupport } from 'Logic/ActAsSupport.js'
import React, { useEffect } from 'react'
import View from './view.js'

export default function Logic(props) {
  let [isActingAsSupport] = useActAsSupport()
  let setFlowTo = useSetFlowTo(props.viewPath)

  useEffect(() => {
    setFlowTo(
      `/App/MyAccount/Content/Profile/Content/MyCompanies/Item/Content/Change/Active/${
        isActingAsSupport ? 'Content' : 'No'
      }`
    )
  }, [isActingAsSupport]) // eslint-disable-line
  // setFlowTo

  return <View viewPath={props.viewPath} />
}
