import { useData } from 'Data/ViewsData.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import React, { useLayoutEffect } from 'react';
import View from './view.js';

function getStatus(value) {
  switch (value) {
    case 'Active':
    case 'Inactive':
    case 'Enrolled':
      return value;

    default:
      return 'Inactive';
  }
}

export default function Logic() {
  let item = useData({ context: 'member', path: 'member.status' });
  let setFlowTo = useSetFlowTo();

  useLayoutEffect(() => {
    setFlowTo(
      `/App/MyAccount/Content/Verify/Result/Match/Status/${getStatus(
        item.value
      )}`
    );
  }, [item.value]); // eslint-disable-line
  // ignore setFlowTo

  return <View />;
}
