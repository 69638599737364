import { downloadCsv } from 'utils/csv.js'
import { phoneNumberUS as formatPhoneNumber } from 'Data/format.js'
import { _in } from 'Data/hasura.js'
import { useData } from 'Data/ViewsData.js'
import { useQuery } from 'Data/Api.js'
import { useSetFlowTo } from 'Logic/ViewsFlow.js'
import gql from 'graphql-tag'
import View from './view.js'
import React, { useEffect } from 'react'

function where({ professional_ids }) {
  return _in('id', professional_ids)
}

let query = gql`
  query list_companies($where: professionals_bool_exp) {
    professionals(where: $where) {
      id
      license {
        name
      }
      license_number
      license_custom_age_max
      license_custom_age_min
      user {
        email
        phone_number
        profile {
          first_name
          last_name
        }
      }
      professional_locations {
        provider_location_id
      }
    }
  }
`

function processData(data) {
  let { professionals } = data
  let fields = [
    'Id',
    'License Name',
    'License Number',
    'License Age Max',
    'License Age Min',
    'Email',
    'Phone Number',
    'First Name',
    'Last Name',
    // get Location N headers
    ...professionals
      .reduce(
        (last, item) =>
          item.professional_locations.length > last.length
            ? item.professional_locations
            : last,
        []
      )
      .map((_, i) => (i === 0 ? 'Location' : `Location ${i + 1}`)),
  ]
  let result = professionals.map(item => [
    item.id,
    item.license.name,
    item.license_number,
    item.license_custom_age_min,
    item.license_custom_age_max,
    item.user.email,
    formatPhoneNumber(item.user.phone_number),
    item.user.profile.first_name,
    item.user.profile.last_name,
    ...item.professional_locations.map(item => item.provider_location_id),
  ])
  downloadCsv({
    data: result,
    fields,
    name: 'greyfinch-professionals-report.csv',
  })
}
export default function Logic(props) {
  let professionalData = useData({
    viewPath: props.viewPath,
    path: 'filtered_professionals',
    context: 'filtered_professionals',
  })

  let [{ fetching, error, data }] = useQuery({
    query,
    variables: {
      where: where({
        professional_ids: professionalData.value.filtered_professionals,
      }),
    },
  })
  let setFlowTo = useSetFlowTo()

  let story =
    '/App/MyAccount/Content/Profile/Content/MyProfessionals/MoreActions/Content/DownloadCSV'
  useEffect(() => {
    if (error) {
      setFlowTo(`${story}/Failure`)
    } else {
      if (!fetching && data) {
        // Process the data
        try {
          processData(data)
          setFlowTo(`${story}/Done`)
        } catch (e) {
          console.error(e)
          setFlowTo(`${story}/Failure`)
        }
      }
    }
  }, [story, setFlowTo, data, fetching, error])
  return <View />
}
