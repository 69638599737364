import { formatNumber, getPrice } from 'Data/format.js';
import { useData } from 'Data/ViewsData.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  let dataPlans = useData({ context: 'plans', path: 'plans' });
  let dataMember = useData({ context: 'member', path: 'member' });

  let plan = dataPlans.value.find(
    (item) => item.id === dataMember.value.plan_id
  );
  if (!plan) return null;

  return (
    <View
      value={formatNumber(
        getPrice(plan.pricing, dataMember.value.related_members.length + 1),
        '$#,##0.00'
      )}
    />
  );
}
