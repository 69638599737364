import * as isValid from 'Data/validate.js';
import { DataProvider } from 'Data/ViewsData.js';
import { EDIT } from 'Data/constants.js';
import { isInvalid, parseGraphqlError } from 'utils/graphQlHelpers.js';
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'utils/useNotifications.js';
import { parseCsv } from 'utils/csv.js';
import { useMutation, useQuery } from 'Data/Api.js';
import { useSelected } from 'Data/Selected.js';
import { useFlow, useSetFlowTo } from 'Logic/ViewsFlow.js';
import {
  dateShortOut as formatDateShortOut,
  memberId as formatMemberId,
  textToNumber as formatTextToNumber,
} from 'Data/format.js';
import View from './view.js';
import addMonths from 'date-fns/addMonths';
import subMonths from 'date-fns/subMonths';
import endOfMonth from 'date-fns/endOfMonth';
import isWithinInterval from 'date-fns/isWithinInterval';
import parseISO from 'date-fns/parseISO';
import gql from 'graphql-tag';
import React, { useCallback, useEffect, useMemo } from 'react';
import toSnakeCase from 'to-snake-case';

let MUTATION = gql`
  mutation upload_utilization_report(
    $report_id: uuid!
    $report: utilization_reports_set_input!
  ) {
    update_utilization_reports(
      _set: $report
      where: { id: { _eq: $report_id } }
    ) {
      returning {
        id
        uploaded_at
        uploaded_by_user {
          id
          profile {
            full_name
          }
        }
      }
    }
  }
`;

let query = gql`
  query utilization_report($id: uuid!) {
    utilization_report: utilization_reports_by_pk(id: $id) {
      id
      reported_month
      uploaded_at
      company {
        id
        legal_name
      }
    }
  }
`;

function getItemErrors(item, date_of_treatment_interval) {
  return [
    !isValid.memberId(item.member_id) && 'member ID',
    !isValid.textInput(item.member_first_name) && 'member first name',
    !isValid.textInput(item.member_last_name) && 'member last name',
    !isValid.birthday(item.member_dob) && 'member dob',
    (!isValid.date(item.date_of_treatment) ||
      !isWithinInterval(
        parseISO(item.date_of_treatment),
        date_of_treatment_interval
      )) &&
      'date of treatment',
    !isValid.textInput(item.treatment_location) && 'treatment location',
    !isValid.textInput(item.procedure_code) && 'procedure code',
    !isValid.textInput(item.procedure_description) && 'procedure description',
    !isValid.numberZeroOrPositive(item.fee_charged) && 'fee charged',
  ].filter(Boolean);
}

async function parseItemsCsv(file, reported_month) {
  let results = await parseCsv({
    file,
    header: true,
    skipEmptyLines: true,
    transformHeader: toSnakeCase,
    transform: (rvalue, key) => {
      if (!rvalue) return;

      let value = rvalue.trim();

      if (/(dob|date)/.test(key)) {
        return formatDateShortOut(value);
      } else if (key === 'member_id') {
        return formatMemberId(value);
      } else if (key === 'fee_charged') {
        return formatTextToNumber(value);
      } else {
        return value;
      }
    },
  });

  if (results.error) {
    throw new Error(results.error);
  }

  // get the range for the past quarter
  let start_of_month = subMonths(parseISO(reported_month), 3);
  let end_of_month = endOfMonth(addMonths(start_of_month, 2));
  let date_of_treatment_interval = {
    start: start_of_month,
    end: end_of_month,
  };

  return (
    results.data
      // Discard empty lines
      .filter((item) => Object.values(item).some(Boolean))
      .map((item) => {
        let errors = getItemErrors(item, date_of_treatment_interval);

        return {
          ...item,
          errors: errors.join(', '),
          is_valid: errors.length === 0,
        };
      })
      .sort((a, b) => (!a.is_valid && !b.is_valid ? 0 : !a.is_valid ? -1 : 1))
  );
}

export default function Logic() {
  let story = '/App/MyAccount/Content/Profile/Content/Utilization/Item';
  let setFlowTo = useSetFlowTo();
  let flow = useFlow();
  let [, notify] = useNotifications();
  let [selected, select] = useSelected();
  let [, executeMutation] = useMutation(MUTATION);
  let [{ fetching, data, error }] = useQuery({
    query,
    variables: {
      id: selected.utilizationReportId,
    },
  });

  useEffect(() => {
    if (
      !fetching &&
      (data.utilization_report === null || data.utilization_report.uploaded_at)
    ) {
      setFlowTo(`${story}/No`);
    }
  }, [fetching, data]); // eslint-disable-line
  // ignore setFlowTo, story

  let value = useMemo(
    () => ({
      utilization_report_import: {
        utilization_report: data && data.utilization_report,
        items: [],
        has_nothing_to_upload: false,
        file: null,
      },
    }),
    [data]
  );

  // TODO this should be within Data I think as an effect with access to the
  // context
  let onChange = useCallback(
    async (next, change) => {
      let storyData = `${story}/Content/Provide/Content/Data`;
      if (
        flow.has(`${storyData}/Upload`) &&
        next.utilization_report_import.file
      ) {
        try {
          if (
            next.utilization_report_import.file.name
              .split('.')
              .pop()
              .toLowerCase() !== 'csv'
          ) {
            throw new Error('Invalid extension');
          }

          setFlowTo(`${storyData}/Loading`);

          let nextItems = await parseItemsCsv(
            next.utilization_report_import.file,
            data.utilization_report.reported_month
          );
          change((next) => {
            next.utilization_report_import.items = nextItems;
          });
        } catch (error) {
          notify(
            notifyError(
              `The uploaded file isn't a valid CSV file. Review it and try again.`
            )
          );
          change((next) => {
            next.utilization_report_import.file = null;
          });
          console.error(error);
        }
      } else if (flow.has(`${storyData}/Loading`)) {
        if (next.utilization_report_import.items.length === 0) {
          notify(notifyError('The uploaded CSV file is empty'));
          setFlowTo(`${storyData}/Upload`);
        } else {
          setFlowTo(`${storyData}/Verify`);
        }
      }
    },
    [flow] // eslint-disable-line
  ); // ignore notify, setFlowTo, story

  if (fetching) return null;
  if (error) return `😱 ${error.message}`;

  return (
    <DataProvider
      context="utilization_report_import"
      onChange={onChange}
      onSubmit={onSubmit}
      value={value}
    >
      <View />
    </DataProvider>
  );

  async function onSubmit(next) {
    if (
      isInvalid(EDIT.UTILIZATION_REPORT, next.utilization_report_import, notify)
    )
      return true;

    let report = {
      has_nothing_to_upload:
        next.utilization_report_import.has_nothing_to_upload,
    };
    if (!report.has_nothing_to_upload) {
      report.data = next.utilization_report_import.items.map(
        ({ errors, is_valid, ...item }) => item
      );
    }

    let mutationResponse = await executeMutation({
      report_id: selected.utilizationReportId,
      report,
    });
    if (mutationResponse.error) {
      notify(notifyError(parseGraphqlError(mutationResponse.error).message));
    } else {
      select({ nothing: Date.now(), utilizationReportId: null });
      notify(notifySuccess(`Successfully uploaded report!`));
      setFlowTo(`${story}/No`);
    }
  }
}
