import { DataProvider, useData } from 'Data/ViewsData.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  let dataMember = useData({
    context: 'member',
    path: 'member.related_members',
  });

  function onChange(next) {
    dataMember.onChange((dataMemberNext) => {
      dataMemberNext.member.related_members = dataMemberNext.member.related_members.map(
        (item) =>
          item.id === next.related_member.id ? next.related_member : item
      );
    });
  }

  return dataMember.value.map((related_member) => (
    <DataProvider
      context="related_member"
      key={related_member.id}
      value={{ related_member }}
      onChange={onChange}
    >
      <View
        isNew={related_member.main_member_id === null}
        onDelete={() => {
          dataMember.onChange(
            dataMember.value.filter((item) => item.id !== related_member.id)
          );
        }}
        onSubmit={dataMember.onSubmit}
      />
    </DataProvider>
  ));
}
