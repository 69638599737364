import { useData } from 'Data/ViewsData.js';
import View from './view.js';
import React from 'react';
import uuid from 'uuid/v4';

function isEmpty(item) {
  return (
    item.provider_location_id === null &&
    item.professional_id === null &&
    item.profile.first_name === '' &&
    item.profile.last_name === '' &&
    item.profile.date_of_birth === '' &&
    item.profile.gender === null &&
    item.profile.social_security_number === ''
  );
}

export default function Logic() {
  let dataMember = useData({
    context: 'member',
    path: 'member.related_members',
  });

  return (
    <View
      onClick={() => {
        if (dataMember.value.some(isEmpty)) return;

        dataMember.onChange([
          ...dataMember.value,
          {
            id: uuid(),
            provider_location_id: null,
            professional_id: null,
            profile: {
              first_name: '',
              last_name: '',
              date_of_birth: '',
              gender: null,
              social_security_number: '',
            },
          },
        ]);
      }}
    />
  );
}
