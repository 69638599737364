import { useData } from 'Data/ViewsData.js';
import View from './view.js';
import React from 'react';

export default function Logic() {
  let item = useData({
    context: 'employees_import',
    path: 'employees_import.employees',
  });

  return <View value={item.value.length} />;
}
