import { useData } from 'Data/ViewsData.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import { useLayoutEffect } from 'react';

let DEPENDENCY = '';

export default function useSetFlowToBasedOnData({
  context,
  path,
  story,
  isContent = i => i,
  dependency = DEPENDENCY,
  content = 'Content',
  no = 'No',
}) {
  let data = useData({ context, path });
  let setFlowTo = useSetFlowTo();
  useLayoutEffect(() => {
    setFlowTo(`${story}/${isContent(data.value) ? content : no}`);
  }, [`${JSON.stringify(data.value)}${dependency}`]); // eslint-disable-line
  // ignore setFlowTo
}
