import React from 'react';
import View from './view.js';
import useCapture from 'useCapture.js';

let MaskedInputSmallLabelLogic = React.forwardRef((props, ref) => {
  let capture = useCapture(props);
  // console.log("invalid, MISLL", props.isInvalid)
  return (
    <View {...props} {...capture} onChange={props.onChange} innerRef={ref} />
  );
});

export default MaskedInputSmallLabelLogic;
