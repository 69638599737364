import { isLastSuperAdminInCompany } from 'Data/format.js';
import { useData } from 'Data/ViewsData.js';
import useSetFlowToBasedOnData from 'Data/useSetFlowToBasedOnData.js';
import View from './view.js';
import React, { useEffect } from 'react';

export default function Logic() {
  useSetFlowToBasedOnData({
    path: 'user',
    context: 'user',
    story:
      '/App/MyAccount/Content/Profile/Content/Admins/Item/Content/Delete/Admin/Content',
    isContent: (value) => isLastSuperAdminInCompany(value),
    no: 'Delete',
    content: 'LastAdminInCompany',
  });

  let data = useData({
    path: 'user',
    context: 'user',
  });

  useEffect(() => {
    if (
      data.value.is_deleting_admin &&
      data.value.company_users.length > 0 &&
      isLastSuperAdminInCompany(data.value)
    ) {
      data.onChange((next) => {
        next.user.is_deleting_admin = false;
      });
    }
  }, [data]);

  return <View />;
}
