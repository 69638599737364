import View from './view.js';
import React from 'react';
import useSetFlowToBasedOnData from 'Data/useSetFlowToBasedOnData.js';

export default function Logic() {
  useSetFlowToBasedOnData({
    context: 'member',
    path: 'member.status',
    isContent: (value) => value === 'Enrolled',
    story:
      '/App/MyAccount/Content/Profile/Content/Employees/Item/Content/Change/BenefitEffectiveDate',
  });

  return <View />;
}
