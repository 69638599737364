import addMonths from 'date-fns/addMonths';
import formatDate from 'date-fns/format';
import startOfMonth from 'date-fns/startOfMonth';
import startOfToday from 'date-fns/startOfToday';

export default function getValidEffectiveDatesForIndividual() {
  let today = startOfToday();
  let firstOfTodaysMonth = startOfMonth(today);

  return [
    today,
    addMonths(firstOfTodaysMonth, 1),
    addMonths(firstOfTodaysMonth, 2),
    addMonths(firstOfTodaysMonth, 3),
  ].map((date) => ({
    id: formatDate(date, 'yyyy-MM-dd'),
    text: formatDate(date, 'MM/dd/yyyy'),
  }));
}
