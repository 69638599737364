import { ActAsSupport } from 'Logic/ActAsSupport.js'
import { Api } from 'Data/Api.js'
import { Auth } from 'Data/Auth.js'
import { captureBreadcrumb, useOnError } from 'utils/ErrorBoundary.js'
import { ViewsFlow, useSetFlowTo } from '../Logic/ViewsFlow.js'
import { Notifications } from 'utils/useNotifications.js'
import { Track } from 'utils/Track.js'
import View from './view.js'
import React from 'react'
import { useTrackFlow } from 'utils/useTrackFlow.js'
import qs from 'querystringify'

function Logic() {
  let setFlowTo = useSetFlowTo()
  useOnError(() => {
    // if (process.env.NODE_ENV !== 'development') {
    setFlowTo('/App/RuntimeError')
    // }
  })
  useTrackFlow()

  return <View />
}

export default function LogicWithFlow() {
  return (
    <Track>
      <ViewsFlow
        onSetFlow={(message) =>
          captureBreadcrumb({ category: 'flow', message })
        }
      >
        <Notifications>
          <ActAsSupport>
            <Api>
              <Auth getReadyStory={getReadyStory}>
                <Logic />
              </Auth>
            </Api>
          </ActAsSupport>
        </Notifications>
      </ViewsFlow>
    </Track>
  )
}

function getReadyStory(auth) {
  let { 'upload-utilization-report': uploadUtilizationReport } = qs.parse(
    window.location.search
  )

  if (auth.permissions.isNewUser) {
    return '/App/MyAccount/Content/Profile/Content/MyCompanies/AddItem/Content'
  } else if (auth.permissions.hasToAcceptAndSignAgreement) {
    return '/App/MyAccount/Content/Profile/Content/MyCompanies/AddItem/Content/Signature'
  } else if (auth.permissions.isProviderAdmin && uploadUtilizationReport) {
    return '/App/MyAccount/Content/Profile/Content/Utilization/Item/Content'
  } else if (auth.permissions.isProvider) {
    return '/App/MyAccount/Content/Members'
  } else {
    return '/App/MyAccount/Content/Profile'
  }
}
