import { useActAsSupport } from 'Logic/ActAsSupport.js';
import { useAuth } from 'Data/Auth.js';
import { useSetFlowTo } from 'Logic/ViewsFlow.js';
import View from './view.js';
import React, { useLayoutEffect } from 'react';

export default function Logic() {
  let [isActingAsSupport] = useActAsSupport();
  let auth = useAuth();
  let setFlowTo = useSetFlowTo();

  useLayoutEffect(() => {
    setFlowTo(
      `/App/MyAccount/Content/Profile/Content/MyCompanies/Item/Content/Show/EditAction/${
        auth.permissions.isAdmin || isActingAsSupport ? 'Content' : 'No'
      }`
    );
  }, [auth.permissions.isAdmin, isActingAsSupport]); // eslint-disable-line
  // ignore setFlowTo

  return <View />;
}
