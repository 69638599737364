import React from 'react';
import { useSelected } from 'Data/Selected.js';
import { useFlow, useSetFlowTo } from 'Logic/ViewsFlow.js';
import { useQuery } from 'Data/Api.js';
import View from './view.js';
import gql from 'graphql-tag';

let query = gql`
  query getPlans($limit: Int) {
    plans(limit: $limit) {
      id
      name
    }
  }
`;

export default function Logic() {
  let flow = useFlow();
  let setFlowTo = useSetFlowTo();
  let story = '/App/MyAccount/Content/Plans/Content/Procedures/Content';
  let [selected, select] = useSelected();
  let [{ fetching, error, data }] = useQuery({
    query,
    variables: { limit: 10000 },
  });

  if (fetching) return null;
  if (error) return `😱 ${error.message}`;

  return data.plans.map((plan) => (
    <View
      key={plan.id}
      text={`${plan.name} Plan`}
      onClick={() => {
        select({ myPlanId: plan.id });
        setFlowTo(story);
      }}
      isSelected={selected.myPlanId === plan.id && flow.has(story)}
    />
  ));
}
