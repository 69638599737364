import { AUTH } from 'Data/constants.js';
import { DataProvider, useData } from 'Data/ViewsData.js';
import { isInvalid } from 'utils/graphQlHelpers.js';
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'utils/useNotifications.js';
import { useAuth } from 'Data/Auth.js';
import View from './view.js';
import React from 'react';

let ITEM = {
  reset_password_confirm: {
    code: '',
    password: '',
  },
};

export default function Logic() {
  let [, notify] = useNotifications();
  let auth = useAuth();
  let dataAuthEmail = useData({ context: 'auth', path: 'auth.email' });

  return (
    <DataProvider
      context="reset_password_confirm"
      onSubmit={onSubmit}
      value={ITEM}
    >
      <View onClickResendCode={onClickResendCode} />
    </DataProvider>
  );

  async function onSubmit(next) {
    if (
      isInvalid(
        AUTH.RESET_PASSWORD_CONFIRM,
        next.reset_password_confirm,
        notify
      )
    )
      return true;

    try {
      await auth.resetPasswordConfirm({
        email: dataAuthEmail.value,
        ...next.reset_password_confirm,
      });
      let res = await auth.signIn({
        email: dataAuthEmail.value,
        password: next.reset_password_confirm.password,
      });
      auth.getUserContext(res);
      notify(notifySuccess('Your new password is ready'));
    } catch (error) {
      console.error(error);
      notify(notifyError('Something went wrong'));
    }
  }

  async function onClickResendCode() {
    try {
      await auth.resetPassword({ email: dataAuthEmail.value });
      notify(notifySuccess(`We sent a new code to ${dataAuthEmail.value}`));
    } catch (error) {
      console.error(error);
      notify(notifyError('Something went wrong'));
    }
  }
}
