import View from './view.js';
import React from 'react';
import useFocusedManual from 'useFocusedManual.js';
import useInputPhone from '../useInputPhone.js';

export default function Logic(props) {
  let input = useInputPhone(props);
  let focusedManual = useFocusedManual();
  return <View {...props} {...input} {...focusedManual} />;
}
