// import { useSetFlowToBasedOnDataCapture } from 'Data/useSetFlowToBasedOnData.js';
import { useData } from 'Data/ViewsData.js';
import React from 'react';
import View from './view.js';

export default function Logic() {
  // useSetFlowToBasedOnDataCapture({
  //   path: 'can_act_as_main_member',
  //   story: '/App/MyAccount/Content/Profile/Content/Individuals/Item/Content/Change/Dependents/Email',
  // });
  let dataMember = useData({
    context: 'related_member',
    path: 'related_member.can_act_as_main_member',
  });

  return <View flowNo={!dataMember.value} flowContent={dataMember.value} />;
}
